import { Controller } from "stimulus";
import { isMobile } from "../utilities/screen_size_breakpoints";

export default class extends Controller {
  static targets = ["menu", "menuContainer", "hamburger", "x", "portalContent", "footer"];

  show() {
    this.menuContainerTarget.classList.add("bg-indigo-800");
    this.menuContainerTarget.classList.add("w-full");
    this.menuContainerTarget.classList.add("h-full");
    this.hamburgerTarget.classList.add("hidden");
    this.xTarget.classList.remove("hidden");
    this.menuTargets.forEach(mt => mt.classList.remove("hidden"));
    this.portalContentTarget.classList.add("hidden");
    this.footerTarget.classList.add("hidden");
  }

  hide() {
    this.menuContainerTarget.classList.remove("bg-indigo-800");
    this.menuContainerTarget.classList.remove("w-full");
    this.menuContainerTarget.classList.remove("h-full");
    this.hamburgerTarget.classList.remove("hidden");
    this.xTarget.classList.add("hidden");
    this.menuTargets.forEach(mt => mt.classList.add("hidden"));
    this.portalContentTarget.classList.remove("hidden");
    this.footerTarget.classList.remove("hidden");
  }

  onResize() {
    if (isMobile()) {
      this.hide();
    }
  }
}
