import { Controller } from "stimulus";
import Cookies from "js-cookie";

function termsAccepted(cookieKey) {
  return Cookies.get(cookieKey);
}

function agreeToTerms(cookieKey) {
  Cookies.set(cookieKey, true, { expires: 365 });
}

export default class extends Controller {
  connect() {
    if (termsAccepted(this.cookieKey)) {
      this.teardown();
    } else {
      this.element.classList.remove("hidden");
    }
  }

  agree() {
    agreeToTerms(this.cookieKey);
    this.teardown();
  }

  teardown() {
    this.element.parentNode.removeChild(this.element);
  }

  get cookieKey() {
    return `dkp-map-terms-accepted-${this.data.get("map-id")}`;
  }
}
