import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTargets.forEach(el => {
      el.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
    });
  }
}
