import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["filter"];

  connect() {
    this.load();
  }

  load() {
    $.ajax({
      url: this.frameUrl,
      dataType: "html",
      success: data => {
        this.element.innerHTML = data;
      },
    });
  }

  filter() {
    this.load();
  }

  get frameUrl() {
    return `${this.data.get("url")}?${this.params}`;
  }

  get params() {
    return this.filterTargets
      .filter(t => this.isFilterSelected(t))
      .map(t => `${t.name}=${t.value}`)
      .join("&");
  }

  isFilterSelected(target) {
    return target.type === "checkbox" ? target.checked : target.value;
  }
}
