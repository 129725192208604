import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["count", "payerTypeInput", "drugInput"];

  connect() {
    this.updateCounts();
  }

  updateCounts() {
    const selectedPayerTypes = this.checkedValues(this.payerTypeInputTargets);
    const selectedDrugs = this.checkedValues(this.drugInputTargets);

    this.countTargets.forEach(target => {
      const coverages = JSON.parse(target.dataset.coverageCountingCoverages);

      const sum = this.assembleCount(selectedPayerTypes, selectedDrugs, coverages);

      target.innerText = sum;
      this.toggleAlertClass(target, sum);
    });
  }

  assembleCount(payerTypeIds, drugIds, coverages) {
    const counts = payerTypeIds.map(payerTypeId =>
      drugIds.map(drugId => coverages[payerTypeId]?.[drugId]),
    );

    return _.sum(_.compact(_.flatten(counts)));
  }

  checkedValues(targets) {
    return targets.filter(i => i.checked).map(i => i.value);
  }

  toggleAlertClass(target, sum) {
    const alertClass = target.dataset.coverageCountingAlertClass;

    if (alertClass) {
      $(target)
        .parent()
        .toggleClass(alertClass, sum > 0);
    }
  }

  get coverageData() {
    return JSON.parse(this.data.get("coverages"));
  }
}
