import tailwindColors from "@tailwindcss/ui/colors";

export default {
  teal: "#54B5C6",
  magenta: "#9F5B94",
  goldenrod: "#EABD2C",
  blue_gray: "#7A78B1",
  pale_green: "#99AD61",
  orange: "#CF7046",
  indigo: "#4F4C90",
  mauve: "#B54B64",
};

export const lightColors = {
  gray: tailwindColors.gray[300],
  teal: "#A9DAE2",
  magenta: "#CFADC9",
};

export const variantLightBlue = {
  backgroundColor: "#BBE1E8",
  borderColor: "#2C7481",
};
