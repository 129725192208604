import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["exportIcon", "spinnerIcon", "errorIcon", "downloadButton"];

  connect() {}

  export() {
    $(this.exportIconTarget).addClass("hidden");
    $(this.spinnerIconTarget).removeClass("hidden");

    fetch(this.data.get("url"), {
      headers: {
        Accept: "application/json",
      },
    })
      .then(blob => blob.json())
      .then(exportResponse => {
        this.intervalId = setInterval(() => {
          fetch(exportResponse.status_url, {
            headers: {
              Accept: "application/json",
            },
          })
            .then(blob => blob.json())
            .then(resp => this.processStatusResponses(resp));
        }, 1000);
      });
  }

  processStatusResponses(resp) {
    if (resp.error) {
      $(this.spinnerIconTarget).addClass("hidden");
      $(this.errorIconTarget).removeClass("hidden");
      this.clearInterval(this.intervalId);
    } else if (resp.progress === 100) {
      $(this.spinnerIconTarget).addClass("hidden");
      $(this.downloadButtonTarget).removeClass("hidden");
      this.clearInterval(this.intervalId);

      this.downloadButtonTarget.href = `/download?key=${resp.download_key}`;
    }
  }

  reset() {
    $(this.downloadButtonTarget).addClass("hidden");
    $(this.spinnerIconTarget).addClass("hidden");
    $(this.errorIconTarget).addClass("hidden");
    $(this.exportIconTarget).removeClass("hidden");
  }

  disconnect() {
    this.clearInterval();
  }

  clearInterval = () => clearInterval(this.intervalId);
}
