import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.load();
  }

  load() {
    $.ajax({
      url: this.data.get("url"),
      dataType: "html",
      success: data => {
        this.element.outerHTML = data;
      },
    });
  }
}
