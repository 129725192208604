import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["payerModalCheckbox", "coverageModalSelect"];

  static values = { endpoint: String };

  connect() {
    this.payerModalCheckboxTargets.forEach(t => {
      const checkbox = $(t).find("input[name=coverage_task_ids]")[0];

      checkbox.checked = this.pageFormHasTaskColumn(checkbox.value);
    });
  }

  updateColumnsFromCheckboxes() {
    this.payerModalCheckboxTargets.forEach(t => {
      const checkbox = $(t).find("input[name=coverage_task_ids]")[0];
      const newFormColumn = $(t).find("[data-e-alert-coverage-table-modal-target=tableEntry]")[0];

      if (checkbox.checked) {
        this.addPageFormColumn(checkbox.value, newFormColumn.innerHTML);
      }
    });
  }

  async updateColumnsFromSelect() {
    const taskId = this.coverageModalSelectTarget.value;

    const response = await fetch(`${this.endpointValue}?task_id=${taskId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    });

    const html = await response.text();

    this.addPageFormColumn(taskId, html);
  }

  addPageFormColumn(taskId, html) {
    if (!this.pageFormHasTaskColumn(taskId)) {
      $(this.pageFormTarget).append(html);
    }
  }

  removePageFormColumn(taskId) {
    $(this.pageFormTarget).find(`[data-coverage-task-id="${taskId}"]`).remove();
  }

  pageFormHasTaskColumn(taskId) {
    return $(this.pageFormTarget).find(`[data-coverage-task-id="${taskId}"]`).length > 0;
  }

  get pageFormTarget() {
    return document.getElementById("section_form");
  }
}
