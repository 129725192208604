import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["submit", "someRequired"];

  connect() {
    this.onChange();
  }

  onChange() {
    const isValid = this.element.checkValidity() && this.groupsHaveSomeChecked();

    this.submitTargets.forEach(submitEl => {
      submitEl.disabled = !isValid;
    });
  }

  onProgramaticChange() {
    // Browsers cannot be trusted to propagate form 'change' events when changing the state of inputs in code.
    // Connecting a triggering event (e.g. a 'Show All' button) to onChange directly could work, but creates race conditions.
    // By placing a callback on the event loop, it is guarenteed to run after all programatic changes take effect.
    setTimeout(() => this.onChange(), 0);
  }

  groupsHaveSomeChecked() {
    const groups = {};

    this.someRequiredTargets.forEach(el => {
      groups[el.name] = !!(el.checked || groups[el.name]);
    });

    return _.every(_.values(groups));
  }
}
