import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "heading"];

  connect() {
    if (this.autoOpen) {
      this.loadContent();
    }
  }

  toggle() {
    this.contentTarget.classList.toggle("hidden");
    this.headingTarget.classList.toggle("open");

    this.loadContent();
  }

  loadContent() {
    if (!this.loaded) {
      $.ajax({
        url: this.data.get("url"),
        dataType: "html",
        success: data => {
          this.contentTarget.innerHTML = data;
          this.data.set("loaded", true);
        },
      });
    }
  }

  get autoOpen() {
    return this.data.get("auto-open") === "true";
  }

  get loaded() {
    return this.data.get("loaded") === "true";
  }
}
