import { Controller } from "stimulus";
import { isMobile } from "../utilities/screen_size_breakpoints";

export default class extends Controller {
  static targets = ["files", "heading"];

  connect() {
    if (isMobile()) {
      this.collapse();
    }
  }

  toggle() {
    if (isMobile()) {
      this.filesTarget.classList.toggle("hidden");
      this.headingTarget.classList.toggle("open");
    }
  }

  collapse() {
    this.filesTarget.classList.add("hidden");
    this.headingTarget.classList.remove("open");
  }

  expand() {
    this.filesTarget.classList.remove("hidden");
    this.headingTarget.classList.add("open");
  }

  onResize() {
    if (!isMobile()) {
      this.expand();
    }
  }
}
