import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["show", "content"];

  static values = { startShown: Boolean };

  connect() {
    if (this.startShownValue) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    $(this.showTargets).addClass("hidden");
    $(this.contentTargets).removeClass("hidden");
  }

  hide() {
    $(this.showTargets).removeClass("hidden");
    $(this.contentTargets).addClass("hidden");
  }
}
