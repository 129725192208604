import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["grid", "container", "hideable"];

  one() {
    $(this.gridTargets).css({ "grid-template-columns": "1fr" });
    $(this.containerTargets).addClass("max-w-xl");
    $(this.containerTargets).removeClass("max-w-3xl");
    $(this.containerTargets).removeClass("max-w-5xl");

    this.hideElementsNotInLayout(1);
  }

  two() {
    $(this.gridTargets).css({ "grid-template-columns": "1fr 1fr" });
    $(this.containerTargets).addClass("max-w-3xl");
    $(this.containerTargets).removeClass("max-w-xl");
    $(this.containerTargets).removeClass("max-w-5xl");

    this.hideElementsNotInLayout(2);
  }

  three() {
    $(this.gridTargets).css({ "grid-template-columns": "1fr 1fr 1fr" });
    $(this.containerTargets).addClass("max-w-5xl");
    $(this.containerTargets).removeClass("max-w-3xl");
    $(this.containerTargets).removeClass("max-w-xl");

    this.hideElementsNotInLayout(3);
  }

  hideElementsNotInLayout(columnCount) {
    this.hideableTargets.forEach(target => {
      const targetColumns = $(target)
        .data("grid-column-switcher-show-in-columns")
        .toString()
        .split(" ")
        .map(x => parseInt(x));

      $(target).toggleClass("hidden", !targetColumns.includes(columnCount));
    });
  }
}
