import _ from "lodash";
import { accumulateValuesFn, payersNoun } from "../../utilities/charts/chart_utils";
import showPayersModal from "../../utilities/charts/payers_modal";
import PayersLivesDonutChartBase from "./payers_lives_donut_chart_base";

export default class extends PayersLivesDonutChartBase {
  calculateFractions(coverageMetric) {
    let yes = 0;

    let total = 0;

    _.forIn(_.pick(this.params[this.drug], this.payerTypesShown), (values, payerType) => {
      const yesValues = this.yesValues(values);
      const allValues = _.flatten(_.values(values));

      const accumulate = accumulateValuesFn(payerType, coverageMetric);

      yes += accumulate(yesValues);
      total += accumulate(allValues);
    });

    const mainFraction = total === 0 ? 0 : yes / total;

    return {
      mainFraction,
      increaseFraction: 0,
      decreaseFraction: 0,
    };
  }

  triggerModalInternal(collection) {
    const hasLimit = collection !== "remainder";

    const payerTypes = this.payerTypesShown.map(payerType =>
      payerType === "medicare_advantage_payers" ? "medicare_advantage" : payerType,
    );

    const payers = _.mapValues(_.pick(this.params[this.drug], payerTypes), valuesByType =>
      hasLimit ? this.yesValues(valuesByType) : _.flatten(_.values(_.omit(valuesByType, "Yes"))),
    );

    const verb = hasLimit ? "with" : "without";
    const title = `${payersNoun(payerTypes)} ${verb} Quantity Limits for ${this.drug}`;

    showPayersModal(this.drug, title, payers);
  }

  get titleText() {
    const nouns = payersNoun(this.payerTypesShown);

    return `${nouns} & Lives with Quantity Limits for ${this.drug}`;
  }

  get tooltipTitleText() {
    return "Has Quantity Limits?";
  }
}
