import "@stimulus/polyfills";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);

application.load(definitionsFromContext(context));

application.handleError = (error, message, detail) => {
  console.error(error, message, detail); // eslint-disable-line no-console
  Sentry.captureException(error); // eslint-disable-line no-undef
};
