import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  appendCanvas() {
    const canvas = document.createElement("canvas");

    this.element.appendChild(canvas);

    return canvas;
  }

  get showChangesFromPrevious() {
    return !this.inStaticMode && !!this.params.previous_snapshot_set;
  }

  get inStaticMode() {
    return this.data.get("staticMode");
  }

  get isFixedDrug() {
    return this.drugsIncluded.length === 1;
  }

  get payerTypesIncluded() {
    return this.data.get("payer-types-included").split("|");
  }

  get drugsIncluded() {
    return this.data.get("drugs-included").split("|");
  }

  get coverageMetric() {
    if (this.inStaticMode || _.isEqual(this.payerTypesIncluded, ["benefit_management"])) {
      return "payers";
    } else {
      return this.coverageInputTargets.find(target => target.checked)?.value;
    }
  }
}
