import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import _ from "lodash";
import moment from "moment";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import {
  accumulateValuesFn,
  formatPayersLivesTitle,
  onHoverPointer,
  toOneDecimal,
} from "../../utilities/charts/chart_utils";
import colors, { lightColors } from "../../utilities/charts/colors";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  togglePayerTypeInternal() {
    this.redrawChart();
  }

  toggleCoverageMetricInternal() {
    this.redrawChart();
  }

  toggleDrugInternal() {
    this.chart.options.plugins.title.text = this.titleText;
    this.redrawChart();
  }

  updateShownPayersInternal() {
    this.redrawChart();
  }

  redrawChart() {
    this.chart.options.plugins.subtitle.text = this.subtitle();
    this.chart.data.labels = this.labels();

    const formattedData = this.calculateDataForChart();

    this.chart.data.datasets[0].data = formattedData.previous;
    this.chart.data.datasets[1].data = formattedData.added;
    this.chart.data.datasets[2].data = formattedData.removed;

    this.chart.data.datasets[0].backgroundColor = this.previousColorFromCoverageMetric;
  }

  renderChart() {
    const formattedData = this.calculateDataForChart();

    return new Chart(this.appendCanvas(), {
      type: "bar",
      plugins: [ChartDataLabels],
      options: {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        animation: !this.inStaticMode,
        scales: {
          x: {
            stacked: true,
            grid: { display: false, drawBorder: false },
            ticks: { display: false },
          },
          y: {
            stacked: true,
            grid: { display: false },
            ticks: { display: !this.inStaticMode },
          },
        },
        layout: {
          padding: {
            right: 40, // Leaves room for datalabel
          },
        },
        plugins: {
          legend: {
            display: !this.inStaticMode,
            position: "bottom",
            onClick: () => {
              /* Disable legend click events */
            },
            labels: {
              boxWidth: 12,
              filter: (legendItem, chartData) =>
                chartData.datasets[legendItem.datasetIndex].data.some(x => x > 0),
            },
          },
          tooltip: {
            displayColors: false,
            callbacks: this.tooltipCallbacks,
          },
          title: {
            display: true,
            text: this.titleText,
            color: "black",
            font: { size: 13 },
          },
          subtitle: {
            display: !this.inStaticMode,
            text: this.subtitle(),
            color: "black",
            font: { size: 13 },
            padding: { bottom: 10 },
          },
          datalabels: {
            color: "black",
            anchor: "end",
            align: "right",
            offset: 4,
            display: context => !this.inStaticMode && context.datasetIndex === 2,
            formatter: (_value, context) =>
              toOneDecimal(this.confirmedAccess()[context.dataIndex][1]),
          },
        },
        onHover: onHoverPointer,
        onClick: event => this.triggerModal(event),
      },
      data: {
        labels: this.labels(),
        drug: this.drug,
        datasets: [
          {
            label: "Previous",
            data: formattedData.previous,
            backgroundColor: this.previousColorFromCoverageMetric,
          },
          {
            label: "Increased",
            data: formattedData.added,
            backgroundColor: colors.pale_green,
          },
          {
            label: "Decreased",
            data: formattedData.removed,
            backgroundColor: colors.orange,
          },
        ],
      },
    });
  }

  subtitle() {
    const date = moment(this.params.current_date).format("MMMM YYYY");

    return `${formatPayersLivesTitle(this.coverageMetric, this.payerTypesShown)} (${date})`;
  }

  labels() {
    return this.payerTypesShown.map(payerType => window.PayerTypeLabels[payerType]);
  }

  datumIsPositive(context) {
    return context.dataset.data[context.dataIndex] > 0;
  }

  previousSnapshotData() {
    return this.confirmedAccess().map(data => _.nth(data, -2));
  }

  currentSnapshotDeltas() {
    return this.confirmedAccess().map(data => _.round(_.last(data) - _.nth(data, -2), 2));
  }

  calculateDataForChart() {
    const bases = this.previousSnapshotData();
    const deltas = this.currentSnapshotDeltas();

    const added = deltas.map(d => Math.max(0, d));
    const removed = deltas.map(d => Math.abs(Math.min(0, d)));
    const previousWithRoomForRemoved = bases.map((value, i) => value - removed[i]);

    return {
      previous: previousWithRoomForRemoved,
      added,
      removed,
    };
  }

  confirmedAccess() {
    const forShownPayers = _.pick(this.params.data, this.payerTypesShown);
    const forThisDrug = _.mapValues(forShownPayers, byDrug => byDrug[this.drug] || []);
    const accumulated = _.mapValues(forThisDrug, (dataOverTime, payerType) =>
      dataOverTime.map(byAccessStatus =>
        accumulateValuesFn(payerType, this.coverageMetric)(byAccessStatus[0]),
      ),
    );

    return _.values(accumulated);
  }

  triggerModal(e) {
    const indexes = parseClick(this.chart, e);

    if (!indexes) return;

    let payerType = this.payerTypesShown[indexes.index];

    if (payerType === "medicare_advantage_payers") payerType = "medicare_advantage";

    let groupType = "";

    let payerIds = [];

    const data = this.currentAndPreviousConfirmedPayerIds(payerType);

    if (indexes.datasetIndex === 0) {
      groupType = "unchanged";
      payerIds = _.intersection(data[0], data[1]);
    } else {
      groupType = "changed";
      payerIds = _.xor(data[0], data[1]);
    }

    showPayersModal(this.drug, groupType, { [payerType]: payerIds });
  }

  currentAndPreviousConfirmedPayerIds(payerType) {
    return this.params.data[payerType][this.drug].map(d => d[0].map(pl => pl.payer));
  }

  get previousColorFromCoverageMetric() {
    if (this.coverageMetric === "lives") {
      return lightColors.teal;
    } else {
      return lightColors.magenta;
    }
  }

  get tooltipCallbacks() {
    return {
      label: tooltipItem => {
        const [previous, current] = this.confirmedAccess()[tooltipItem.dataIndex];

        const deltaWord = current < previous ? "Decreased" : "Increased";

        return [
          `Total Confirmed: ${toOneDecimal(current)}`,
          `${deltaWord}: ${toOneDecimal(Math.abs(previous - current))}`,
        ];
      },
    };
  }

  get titleText() {
    return `New & Previous Confirmed Access for ${this.drug}`;
  }
}
