import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["dependant"];

  static values = { noEvents: Boolean };

  connect() {
    this.evaluateDependants();
  }

  checkAll() {
    this.setAllCheckboxes(true);
    this.evaluateDependants();
    this.setAllCheckboxes(true);
  }

  checkNone() {
    this.setAllCheckboxes(false);
    this.evaluateDependants();
    this.setAllCheckboxes(false);
  }

  checkProgramatic(event) {
    const boxesToCheck = JSON.parse(event.currentTarget.dataset.checkboxListSelect);

    this.checkboxTargets.forEach(checkbox => {
      this.setChecked(checkbox, boxesToCheck.includes(checkbox.value));
    });
  }

  toggleByToken(event) {
    const token = event.currentTarget.dataset.checkboxListToken;

    this.checkboxTargets
      .filter(checkbox => checkbox.dataset.checkboxListToken === token)
      .forEach(checkbox => this.setChecked(checkbox, !checkbox.checked));
  }

  toggleGroup(event) {
    const groupIds = JSON.parse(event.currentTarget.dataset.checkboxListSelect);
    const groupBoxes = this.checkboxTargets.filter(checkbox => groupIds.includes(checkbox.value));

    const allChecked = groupBoxes.every(checkbox => checkbox.checked);

    groupBoxes.forEach(checkbox => {
      this.setChecked(checkbox, !allChecked);
    });
  }

  setAllCheckboxes(checked) {
    this.checkboxTargets.forEach(checkbox => {
      if (!checkbox.disabled) {
        this.setChecked(checkbox, checked);
      }
    });
  }

  evaluateDependants() {
    this.dependantTargets.forEach(el => {
      const dependantCheckbox = el.querySelector("input[type=checkbox]");
      const parent = this.checkboxTargets.find(other => other.value === el.dataset.dependantOn);
      const disabled = !parent || !parent.checked;

      el.classList.toggle("opacity-50", disabled);
      dependantCheckbox.disabled = disabled;
      if (disabled) {
        this.setChecked(dependantCheckbox, false);
      }
    });
  }

  setChecked(checkbox, checked) {
    if (checkbox.checked !== checked) {
      checkbox.checked = checked;

      if (!this.noEventsValue) {
        checkbox.dispatchEvent(new Event("input"));
      }
    }
  }

  get checkboxTargets() {
    return Array.from(this.element.querySelectorAll("input[type=checkbox]"));
  }
}
