import { Controller } from "stimulus";

import { configure, hits, pagination, panel, searchBox } from "instantsearch.js/es/widgets";

const algoliasearch = require("algoliasearch");
const instantsearch = require("instantsearch.js").default;

export default class extends Controller {
  static targets = ["pagination", "results", "resultTemplate", "searchBar", "searchBox"];

  initialize() {
    this.search = instantsearch({
      indexName: this.data.get("algolia-index"),
      searchClient: algoliasearch(this.data.get("algolia-id"), this.data.get("algolia-key")),
      searchFunction: helper => {
        if (helper.state.query === "") {
          this.resultsTarget.innerHTML = "";
          this.paginationTarget.innerHTML = "";
          return;
        }

        helper.search();
      },
    });
  }

  connect() {
    const customPagination = panel({
      hidden: ({ results }) => results.nbPages < 2,
    })(pagination);

    this.search.addWidgets([
      configure({
        hitsPerPage: this.data.get("page-size") || 4,
        distinct: true,
        facetFilters: this.data.get("filter"),
      }),
      searchBox({
        container: this.searchBoxTarget,
        placeholder: this.data.get("placeholder"),
        showSubmit: false,
        showLoadingIndicator: false,
        cssClasses: {
          form: "campaign-search-form",
          input: "campaign-search-input",
          reset: "campaign-search-reset",
        },
        templates: {
          reset: "&times;",
        },
      }),
      customPagination({
        container: this.paginationTarget,
        cssClasses: {
          root: "algolia-pagination",
          list: "algolia-pagination-list",
          item: "algolia-pagination-item",
          selectedItem: "algolia-pagination-item-selected",
          disabledItem: "hidden",
        },
      }),
      hits({
        container: this.resultsTarget,
        cssClasses: {
          root: "campaign-search-results",
          emptyRoot: "campaign-search-results-empty",
          item: "campaign-search-result",
        },
        templates: {
          item: this.resultTemplateTarget.innerHTML,
        },
      }),
    ]);

    this.search.start();
  }

  show() {
    this.searchBarTarget.classList.remove("hidden");
    this.searchBoxTarget.querySelector("input").focus();
  }

  hide() {
    this.searchBarTarget.classList.add("hidden");
  }

  jump(event) {
    const { gid } = event.currentTarget.dataset;

    if (gid) {
      let section = document.querySelector(`[data-campaign-target='${gid}']`);

      if (!section) {
        // Fallback to participants to handle case where file is on a different tab
        section = document.querySelector("#participants");
      }

      this.hide();

      section.scrollIntoView({ behavior: "smooth", block: "center" });
      section.classList.remove("animate-slow");
      section.classList.add("pulse");

      window.setTimeout(() => {
        section.classList.add("animate-slow");
        section.classList.remove("pulse");
      }, 1000);
    }
  }

  disconnect() {
    this.hide();
    this.search.dispose();
  }
}
