import SelectController from "./select_controller";

export default class extends SelectController {
  connect() {
    super.connect();

    this.element.onchange = event => {
      const url = `${window.location.pathname}?region=${event.target.value}`;

      Turbolinks.clearCache();
      Turbolinks.visit(url, { action: "replace" });
    };
  }
}
