import _ from "lodash";
import { buildChart, updateChartData } from "../../utilities/charts/payers_v_lives_over_time_chart";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";
import {
  absoluteDateOverTimeCallbacks,
  accumulateValuesFn,
  omitMissingOverTimeItems,
  overTimeX,
  payersNoun,
  percent,
} from "../../utilities/charts/chart_utils";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  togglePayerTypeInternal() {
    updateChartData(this.chart, this.formatData.bind(this), payersNoun(this.payerTypesShown));
    this.chart.options.plugins.title.text = this.titleText;
  }

  toggleDrugInternal() {
    updateChartData(this.chart, this.formatData.bind(this), payersNoun(this.payerTypesShown));
    this.chart.options.plugins.title.text = this.titleText;
  }

  updateShownPayersInternal() {
    updateChartData(this.chart, this.formatData.bind(this), payersNoun(this.payerTypesShown));
  }

  renderChart() {
    return buildChart(
      this.appendCanvas(),
      payersNoun(this.payerTypesShown),
      this.titleText,
      absoluteDateOverTimeCallbacks(this),
      this.formatData.bind(this),
      this.triggerModal.bind(this),
      this.inStaticMode,
    );
  }

  formatData(coverageMetric) {
    const forShownPayers = _.pick(this.params.data[this.drug], this.payerTypesShown);
    const accumulatedData = {};

    _.forIn(forShownPayers, (datapoints, payerType) => {
      const accumulate = accumulateValuesFn(payerType, coverageMetric);

      datapoints.forEach(datapoint => {
        const date = datapoint.date;
        const confirmed = accumulate(datapoint.confirmed);
        const all = accumulate(datapoint.all);

        if (accumulatedData[date]) {
          accumulatedData[date].confirmed += confirmed;
          accumulatedData[date].all += all;
        } else {
          accumulatedData[date] = { date, confirmed, all };
        }
      });
    });

    const data = omitMissingOverTimeItems(_.values(accumulatedData)).map(
      ({ date, confirmed, all }) => ({
        x: overTimeX(this, date),
        y: percent(confirmed, all),
        date,
      }),
    );

    return _.sortBy(data, point => point.x);
  }

  triggerModal(event) {
    const clickLocation = parseClick(this.chart, event);

    if (!clickLocation) return;

    const payerTypes = this.payerTypesShown.map(payerType =>
      payerType === "medicare_advantage_payers" ? "medicare_advantage" : payerType,
    );

    const title = `${payersNoun(payerTypes)} with Confirmed Access for ${this.drug}`;

    const dataForPayerTypes = _.pick(this.params.data[this.drug], payerTypes);
    const confirmedByType = _.mapValues(dataForPayerTypes, v => v[clickLocation.index]?.confirmed);

    const payers = _.pickBy(confirmedByType, data => !_.isEmpty(data));

    const dataset = this.chart.data.datasets[clickLocation.datasetIndex];
    const maxIndex = dataset.data.length - 1;

    if (clickLocation.index === maxIndex) {
      showPayersModal(this.drug, title, payers);
    } else {
      const timestamp = dataset.data[clickLocation.index].date;

      showPayersModal(this.drug, title, payers, { timestamp });
    }
  }

  get titleText() {
    const nouns = payersNoun(this.payerTypesShown);

    return `% of ${nouns} v. % of Lives with Confirmed Access for ${this.drug}`;
  }
}
