import { Controller } from "stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["menu", "icon"];

  toggle(e) {
    const parentCell = e
      .composedPath()
      .find(ancestor => ancestor.classList.contains("action-button"));

    const toggle = parentCell.querySelector("[data-action-menu-target='icon']");
    const menu = parentCell.querySelector("[data-action-menu-target='menu']");
    const shouldOpen = menu.classList.contains("hidden");

    this.closeAllMenus();

    if (shouldOpen) {
      this.popper = createPopper(toggle, menu);

      toggle.classList.add("active");
      menu.classList.remove("hidden");
    }
  }

  teardown() {
    this.closeAllMenus();
  }

  closeAllMenus() {
    if (this.popper) {
      this.popper.destroy();
    }

    this.menuTargets.forEach(menuTarget => menuTarget.classList.add("hidden"));
    this.iconTargets.forEach(iconTarget => iconTarget.classList.remove("active"));
  }
}
