import _ from "lodash";
import OnePayerTypeManyDrugsChartControllerBase from "./one_payer_type_many_drugs_chart_controller_base";
import BarsChartGridBase from "./bars_chart_grid_base";
import {
  orderedAccessStatusNames,
  coverageMetricNoun,
  payersNoun,
  percentString,
  toOneDecimal,
  formatPayersLivesTitle,
  accumulateValuesFn,
} from "../../utilities/charts/chart_utils";
import showPayersModal from "../../utilities/charts/payers_modal";

export default class extends OnePayerTypeManyDrugsChartControllerBase {
  static targets = [
    "title",
    "mainPercent",
    "mainDescription",
    "gridTitle",
    "gridFraction",
    "gridPercent",
    "gridChangeIndicator",
  ];

  connect() {
    this.innerClass = new BarsChartGridBase(this);
    super.connect();
  }

  toggleCoverageMetricInternal() {
    this.renderChart();
  }

  togglePayerTypeInternal() {
    this.renderChart();
  }

  toggleDrugsInternal() {
    this.renderChart();
  }

  updateShownPayersInternal() {
    this.renderChart();
  }

  renderChart() {
    this.titleTarget.innerText = `${window.PayerTypeLabels[this.payerType]} ${coverageMetricNoun(
      this.coverageMetric,
      this.payerType,
    )}`;

    const { yes, all } = this.currentAndPriorDataForAccessStatus("confirmed_access").current;

    this.mainPercentTarget.innerText = percentString(yes, all);

    this.mainDescriptionTarget.innerText = `${toOneDecimal(
      yes,
    ).toLocaleString()} out of ${toOneDecimal(all).toLocaleString()} ${formatPayersLivesTitle(
      this.coverageMetric,
      this.payerType,
    )} with Confirmed Access`;

    this.innerClass.renderGrid();
  }

  currentAndPriorDataForAccessStatus(accessStatus) {
    const orderedAccessStatuses = [
      "confirmed_access",
      "case_by_case",
      "not_covered",
      "access_undetermined",
    ];
    const datasetIndex = orderedAccessStatuses.indexOf(accessStatus);

    const output = {
      prior: { yes: 0, all: 0 },
      current: { yes: 0, all: 0 },
    };

    this.drugs.forEach(drug => {
      const [prior, current] = this.params.data[this.payerType][drug];
      const priorValues = prior.map(accumulateValuesFn(this.payerType, this.coverageMetric));
      const currentValues = current.map(accumulateValuesFn(this.payerType, this.coverageMetric));

      output.prior.yes += priorValues[datasetIndex];
      output.current.yes += currentValues[datasetIndex];
      output.prior.all += _.sum(priorValues);
      output.current.all += _.sum(currentValues);
    });

    return output;
  }

  currentAndPriorDataForTarget(target) {
    return this.currentAndPriorDataForAccessStatus(target.dataset.accessStatus);
  }

  targetIsDisabled() {
    return false;
  }

  onBarClick(event) {
    const accessStatus = $(event.target)
      .closest("[data-access-status-name]")
      .data("access-status-name");

    const accessStatusIndex = orderedAccessStatusNames.indexOf(accessStatus);

    const payerType =
      this.payerType === "medicare_advantage_payers" ? "medicare_advantage" : this.payerType;

    const nouns = payersNoun(this.payerType);

    const title = `${window.PayerTypeLabels[this.payerType]} ${nouns} with ${accessStatus}`;

    const payerIds = {};
    const extraParams = {};

    if (this.showChangesFromPrevious) {
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changedCoverage = { [payerType]: [] };
      extraParams.changeDisplayMode = "access_status";
    }

    this.drugs.forEach(drug => {
      const currentPayerIds = this.params.data[payerType][drug][0][accessStatusIndex].map(
        d => d.payer,
      );

      payerIds[payerType] = _.union(payerIds[payerType], currentPayerIds);

      if (this.showChangesFromPrevious) {
        const priorPayerIds = this.params.data[payerType][drug][1][accessStatusIndex].map(
          d => d.payer,
        );
        const changedPayers = _.xor(currentPayerIds, priorPayerIds);

        payerIds[payerType] = _.union(payerIds[payerType], priorPayerIds);
        extraParams.changedCoverage[payerType].push(
          ...changedPayers.map(payer => ({ payer, drug })),
        );
      }
    });

    showPayersModal(this.drugs, title, payerIds, extraParams);
  }
}
