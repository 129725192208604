import _ from "lodash";
import colors, { lightColors } from "../../utilities/charts/colors";
import USStateChartControllerBase from "./us_state_chart_controller_base";

export default class extends USStateChartControllerBase {
  titleText() {
    return `States requiring Step Therapy for ${this.drug}`;
  }

  fractionForState(payerType, state) {
    const payersByValue = this.params[payerType]?.[this.drug]?.[state];
    const valuesForState = _.flatten(_.keys(payersByValue));

    if (valuesForState.includes("Yes")) {
      return 1;
    } else if (valuesForState.includes("No")) {
      return 0.5;
    } else {
      return 0;
    }
  }

  displayValueFromFraction(fraction) {
    if (fraction > 0.75) {
      return "Yes";
    } else if (fraction > 0.25) {
      return "No";
    } else {
      return "N/I";
    }
  }

  colorForDisplayValue(hasQuantityLimits) {
    if (hasQuantityLimits === "Yes") {
      return colors.blue_gray;
    } else if (hasQuantityLimits === "No") {
      return colors.goldenrod;
    } else {
      return lightColors.gray;
    }
  }

  coverageDetailsAnchor() {
    return "st";
  }

  payersForState(payerType, state) {
    return _.flatten(_.values(this.params[payerType]?.[this.drug]?.[state]));
  }
}
