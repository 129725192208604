import _ from "lodash";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import {
  accumulateValuesFn,
  percentString,
  toOneDecimal,
} from "../../utilities/charts/chart_utils";
import shrinkWidgetToFit from "../../utilities/charts/shrink_widget_to_fit";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  static targets = [
    "grid",
    "title",
    "rowTitle",
    "payersValue",
    "payersPercent",
    "payersTotal",
    "livesValue",
    "livesPercent",
    "livesTotal",
  ];

  initializeParams() {
    const params = super.initializeParams();

    this.enabledTypes = ["Yes", "No", "N/I"];

    return params;
  }

  togglePayerTypeInternal() {
    this.renderChart();
  }

  toggleDrugInternal() {
    this.renderChart();
  }

  updateShownPayersInternal() {
    this.renderChart();
  }

  renderChart() {
    const countsByType = this.countsForShownPayerTypes();
    const totalsWithPolicy = this.totalsWithPolicy(countsByType);

    this.rowTitleTargets.forEach(target => {
      const key = $(target).data("key");

      target.classList.toggle("line-through", !this.enabledTypes.includes(key));
    });

    this.payersValueTargets.forEach(target => {
      const key = $(target).data("key");
      const isEnabled = this.enabledTypes.includes(key);

      target.innerText = isEnabled ? countsByType[key].payers : "";
    });

    this.livesValueTargets.forEach(target => {
      const key = $(target).data("key");
      const isEnabled = this.enabledTypes.includes(key);

      target.innerText = isEnabled ? this.livesString(countsByType[key].lives) : "";
    });

    this.payersPercentTargets.forEach(target => {
      const key = $(target).data("key");
      const numerator = countsByType[key].payers;
      const isEnabled = this.enabledTypes.includes(key);

      target.innerText = isEnabled ? percentString(numerator, totalsWithPolicy.payers) : "";
    });

    this.livesPercentTargets.forEach(target => {
      const key = $(target).data("key");
      const numerator = countsByType[key].lives;
      const isEnabled = this.enabledTypes.includes(key);

      target.innerText = isEnabled ? percentString(numerator, totalsWithPolicy.lives) : "";
    });

    this.payersTotalTarget.innerText = totalsWithPolicy.payers;
    this.livesTotalTarget.innerText = this.livesString(totalsWithPolicy.lives);
    this.titleTarget.innerText = `Has PA Auth/Reauth Length for ${this.drug}?`;

    shrinkWidgetToFit(this.element, this.gridTarget);
  }

  togglePARequiredType(event) {
    const key = $(event.target).data("key");

    this.enabledTypes = _.xor(this.enabledTypes, [key]);

    this.renderChart();
  }

  countsForShownPayerTypes() {
    const counts = {
      Yes: { payers: 0, lives: 0 },
      No: { payers: 0, lives: 0 },
      "N/I": { payers: 0, lives: 0 },
    };

    const forShownPayers = _.pick(this.params[this.drug], this.payerTypesShown);

    _.forIn(forShownPayers, (countsByType, payerType) => {
      Object.entries(countsByType).forEach(([key, payerIds]) => {
        counts[key].payers += accumulateValuesFn(payerType, "payers")(payerIds);
        counts[key].lives += accumulateValuesFn(payerType, "lives")(payerIds);
      });
    });

    return counts;
  }

  totalsWithPolicy(countsByType) {
    const counts = _.values(_.pick(countsByType, this.enabledTypes));
    const countSum = key => _.sum(counts.map(byCoverageMetric => byCoverageMetric[key]));

    return { payers: countSum("payers"), lives: countSum("lives") };
  }

  livesString(count) {
    return count > 0 ? `${toOneDecimal(count).toLocaleString()}m` : "0";
  }
}
