import _ from "lodash";
import { joinNounsWithOr, payersNoun } from "../../utilities/charts/chart_utils";
import colors, { lightColors } from "../../utilities/charts/colors";
import USStateChartControllerBase from "./us_state_chart_controller_base";

export default class extends USStateChartControllerBase {
  titleText() {
    const nouns = payersNoun(this.payerType);

    const preposition = this.params.custom_preposition || `with ${this.numeratorText} for`;

    return `${nouns} ${preposition} ${this.drug} ${this.params.custom_field}`;
  }

  displayValueFromFraction(fraction) {
    const index = _.round(fraction * this.allValues.length);

    return this.allValues[index];
  }

  colorForDisplayValue(value) {
    return this.params.numerator_values.includes(value) ? colors.magenta : lightColors.gray;
  }

  // This does not currently support prioritized values for multiple payers
  fractionForState(payerType, state) {
    const payersByValue = this.params.data[this.drug]?.[payerType]?.[state];

    if (_.isEmpty(payersByValue)) {
      return _.indexOf(this.allValues, "N/I") / this.allValues.length;
    } else {
      const value = _.keys(payersByValue)[0];
      const index = _.indexOf(this.allValues, value);

      return index / this.allValues.length;
    }
  }

  payersForState(payerType, state) {
    const payersByValue = this.params.data[this.drug]?.[payerType]?.[state];

    return _.isEmpty(payersByValue) ? [] : _.flatten(_.values(payersByValue));
  }

  generateLegendLabels(chart) {
    const matchingText =
      this.params.numerator_values.length === 1 ? this.params.numerator_values[0] : "Matching";

    return [
      {
        text: matchingText,
        fillStyle: colors.magenta,
        pointStyle: "rect",
        fontColor: chart.legend.options.labels.color,
      },
      {
        text: "Other",
        fillStyle: lightColors.gray,
        pointStyle: "rect",
        fontColor: chart.legend.options.labels.color,
      },
    ];
  }

  coverageDetailsAnchor() {
    return "custom";
  }

  get allValues() {
    return this.params.all_values[this.drug];
  }

  get numeratorText() {
    return joinNounsWithOr(this.params.numerator_values);
  }
}
