import { Controller } from "stimulus";
import colors from "../../utilities/charts/colors";

const rowTemplate = (name, hexCode) => `
  <div style="background-color: ${hexCode}" class="p-12">${name}</div>
`;

export default class extends Controller {
  connect() {
    Object.entries(colors).forEach(([name, hexCode]) => {
      $(this.element).append(rowTemplate(name, hexCode));
    });
  }
}
