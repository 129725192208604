import _ from "lodash";
import { payersNoun } from "../../utilities/charts/chart_utils";
import ChartControllerBase from "./chart_controller_base";

export default class extends ChartControllerBase {
  static targets = [];

  connect() {
    this.payerTypeInputTargets = $("input[type=radio][name=payer_type]")
      .toArray()
      .filter(target => this.payerTypesIncluded.includes(target.value));

    $(this.payerTypeInputTargets).on("change", event => this.togglePayerTypeFromButton(event));

    if (!this.isFixedDrug) {
      this.drugInputTargets = $("input[type=radio][name=drug]").toArray();
      $(this.drugInputTargets).on("change", event => this.toggleDrug(event));
    }

    this.coverageInputTargets = $("input[name=coverage_metric_toggle]").toArray();
    $(this.coverageInputTargets).on("change", event => this.toggleCoverageMetric(event));

    $("#account_manager").on("change", () => this.updateShownPayers());
    $("#region").on("change", () => this.updateShownPayers());

    this.params = this.initializeParams();
    this.chart = this.renderChart();
  }

  togglePayerTypeFromButton(event) {
    this.payerType = event.target.value;

    this.togglePayerTypeInternal(event.target.value, event.target.checked);

    this.chart?.update();
  }

  toggleCoverageMetric() {
    this.toggleCoverageMetricInternal();

    this.chart?.update();
  }

  toggleDrug(event) {
    const previousDrug = this.drug;

    this.drug = event.target.value;

    this.toggleDrugInternal(previousDrug);

    this.chart?.update();
  }

  updateShownPayers() {
    this.updateShownPayersInternal();

    this.chart?.update();
  }

  initializeParams() {
    const params = JSON.parse(this.data.get("chartData")) || {};

    if (this.inStaticMode) {
      this.payerType = _.first(this.payerTypesIncluded);
      this.drug = _.first(this.drugsIncluded);
    } else {
      const initialPayerType = this.payerTypeInputTargets.find(target => target.checked)?.value;

      this.payerType = initialPayerType || this.payerTypesIncluded[0];

      this.drug = this.isFixedDrug
        ? this.drugsIncluded[0]
        : this.drugInputTargets.find(target => target.checked).value;
    }

    return params;
  }

  get subtitles() {
    return {
      payers: payersNoun(this.payerType),
      lives: "Millions of Lives",
    };
  }

  togglePayerTypeInternal() {
    // Implement in subclass if needed
  }

  toggleCoverageMetricInternal() {
    // Implement in subclass if needed
  }

  toggleDrugInternal() {
    // Implement in subclass if needed
  }
}
