import _ from "lodash";

export default class HtmldiffAsyncRunner {
  constructor(diffCallback, progressEl = null) {
    this.diffCallback = diffCallback;
    this.progressEl = progressEl;
  }

  calculateDiff(before, after) {
    if (_.isEmpty(before) || _.isEmpty(after)) {
      this.diffCallback("<div class='text-gray'>Empty</div>");
    } else if (before.length > 10000 || after.length > 10000) {
      this.diffCallback("<div class='text-gray'>Text too long for changes</div>");
    } else {
      if (this.progressCallback) {
        this.worker.terminate();
        delete this.worker;
      } else {
        this.progressCallback = setTimeout(() => this.onNoticableDelay(), 200);
      }

      if (!this.worker) {
        this.worker = new Worker(new URL("../workers/htmldiff_worker.js", import.meta.url));
        this.worker.onmessage = e => this.onWorkerMessage(e);
      }

      this.worker.postMessage([before, after]);
    }
  }

  onWorkerMessage(event) {
    clearTimeout(this.progressCallback);
    delete this.progressCallback;

    setTimeout(() => this.progressEl?.classList?.add("hidden"), 0);

    this.diffCallback(event.data);
  }

  onNoticableDelay() {
    if (this.progressCallback) {
      this.progressEl?.classList?.remove("hidden");
    }
  }

  withinBounds(text) {
    return !_.isEmpty(text) && text.length < 10000;
  }
}
