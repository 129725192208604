import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["headerContainer", "bodyContainer"];

  connect() {
    this.onScroll();
  }

  onScroll() {
    this.headerContainerTarget.scroll({ left: this.bodyContainerTarget.scrollLeft });
  }
}
