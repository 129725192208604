import { Controller } from "stimulus";

import moment from "moment";

import {
  configure,
  hits,
  pagination,
  panel,
  searchBox,
  refinementList,
  clearRefinements,
} from "instantsearch.js/es/widgets";

import { connectRange } from "instantsearch.js/es/connectors";

const algoliasearch = require("algoliasearch");
const instantsearch = require("instantsearch.js").default;

function buildPanelWithTitle(title) {
  return panel({
    hidden(options) {
      return options.results.nbHits === 0;
    },
    templates: {
      header: title,
    },
    cssClasses: {
      header: "text-gray-600 font-semibold mb-2",
    },
  })(refinementList);
}

function buildRefinementlist(container, title, attribute) {
  return buildPanelWithTitle(title)({
    container,
    attribute,
    operator: "or",
    showMore: true,
    limit: 5,
    cssClasses: {
      count: "badge-sm badge-indigo",
      checkbox: "form-checkbox",
      label: "flex items-center text-base",
      labelText: "px-2",
      disabledShowMore: "hidden",
    },
  });
}

function buildDateRangeRefinement(container, attribute) {
  return connectRange((renderOptions, isFirstRender) => {
    const { start, refine, widgetParams } = renderOptions;
    const [min, max] = start;

    if (isFirstRender) {
      const form = document.createElement("form");

      form.addEventListener("select", event => {
        event.preventDefault();
        const inputElements = event.target.form.elements;

        const rawMinInputValue = Date.parse(inputElements.min.value);
        const rawMaxInputValue = Date.parse(inputElements.max.value);

        refine([
          Number.isFinite(rawMinInputValue) ? rawMinInputValue / 1000 : undefined,
          Number.isFinite(rawMaxInputValue) ? rawMaxInputValue / 1000 : undefined,
        ]);
      });

      widgetParams.container.appendChild(form);

      return;
    }

    widgetParams.container.querySelector("form").innerHTML = `
        <div class="flex flex-col">
          <span class="text-gray-500">From</span>
          <input
            class="form-select text-sm w-32 mt-1"
            data-controller="date-picker"
            name="min"
            placeholder="Pick a date"
            value="${Number.isFinite(min) ? moment.unix(min).format("MM/DD/YYYY") : ""}"
          />
          <span class="text-gray-500 mt-2">To</span>
          <input
            class="form-select text-sm w-32 mt-1"
            data-controller="date-picker"
            name="max"
            placeholder="Pick a date"
            value="${Number.isFinite(max) ? moment.unix(max).format("MM/DD/YYYY") : ""}"
          />
        </div>
      `;
  })({ container, attribute });
}

function buildPagination(container) {
  const customPagination = panel({
    hidden: ({ results }) => results.nbPages < 2,
  })(pagination);

  return customPagination({
    container,
    cssClasses: {
      root: "algolia-pagination",
      list: "algolia-pagination-list",
      item: "algolia-pagination-item",
      selectedItem: "algolia-pagination-item-selected",
      disabledItem: "hidden",
    },
  });
}

export default class extends Controller {
  static targets = [
    "pagination",
    "results",
    "resultTemplate",
    "searchBox",
    "dateRange",
    "clearFilter",
    "clearDateRange",
    "refinement",
  ];

  initialize() {
    this.search = instantsearch({
      indexName: this.data.get("algolia-index"),
      searchClient: algoliasearch(this.data.get("algolia-id"), this.data.get("algolia-key")),
    });
  }

  connect() {
    this.search.addWidgets([
      configure({
        hitsPerPage: this.data.get("page-size") || 4,
        distinct: true,
        filters: this.data.get("filter"),
        facetingAfterDistinct: true,
      }),
      searchBox({
        container: this.searchBoxTarget,
        placeholder: this.data.get("placeholder"),
        autofocus: true,
        showSubmit: false,
        showLoadingIndicator: false,
        showReset: true,
        cssClasses: {
          root: "w-full",
          form: "flex w-full",
          input: "form-input flex-grow",
          reset: "hover:text-teal text-2xl px-4",
        },
        templates: {
          reset: "&times;",
        },
      }),
      buildPagination(this.paginationTarget),
      hits({
        container: this.resultsTarget,
        templates: {
          item: this.resultTemplateTarget.innerHTML,
        },
      }),
    ]);

    this.search.addWidgets(
      this.refinementTargets.map(ref =>
        buildRefinementlist(ref, ref.dataset.title, ref.dataset.field),
      ),
    );

    this.search.addWidgets([
      buildDateRangeRefinement(this.dateRangeTarget, "date_timestamp"),
      clearRefinements({
        container: this.clearDateRangeTarget,
        includedAttributes: ["date_timestamp"],
        templates: {
          resetLabel: "Reset date range",
        },
      }),
    ]);

    this.search.addWidget(
      clearRefinements({
        container: this.clearFilterTarget,
        templates: {
          resetLabel: "Reset all",
        },
      }),
    );

    this.search.start();
  }

  disconnect() {
    this.search.dispose();
  }
}
