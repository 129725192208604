import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["scrollable"];

  connect() {
    if (this.enabled && !window.location.hash) {
      this.restorePosition();
    }
  }

  savePosition() {
    if (this.enabled) {
      const currentPos = this.scrollable.scrollTop;

      if (currentPos > 0) {
        localStorage.setItem(this.key, currentPos);
      } else {
        this.clearPosition(this.key);
      }
    }
  }

  restorePosition() {
    const pos = localStorage.getItem(this.key) || 0;

    requestAnimationFrame(() => this.scrollable.scrollTo({ top: pos }));
  }

  clearPosition(key) {
    localStorage.removeItem(key);
  }

  resetPosition(e) {
    const source = e.target;

    this.data.set("enabled", false);
    this.clearPosition(this.key);

    if (source.href) {
      this.clearPosition(`${this.prefix}-${source.href}`);
    }
  }

  get prefix() {
    return "scout-scroll-position";
  }

  get key() {
    return `${this.prefix}-${this.data.get("key")}`;
  }

  get enabled() {
    return this.data.get("enabled") === "true";
  }

  get scrollable() {
    return this.hasScrollableTarget ? this.scrollableTarget : this.element;
  }
}
