import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "commercialPayers",
    "medicareAdvantagePayers",
    "managedMedicaidPayers",
    "payerTypes",
  ];

  toggleCommercial() {
    this.commercialPayersTarget.classList.remove("hidden");
    this.medicareAdvantagePayersTarget.classList.add("hidden");
    this.managedMedicaidPayersTarget.classList.add("hidden");
    this.payerTypesTarget.classList.add("hidden");
  }

  toggleMedicareAdvantage() {
    this.commercialPayersTarget.classList.add("hidden");
    this.medicareAdvantagePayersTarget.classList.remove("hidden");
    this.managedMedicaidPayersTarget.classList.add("hidden");
    this.payerTypesTarget.classList.add("hidden");
  }

  toggleManagedMedicaid() {
    this.commercialPayersTarget.classList.add("hidden");
    this.medicareAdvantagePayersTarget.classList.add("hidden");
    this.managedMedicaidPayersTarget.classList.remove("hidden");
    this.payerTypesTarget.classList.add("hidden");
  }

  back() {
    this.commercialPayersTarget.classList.add("hidden");
    this.medicareAdvantagePayersTarget.classList.add("hidden");
    this.managedMedicaidPayersTarget.classList.add("hidden");
    this.payerTypesTarget.classList.remove("hidden");
  }
}
