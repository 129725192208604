import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import _ from "lodash";
import moment from "moment";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import colors, { variantLightBlue as mapd } from "../../utilities/charts/colors";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";
import SymbolaArrows from "../../utilities/charts/symbola_arrows";
import {
  accumulateValuesFn,
  formatPayersLivesTitle,
  onHoverPointer,
  orderedAccessStatusNames,
  payersNoun,
  toOneDecimal,
  withinOneDecimalP,
} from "../../utilities/charts/chart_utils";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  connect() {
    super.connect();

    if (this.showChangesFromPrevious) {
      SymbolaArrows.onload(() => this.chart.update());
    }
  }

  togglePayerTypeInternal() {
    this.chart.data.datasets.forEach(dataset => {
      dataset.hidden = !this.payerTypesShown.includes(dataset.payerType);
    });

    this.chart.options.plugins.subtitle.text = this.subtitle();
  }

  toggleCoverageMetricInternal() {
    this.recalculateChartData();

    this.chart.options.plugins.subtitle.text = this.subtitle();
  }

  toggleDrugInternal() {
    this.recalculateChartData();

    this.chart.options.plugins.title.text = `Access Status Report for ${this.drug}`;
  }

  updateShownPayersInternal() {
    this.recalculateChartData();
    this.chart.options.plugins.subtitle.text = this.subtitle();
  }

  recalculateChartData() {
    this.chart.data.datasets.forEach(dataset => {
      dataset.data = this.dataFromParams(dataset.payerType);
    });
  }

  buildDatasets(payerType, backgroundColor, borderColor) {
    const output = [];

    if (this.payerTypesIncluded.includes(payerType)) {
      const label =
        payerType === "medicare_advantage_payers"
          ? `${window.PayerTypeLabels.medicare_advantage} Payers`
          : window.PayerTypeLabels[payerType];

      output.push({
        payerType,
        label,
        data: this.dataFromParams(payerType),
        backgroundColor,
        borderColor,
        borderWidth: 1,
        barPercentage: 1,
        hidden: !this.payerTypesShown.includes(payerType),
      });
    }

    return output;
  }

  renderChart() {
    return new Chart(this.appendCanvas(), {
      type: "bar",
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: !this.inStaticMode,
        scales: {
          x: {
            grid: { display: false },
            ticks: {
              display: !this.inStaticMode,
              font: { size: 11 },
            },
          },
          y: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: !this.inStaticMode,
            position: "bottom",
            onClick: (_event, legendItem) => this.togglePayerTypeFromChart(legendItem),
            labels: {
              boxWidth: 12,
            },
          },
          title: {
            display: true,
            text: `Access Status Report for ${this.drug}`,
            color: "black",
            font: { size: 13 },
          },
          subtitle: {
            display: !this.inStaticMode,
            text: this.subtitle(),
            color: "black",
            font: { size: 13 },
            padding: { bottom: 10 },
          },
          tooltip: {
            callbacks: {
              label: context => this.tooltipLabelText(context),
            },
          },
          datalabels: {
            display: !this.inStaticMode,
            color: c => this.colorForDelta(this.deltaFromChartContext(c)),
            font: c => (this.deltaFromChartContext(c) !== 0 ? { weight: "bold" } : {}),
            anchor: "end",
            align: "end",
            textAlign: "center",
            padding: c => (this.deltaFromChartContext(c) > 0 ? 0 : 4),
            formatter: (v, c) => this.dataLabelWithDelta(v, c),
            listeners: {
              click: event => this.triggerModalFromChartElement(event),
            },
          },
        },
        onClick: event => this.triggerModalFromChart(event),
        onHover: onHoverPointer,
      },
      data: {
        labels: [
          ["Confirmed", "Access"],
          "Case-by-Case",
          "Not Covered",
          ["Access", "Undetermined"],
        ],
        datasets: _.flatten([
          this.buildDatasets("commercial", colors.teal, "#358F9F"),
          this.buildDatasets("medicaid", colors.indigo, "#A5502B"),
          this.buildDatasets("medicare", colors.magenta, "#77446F"),
          this.buildDatasets("medicare_advantage", mapd.backgroundColor, mapd.borderColor),
          this.buildDatasets("medicare_advantage_payers", mapd.backgroundColor, mapd.borderColor),
          this.buildDatasets("managed_medicaid", colors.mauve, "#863749"),
          this.buildDatasets("benefit_management", colors.blue_gray, "#52508b"),
        ]),
      },
    });
  }

  subtitle() {
    const date = moment(this.params.current_date).format("MMMM YYYY");

    return `${formatPayersLivesTitle(this.coverageMetric, this.payerTypesShown)} (${date})`;
  }

  tooltipLabelText(context) {
    const standardLine = `${context.dataset.label}: ${context.parsed.y}`;
    const delta = this.deltaFromChartContext(context);

    if (delta === 0) {
      return standardLine;
    } else {
      const deltaWord = delta > 0 ? "Increased" : "Decreased";

      return [standardLine, `${deltaWord}: ${toOneDecimal(Math.abs(delta))}`];
    }
  }

  dataFromParams(payerType) {
    const format = x => toOneDecimal(accumulateValuesFn(payerType, this.coverageMetric)(x));
    const data = this.params.data[payerType][this.drug];

    return _.isEmpty(data) ? [] : _.last(data).map(format);
  }

  deltaFromChartContext(context) {
    const { payerType } = context.dataset;
    const format = accumulateValuesFn(payerType, this.coverageMetric);

    const priorValue = format(this.params.data[payerType][this.drug][0][context.dataIndex]);
    const currentValue = format(this.params.data[payerType][this.drug][1][context.dataIndex]);

    if (!this.showChangesFromPrevious || withinOneDecimalP(priorValue, currentValue)) {
      return 0;
    } else {
      return currentValue - priorValue;
    }
  }

  dataLabelWithDelta(value, context) {
    const delta = this.deltaFromChartContext(context);

    if (delta === 0) {
      return value;
    } else if (delta > 0) {
      return [value, SymbolaArrows.up];
    } else {
      return [SymbolaArrows.down, value];
    }
  }

  colorForDelta(delta) {
    if (delta === 0) {
      return "#000";
    } else if (delta > 0) {
      return "#6b7e38"; // text-green-500
    } else {
      return "#e02424"; // text-red
    }
  }

  triggerModalFromChart(event) {
    const clickLocation = parseClick(this.chart, event);

    if (!clickLocation) return;

    this.triggerModalInternal(clickLocation.datasetIndex, clickLocation.index);
  }

  triggerModalFromChartElement(indexes) {
    this.triggerModalInternal(indexes.datasetIndex, indexes.dataIndex);
  }

  triggerModalInternal(payerTypeIndex, accessStatusIndex) {
    let payerType = this.payerTypesShown[payerTypeIndex];

    if (payerType === "medicare_advantage_payers") payerType = "medicare_advantage";

    const payerIds = {};
    const extraParams = {};

    const payerIdsFor = index =>
      this.params.data[payerType][this.drug][index][accessStatusIndex].map(d => d.payer);

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(1);
      const priorPayers = payerIdsFor(0);

      payerIds[payerType] = _.union(currentPayers, priorPayers);
      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = {
        [payerType]: changedPayers.map(payer => ({ payer, drug: this.drug })),
      };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changeDisplayMode = "access_status";
    } else {
      payerIds[payerType] = payerIdsFor(1);
    }

    const accessStatus = orderedAccessStatusNames[accessStatusIndex];
    const title = `${payersNoun(this.payerTypesShown)} with ${accessStatus} for ${this.drug}`;

    showPayersModal(this.drug, title, payerIds, extraParams);
  }
}
