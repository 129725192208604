import _ from "lodash";
import ManyDrugsManyPayerTypesControllerBase from "./many_drugs_many_payer_types_controller_base";
import BarsChartGridBase from "./bars_chart_grid_base";
import shrinkWidgetToFit from "../../utilities/charts/shrink_widget_to_fit";
import colors from "../../utilities/charts/colors";
import showPayersModal from "../../utilities/charts/payers_modal";
import { formatPayersLivesTitle, accumulateValuesFn } from "../../utilities/charts/chart_utils";

export default class extends ManyDrugsManyPayerTypesControllerBase {
  static targets = ["title", "gridTitle", "gridFraction", "gridPercent", "gridChangeIndicator"];

  connect() {
    this.innerClass = new BarsChartGridBase(this);
    super.connect();
  }

  toggleCoverageMetricInternal() {
    this.renderChart();
  }

  togglePayerTypesInternal() {
    this.renderChart();
  }

  toggleDrugsInternal() {
    this.renderChart();
  }

  updateShownPayersInternal() {
    this.renderChart();
  }

  renderChart() {
    this.renderTitle();
    this.innerClass.renderGrid();
    this.setBarColors();
    shrinkWidgetToFit(this.element, this.element.children[0]);
  }

  renderTitle() {
    const nouns = formatPayersLivesTitle(this.coverageMetric, this.payerTypesShown);

    this.titleTarget.innerText = `${nouns} with Confirmed Access`;
  }

  setBarColors() {
    this.gridPercentTargets.forEach(target => {
      $(target)
        .find(".bar")
        .css({ backgroundColor: this.colorForDrug(target.dataset.drug) });
    });
  }

  currentAndPriorDataForTarget(target) {
    const { drug, payerType } = target.dataset;

    if (payerType) {
      return this.currentAndPriorDataForParams(payerType, drug);
    } else {
      const output = {
        prior: { yes: 0, all: 0 },
        current: { yes: 0, all: 0 },
      };

      this.payerTypesShown.forEach(shownPayerType => {
        const { prior, current } = this.currentAndPriorDataForParams(shownPayerType, drug);

        output.prior.yes += prior.yes;
        output.prior.all += prior.all;
        output.current.yes += current.yes;
        output.current.all += current.all;
      });

      return output;
    }
  }

  currentAndPriorDataForParams(payerType, drug) {
    const [prior, current] = this.params.data[payerType][drug];

    const priorCounts = prior.map(accumulateValuesFn(payerType, this.coverageMetric));
    const currentCounts = current.map(accumulateValuesFn(payerType, this.coverageMetric));

    return {
      prior: { yes: priorCounts[0], all: _.sum(priorCounts) },
      current: { yes: currentCounts[0], all: _.sum(currentCounts) },
    };
  }

  targetIsDisabled(target) {
    const { drug, payerType } = target.dataset;

    // !!datum here converts undefined to false, avoiding toggleClass(..., undefined)
    // which regresses to jquery's on-argument "invert the state" instead of "set it to this".
    const payerTypeDisabled = !!payerType && !this.payerTypesShown.includes(payerType);
    const drugDisabled = !!drug && !this.drugs.includes(drug);

    return payerTypeDisabled || drugDisabled;
  }

  colorForDrug(drug) {
    const priorityColors = [colors.mauve, colors.goldenrod, colors.indigo];
    const otherColors = _.values(colors).filter(c => !priorityColors.includes(c));
    const colorList = priorityColors.concat(...otherColors);

    const drugIndex = this.drugsIncluded.indexOf(drug);

    return colorList[drugIndex % colorList.length];
  }

  onBarClick(event) {
    const { drug } = event.target.dataset;

    let payerTypes = this.payerTypesShown;

    if (event.target.dataset.payerType) {
      payerTypes = [event.target.dataset.payerType];
    }

    payerTypes = payerTypes.map(p =>
      p === "medicare_advantage_payers" ? "medicare_advantage" : p,
    );

    const payerIdsFor = (payerType, datasetIndex) =>
      _.flatMap(this.params.data[payerType][drug][datasetIndex][0].map(d => d.payer));

    const payerIds = {};
    const extraParams = {};

    if (this.showChangesFromPrevious) {
      extraParams.changedCoverage = {};
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changeDisplayMode = "access_status";
    }

    payerTypes.forEach(payerType => {
      if (this.showChangesFromPrevious) {
        const currentPayers = payerIdsFor(payerType, 1);
        const priorPayers = payerIdsFor(payerType, 0);

        payerIds[payerType] = _.union(currentPayers, priorPayers);
        const changedPayers = _.xor(currentPayers, priorPayers);

        extraParams.changedCoverage[payerType] = changedPayers.map(payer => ({ payer, drug }));
      } else {
        payerIds[payerType] = payerIdsFor(payerType, 1);
      }
    });

    showPayersModal(drug, "confirmed", payerIds, extraParams);
  }

  onBarRemainderClick(event) {
    const { drug } = event.target.dataset;

    let payerTypes = this.payerTypesShown;

    if (event.target.dataset.payerType) {
      payerTypes = [event.target.dataset.payerType];
    }

    payerTypes = payerTypes.map(p =>
      p === "medicare_advantage_payers" ? "medicare_advantage" : p,
    );

    const payerIdsFor = (payerType, datasetIndex) =>
      _.flatMap(this.params.data[payerType][drug][datasetIndex].slice(1), ds =>
        ds.map(d => d.payer),
      );

    const payerIds = {};
    const extraParams = {};

    if (this.showChangesFromPrevious) {
      extraParams.changedCoverage = {};
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changeDisplayMode = "access_status";
    }

    payerTypes.forEach(payerType => {
      if (this.showChangesFromPrevious) {
        const currentPayers = payerIdsFor(payerType, 1);
        const priorPayers = payerIdsFor(payerType, 0);

        payerIds[payerType] = _.union(currentPayers, priorPayers);
        const changedPayers = _.xor(currentPayers, priorPayers);

        extraParams.changedCoverage[payerType] = changedPayers.map(payer => ({
          payer,
          drug: this.drug,
        }));
      } else {
        payerIds[payerType] = payerIdsFor(payerType, 1);
      }
    });

    showPayersModal(drug, "unconfirmed", payerIds, extraParams);
  }
}
