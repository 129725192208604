import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showable", "count"];

  static values = { form: String };

  connect() {
    this.checkboxTargets.on("input", () => this.update());

    this.update();
  }

  update() {
    const count = this.selectedCount;

    $(this.countTargets).text(count);
    $(this.showableTargets).toggleClass("hidden", count === 0);
  }

  get checkboxTargets() {
    return $(`#${this.formValue} input[type=checkbox]`);
  }

  get selectedCount() {
    return $(`#${this.formValue} input[type=checkbox]:checked`).length;
  }
}
