import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { url: String, filename: String };

  async download() {
    const response = await fetch(this.urlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
    });

    const data = await response.blob();
    const objectUrl = URL.createObjectURL(new Blob([data]));

    const downloadLink = document.createElement("a");

    downloadLink.href = objectUrl;
    downloadLink.setAttribute("download", this.filenameValue);
    downloadLink.click();

    URL.revokeObjectURL(objectUrl);
  }

  async downloadAndReload() {
    await this.download();

    setTimeout(() => {
      window.location.reload();
    }, 0);
  }
}
