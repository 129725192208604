import { Controller } from "stimulus";

import "blueimp-file-upload/js/jquery.fileupload";

export default class extends Controller {
  static targets = [
    "file",
    "placeholder",
    "progress",
    "clear",
    "imagePreview",
    "key",
    "name",
    "dropzone",
  ];

  connect() {
    this.form = $(this.fileTarget).closest("form");
    const dropzone = this.hasDropzoneTarget ? this.dropzoneTarget : document;

    dropzone.addEventListener("dragover", this.showDropzone.bind(this));
    dropzone.addEventListener("dragleave", this.hideDropzone.bind(this));
    dropzone.addEventListener("drop", this.hideDropzone.bind(this));

    $(this.fileTarget).fileupload({
      fileInput: this.fileTarget,
      url: this.data.get("url"),
      type: "POST",
      autoUpload: true,
      paramName: "file",
      dataType: "XML",
      replaceFileInput: true,
      dropZone: dropzone,
      add: (e, data) => {
        const fileData = { ...data };
        const selectedFile = fileData.files[0];

        this.nameTarget.value = selectedFile.name;

        const formData = JSON.parse(this.data.get("fields"));

        formData["Content-Type"] = selectedFile.type;
        fileData.formData = formData;
        fileData.submit();
      },
      start: () => {
        this.maybePlaceholderTarget?.classList.add("hidden");
        this.progressTarget.innerText = "Uploading...";
        this.maybeImagePreviewTarget?.classList.add("hidden");
      },
      progressall: (e, data) => {
        const progress = parseInt((data.loaded / data.total) * 100);

        this.maybePlaceholderTarget?.classList.add("hidden");
        this.progressTarget.innerText = `Uploading...${progress}%`;
      },
      done: (e, data) => {
        const key = $(data.jqXHR.responseXML).find("Key").text();

        this.keyTarget.value = key;

        this.maybePlaceholderTarget?.classList.add("hidden");
        this.maybeClearTarget?.classList.remove("hidden");

        if (this.autoSubmit) {
          this.fileTarget.form.submit();
        } else if (this.hasImagePreviewTarget) {
          this.previewImage(data.files[0]);
        } else {
          this.progressTarget.innerText = data.files[0].name;
        }
      },
      fail() {
        this.progressTarget.innerText = "Upload failed!";
      },
    });

    this.dispatchConnectedEvent();
  }

  clear() {
    this.fileTarget.value = "";
    this.maybeClearTarget?.classList.add("hidden");
    this.progressTarget.innerText = "";
    this.maybeImagePreviewTarget?.classList.add("hidden");
    this.keyTarget.value = "";
    this.nameTarget.value = "";

    this.maybePlaceholderTarget?.classList.remove("hidden");
  }

  previewImage(file) {
    const reader = new FileReader();

    reader.onload = event => {
      this.progressTarget.innerText = "";
      this.imagePreviewTarget.classList.remove("hidden");
      this.imagePreviewTarget.src = event.target.result;
    };

    reader.readAsDataURL(file);
  }

  dispatchConnectedEvent() {
    this.element.dispatchEvent(new CustomEvent("file-input-connected", { bubbles: true }));
  }

  showDropzone(event) {
    const { items } = event.dataTransfer;

    if (items.length !== 0 && items[0].kind === "file") {
      this.dropzoneTarget.classList.add("border-teal");
      this.dropzoneTarget.classList.add("bg-teal-25");
    }
  }

  hideDropzone() {
    this.dropzoneTarget.classList.remove("border-teal");
    this.dropzoneTarget.classList.remove("bg-teal-25");
  }

  get maybeImagePreviewTarget() {
    return this.hasImagePreviewTarget ? this.imagePreviewTarget : null;
  }

  get maybePlaceholderTarget() {
    return this.hasPlaceholderTarget ? this.placeholderTarget : null;
  }

  get maybeClearTarget() {
    return this.hasClearTarget ? this.clearTarget : null;
  }

  get autoSubmit() {
    return this.data.get("auto-submit");
  }
}
