import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["lockIcon", "unlockIcon", "unlockButton", "content"];

  static values = {
    unlocked: Boolean,
  };

  connect() {
    if (this.unlockedValue) {
      this.unlock();
    } else {
      this.lock();
    }
  }

  lock() {
    this.element.disabled = true;
    this.lockIconTarget.classList.remove("hidden");
    this.unlockIconTarget.classList.add("hidden");
    this.unlockButtonTarget.classList.remove("hidden");
    this.contentTarget.classList.add("opacity-50");
  }

  unlock() {
    this.element.disabled = false;
    this.lockIconTarget.classList.add("hidden");
    this.unlockIconTarget.classList.remove("hidden");
    this.unlockButtonTarget.classList.add("hidden");
    this.contentTarget.classList.remove("opacity-50");
  }
}
