/* eslint-disable import/prefer-default-export */
import _ from "lodash";
import moment from "moment";
import applyPayerFilters from "./filter_payers";

export const orderedAccessStatusNames = [
  "Confirmed Access",
  "Case-by-Case",
  "Not Covered",
  "Access Undetermined",
];

export const payersNoun = payerTypes => {
  let payerType = null;

  if (_.isArray(payerTypes)) {
    if (payerTypes.length > 1) {
      return "Payers";
    } else {
      payerType = payerTypes[0];
    }
  } else {
    payerType = payerTypes;
  }

  switch (payerType) {
    case "medicaid":
      return "Agencies";
    case "medicare":
      return "Contractors";
    case "benefit_management":
      return "Managers";
    case "medicare_advantage":
    case "managed_medicaid":
      return "Plans";
    case "commercial":
    case "medicare_advantage_payers":
    default:
      return "Payers";
  }
};

export const coverageMetricNoun = (coverageMetric, payerTypes) => {
  if (coverageMetric === "lives") {
    return "Lives";
  } else {
    return payersNoun(payerTypes);
  }
};

export const joinNounsWithOr = nouns => {
  if (nouns.length <= 2) {
    return nouns.join(" or ");
  } else {
    const last = _.last(nouns);
    const rest = _.dropRight(nouns, 1)
      .map(n => `${n},`)
      .join(" ");

    return [rest, last].join(" or ");
  }
};

const accumulateValues = (coverageMetric, payerPairs) =>
  coverageMetric === "lives" ? _.sum(payerPairs.map(d => d.lives)) : payerPairs.length;

export const accumulateValuesFn = (payerType, coverageMetric) => payerPairs =>
  accumulateValues(coverageMetric, applyPayerFilters(payerType, payerPairs));

export const toOneDecimal = value => (value ? value.toFixed(1).replace(".0", "") : "0");

export const withinOneDecimal = (a, b) => a.toFixed(3) === b.toFixed(3);

export const withinOneDecimalP = (a, b) => a.toFixed(1) === b.toFixed(1);

export const fraction = (numerator, denominator) =>
  denominator === 0 ? 0 : numerator / denominator;

export const percent = (numerator, denominator) => fraction(numerator, denominator) * 100;

export const percentString = (numerator, denominator) =>
  `${toOneDecimal(percent(numerator, denominator))}%`;

export const omitMissingOverTimeItems = data =>
  data.filter(({ all }) => (_.isNumber(all) ? all > 0 : !_.isEmpty(all)));

export const formatPayersLivesTitle = (coverageMetric, payerType) =>
  coverageMetric === "lives" ? "Millions of Lives" : payersNoun(payerType);

export const minDate = chart =>
  moment.min(_.flattenDeep(_.values(chart.params.data).map(_.values)).map(d => moment(d.date)));

export const maxDate = chart =>
  moment.max(_.flattenDeep(_.values(chart.params.data).map(_.values)).map(d => moment(d.date)));

export const overTimeX = (chart, timestamp) => moment(timestamp).diff(minDate(chart), "days");

export const maxOverTimeX = chart => overTimeX(chart, maxDate(chart));

export const onHoverPointer = (e, el) => {
  $(e.chart.canvas).css("cursor", el[0] ? "pointer" : "default");
};

const dateFromOverTimeX = (chart, x) => minDate(chart).add(x, "days");

const monthsFromStart = (chart, x) => dateFromOverTimeX(chart, x).diff(minDate(chart), "months");

const absoluteDateOverTimeLabel = chart => (xValue, index, allTicks) => {
  const thisDate = minDate(chart).add(xValue, "days");
  const lastDate = minDate(chart).add(allTicks[index - 1]?.value, "days");

  const yearsMatch = index > 0 && thisDate.year() === lastDate.year();
  const monthsMatch = index > 0 && thisDate.month() === lastDate.month();

  if (monthsMatch) {
    return "";
  } else if (yearsMatch) {
    return thisDate.format("MMM");
  } else {
    return thisDate.format("MMM YY");
  }
};

const relativeDateOverTimeLabel = chart => (xValue, index, allTicks) => {
  if (index === 0) return "Launch";

  const thisDate = minDate(chart).add(xValue, "days");
  const monthsPostLaunch = thisDate.diff(minDate(chart), "months");

  const previousXValue = allTicks[index - 1].value;
  const previousDate = minDate(chart).add(previousXValue, "days");

  const differentMonths =
    thisDate.year() !== previousDate.year() || thisDate.month() !== previousDate.month();

  if (differentMonths) {
    return `M${monthsPostLaunch}`;
  } else {
    return null;
  }
};

const singleXValueTooltip = (tooltipItem, _index, allTooltipItems) =>
  tooltipItem.parsed.x === allTooltipItems[0].parsed.x;

export const absoluteDateOverTimeCallbacks = chart => ({
  ticks: absoluteDateOverTimeLabel(chart),
  tooltip: {
    title: items => dateFromOverTimeX(chart, items[0].parsed.x).format("MMMM YYYY"),
    label: item => `${item.dataset.label} - ${toOneDecimal(item.parsed.y)}%`,
    filter: singleXValueTooltip,
  },
});

export const relativeDateOverTimeCallbacks = chart => ({
  ticks: relativeDateOverTimeLabel(chart),
  tooltip: {
    title: items => `${monthsFromStart(chart, items[0].parsed.x)} months post launch`,
    label: item => `${item.dataset.label} - ${toOneDecimal(item.parsed.y)}%`,
    filter: singleXValueTooltip,
  },
});

// The same payer might have multiple different values for a given custom field if the custom field is a multiselect.
// However, for cases where the "total" number of payers/lives is what's important, we discard those duplicates.
export const uniqPayersFromCustomFields = byCustomFieldValue =>
  _.uniqBy(_.flatten(_.values(byCustomFieldValue)), datum => datum.payer);
