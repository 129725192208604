import _ from "lodash";
import { Controller } from "stimulus";
import { screenWithinBreakpoint } from "../utilities/screen_size_breakpoints";
import PreserveScrollPosition from "../utilities/preserve_scroll_position";

const drugCountBreakpoints = {
  "": 1,
  sm: 2,
  md: 3,
  xl: 4,
  "2k": 6,
  "4k": 8,
  "8k": 16,
};

export default class extends Controller {
  static targets = ["column", "section", "sectionTitle", "gutterLeft", "gutterRight"];

  connect() {
    this.shiftAmount = 0;
    this.scrollCompensation = new PreserveScrollPosition(this.sectionTargets);

    window.addEventListener("resize", this.onScreenResize.bind(this));

    this.updateVisiblity();
  }

  onScreenResize() {
    this.scrollCompensation.clear();

    this.updateVisiblity();
  }

  updateVisiblity() {
    this.hideOverflowingColumns();
    this.toggleGutters();
  }

  shiftLeft(event) {
    this.shiftAmount = Math.max(this.shiftAmount - 1, 0);

    this.updateVisiblityPreservingVerticalPosition(event.target);
  }

  shiftRight(event) {
    this.shiftAmount = Math.min(this.shiftAmount + 1, this.drugCount - 1);

    this.updateVisiblityPreservingVerticalPosition(event.target);
  }

  hideOverflowingColumns() {
    const shownIndices = this.shownDrugIndices();

    this.columnTargets.forEach(target => {
      target.classList.toggle("hidden", !shownIndices.includes(parseInt(target.dataset.index)));
    });
  }

  updateVisiblityPreservingVerticalPosition(elementToPreserve) {
    this.scrollCompensation.preservePosition(this.sectionNearest(elementToPreserve));

    this.updateVisiblity();
  }

  toggleGutters() {
    const shownIndices = this.shownDrugIndices();

    const showLeftGutter = this.overflowExists() && !shownIndices.includes(0);
    const showRightGutter = this.overflowExists() && !shownIndices.includes(this.drugCount - 1);

    $(this.sectionTargets)
      .toggleClass("rounded-l-xl", !showLeftGutter)
      .toggleClass("rounded-r-xl", !showRightGutter)
      .css("margin-left", showLeftGutter ? "0" : "")
      .css("margin-right", showRightGutter ? "0" : "");

    $(this.sectionTitleTargets).css("margin-left", !showLeftGutter ? "0" : "");

    $(this.gutterLeftTargets).toggleClass("hidden", !showLeftGutter);
    $(this.gutterRightTargets).toggleClass("hidden", !showRightGutter);
  }

  shownDrugIndices() {
    const visibleDrugCount = this.maxVisibleDrugs();
    const shiftWithoutWrap = Math.min(this.shiftAmount, this.drugCount - visibleDrugCount);

    return _.times(visibleDrugCount, i => shiftWithoutWrap + i);
  }

  overflowExists() {
    return !screenWithinBreakpoint(
      _.findKey(drugCountBreakpoints, drugCount => drugCount >= this.drugCount),
    );
  }

  maxVisibleDrugs() {
    return _.findLast(drugCountBreakpoints, (_count, breakpoint) =>
      screenWithinBreakpoint(breakpoint),
    );
  }

  sectionNearest(element) {
    let nearestSection = null;

    let minDistance = Infinity;

    const rect = element.getBoundingClientRect();

    const distanceFrom = otherElement => {
      const otherRect = otherElement.getBoundingClientRect();

      return Math.min(Math.abs(rect.top - otherRect.top), Math.abs(rect.bottom - otherRect.bottom));
    };

    this.sectionTargets.forEach(section => {
      const distance = distanceFrom(section);

      if (distance < minDistance) {
        minDistance = distance;
        nearestSection = section;
      }
    });

    return nearestSection;
  }

  get drugCount() {
    return $(this.sectionTargets[0]).find("[data-coverage-details-carousel-target=column]").length;
  }
}
