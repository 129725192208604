import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["row"];

  copyToAll(event) {
    const inputsToCopy = event.currentTarget
      .closest("[data-copy-row-inputs-target='row']")
      .querySelectorAll("input");

    this.rowTargets.forEach(row => {
      const rowInputs = row.querySelectorAll("input");

      rowInputs.forEach((ri, i) => {
        ri.value = inputsToCopy[i].value;
      });
    });
  }
}
