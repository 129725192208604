import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["toggle", "select", "link"];

  connect() {
    const params = this.currentHashParams();

    this.toggleTargets.forEach(toggle => {
      const checkedValues = params[toggle.name];

      if (checkedValues) {
        toggle.checked = params[toggle.name].includes(toggle.value);
      }
    });

    this.selectTargets.forEach(select => {
      const checkedValue = params[select.name];

      if (checkedValue) {
        select.value = checkedValue;
      }
    });

    this.updateHash(); // Discards junk/irrelevant entries
  }

  reset(event) {
    const exclusions = event.target.dataset.breadcrumbsAsTogglesResetOnChange?.split(" ");
    const newHash = this.toggleTargets
      .filter(toggle => toggle.checked)
      .concat(this.selectTargets)
      .filter(t => !exclusions.includes(t.name))
      .map(toggle => `${toggle.name}=${toggle.value}`)
      .join("&");

    window.location.hash = newHash;
    window.location.reload();
  }

  updateHash() {
    const newHash = this.toggleTargets
      .filter(toggle => toggle.checked)
      .concat(this.selectTargets)
      .map(toggle => `${toggle.name}=${toggle.value}`)
      .join("&");

    window.location.hash = newHash;

    this.linkTargets.forEach(target => {
      const url = new URL(target.href);

      url.hash = newHash;
      target.href = url;
    });
  }

  currentHashParams() {
    const paramPairs = window.location.hash
      .slice(1)
      .split("&")
      .filter(assignment => !!assignment)
      .map(assignment => assignment.split("="))
      .filter(([key, value]) => {
        const toggleExists = this.toggleTargets.some(t => t.name === key && t.value === value);
        const selectExists = _.map(
          this.selectTargets.find(s => s.name === key)?.options,
          o => o.value === value,
        );

        return toggleExists || selectExists;
      });

    const params = {};

    paramPairs.forEach(([key, value]) => {
      if (!params[key]) params[key] = [];
      params[key].push(value);
    });

    return params;
  }
}
