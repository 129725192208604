import { Controller } from "stimulus";
import InputMask from "inputmask";

export default class extends Controller {
  static targets = ["price", "noPriceReason", "reset"];

  connect() {
    this.inputMask = new InputMask("decimal", {
      digits: "4",
      rightAlign: false,
      enforceDigitsOnBlur: true,
      allowMinus: false,
    });
    this.inputMask.mask(this.priceTarget);
    if (this.priceTarget.value) {
      this.hideNoPriceReason();
    } else if (this.noPriceReasonTarget.value) {
      this.hidePrice();
    }
  }

  reset() {
    this.priceTarget.value = null;
    this.noPriceReasonTarget.selectize.clear();
    this.noPriceReasonTarget.parentElement.classList.remove("hidden");
    this.priceTarget.parentElement.classList.add("flex");
    this.priceTarget.parentElement.classList.remove("hidden");
    this.resetTarget.classList.add("hidden");
  }

  onPriceInput() {
    if (this.priceTarget.value) {
      this.hideNoPriceReason();
    } else {
      this.reset();
    }
  }

  onNoPriceReasonBlur() {
    if (this.noPriceReasonTarget.value) {
      this.hidePrice();
    } else {
      this.reset();
    }
  }

  hidePrice() {
    this.priceTarget.parentElement.classList.remove("flex");
    this.priceTarget.parentElement.classList.add("hidden");
    this.resetTarget.classList.remove("hidden");
  }

  hideNoPriceReason() {
    this.noPriceReasonTarget.parentElement.classList.add("hidden");
    this.resetTarget.classList.remove("hidden");
  }
}
