import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["name", "url", "message"];

  static values = { endpoint: String };

  validate() {
    const message = this.messageTarget;

    fetch(this.endpointValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify({
        name: this.nameTarget.value,
        url: this.urlTarget.value,
      }),
    })
      .then(resp => resp.text())
      .then(html => {
        message.innerHTML = html;
      });
  }
}
