import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["rowContainer", "rowTemplate"];

  addRow() {
    const newRow = document.createElement("div");

    newRow.innerHTML = this.rowTemplateTarget.innerHTML;

    this.rowContainerTarget.appendChild(newRow);
  }

  removeRow(event) {
    const row = event.currentTarget.parentElement;

    this.rowContainerTarget.removeChild(row);
  }
}
