import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["tableEntry", "hiddenWhenPayerTypeAmbiguous", "shownWhenPayerTypeAmbiguous"];

  static values = { fixedPayerType: String };

  connect() {
    new MutationObserver(this.onTableEntriesChanged.bind(this)).observe(this.element, {
      childList: true,
    });

    this.onTableEntriesChanged();
  }

  removeExistingCoverage(event) {
    const taskId = event.currentTarget.dataset.coverageTaskId;

    this.tableEntryTargets.find(t => t.dataset.coverageTaskId === taskId).remove();
  }

  addRelatedCoverage() {
    this.triggerModal("related");
  }

  addOtherCoverage() {
    this.triggerModal("other");
  }

  triggerModal(collection) {
    $(this.modalFormTarget).find('input[name="task_id[]"]').remove();
    this.tableEntryTargets.forEach(t => {
      $(this.modalFormTarget).append(
        `<input type="hidden" name="task_id[]" value="${t.dataset.coverageTaskId}">`,
      );
    });

    $(this.modalFormTarget).find("input[name=payer_type]").val(this.currentPayerType);
    $(this.modalFormTarget).find("input[name=collection]").val(collection);
    $(this.modalFormTarget).find("input[type=submit]").click();
  }

  onTableEntriesChanged() {
    const ambiguous = _.isEmpty(this.currentPayerType);

    $(this.shownWhenPayerTypeAmbiguousTargets).toggleClass("hidden", !ambiguous);
    $(this.hiddenWhenPayerTypeAmbiguousTargets).toggleClass("hidden", ambiguous);
  }

  get currentPayerType() {
    if (this.hasFixedPayerTypeValue) {
      return this.fixedPayerTypeValue;
    } else if (this.hasTableEntryTarget) {
      return this.tableEntryTargets[0].dataset.payerType;
    } else {
      return "";
    }
  }

  get modalFormTarget() {
    return document.getElementById("modal_form");
  }
}
