import _ from "lodash";
import colors, { lightColors } from "../../utilities/charts/colors";
import USStateChartControllerBase from "./us_state_chart_controller_base";

export default class extends USStateChartControllerBase {
  titleText() {
    return `States where PA is required for ${this.drug}`;
  }

  fractionForState(payerType, state) {
    const payersByPolicyType = this.params.data[payerType]?.[this.drug]?.[state];

    if (_.isEmpty(_.flatten(_.values(payersByPolicyType)))) return 0;

    const indexes = _.keys(payersByPolicyType).map(p => this.orderedAllValues.indexOf(p));

    return _.max(indexes) / this.params.all_values.length;
  }

  displayValueFromFraction(fraction) {
    const index = _.round(fraction * this.params.all_values.length);

    return index === 0 ? "N/I" : this.orderedAllValues[index];
  }

  colorForDisplayValue(paType) {
    if (paType === "N/I" || paType === "No") {
      return lightColors.gray;
    } else {
      return colors.magenta;
    }
  }

  generateLegendLabels(chart) {
    return [
      {
        text: "Yes",
        fillStyle: colors.magenta,
        pointStyle: "rect",
        fontColor: chart.legend.options.labels.color,
      },
      {
        text: "Other",
        fillStyle: lightColors.gray,
        pointStyle: "rect",
        fontColor: chart.legend.options.labels.color,
      },
    ];
  }

  payersForState(payerType, state) {
    return _.flatten(_.values(this.params.data[payerType]?.[this.drug]?.[state]));
  }

  coverageDetailsAnchor() {
    return "prior-auth";
  }

  get orderedAllValues() {
    // Ordering "" first plays nicely with implicit blanks being known 0
    return [""].concat(_.without(this.params.all_values, ""));
  }
}
