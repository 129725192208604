import { Controller } from "stimulus";
import "@selectize/selectize";

export default class extends Controller {
  connect() {
    const plugins = ["silent_remove"];
    const conditionalOptions = {};

    const isMulti = this.element.attributes.multiple;
    const inModal = $(this.element).parents(".modal").length > 0;
    const addSingle = this.element.attributes.selectizeAddSingle;
    const addMulti = $(this.element).data("tags");

    if (isMulti) {
      plugins.push("remove_button");
    }

    if (inModal) {
      conditionalOptions.onDropdownOpen = this.buildDropdownRepositionCallback();
    }

    if (addSingle || addMulti) {
      conditionalOptions.create = true;

      conditionalOptions.createFilter = function (input) {
        return Object.values(this.options).every(
          option => option.text.toLowerCase() !== input.toLowerCase(),
        );
      };
    }

    if (addMulti) {
      conditionalOptions.onItemAdd = function (value, item) {
        if (value.length === 0 && isMulti) {
          this.removeItem(item, true);
        }
      };
    }

    $(this.element)
      .selectize({
        width: "100%",
        plugins,
        placeholder: $(this.element).data("placeholder"),
        openOnFocus: true,
        closeAfterSelect: true,
        allowEmptyOption: true,
        respect_word_boundaries: false,
        selectOnTab: true,
        maxOptions: this.element.length,
        onFocus() {
          if (this.getValue().length === 0) {
            this.clear();
          }
        },
        ...conditionalOptions,
      })
      .on("change", () => this.element.dispatchEvent(new Event("scoutchange", { bubbles: true })));

    document.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.teardown();
      },
      { once: true },
    );

    if (this.element.title) {
      $(this.element).siblings(".selectize-control").attr("title", this.element.title);
    }
  }

  teardown() {
    this.element?.selectize?.destroy();
  }

  buildDropdownRepositionCallback() {
    return $dropdown => {
      const dropdownPosition = $dropdown[0].getBoundingClientRect();
      const dropdownSpillover = dropdownPosition.bottom - $(window).height();

      if (dropdownSpillover > 0) {
        $dropdown.css("top", dropdownPosition.top - dropdownSpillover);
      }
    };
  }
}
