import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    Sortable.create(this.element, {
      animation: 150,
      handle: "[data-sortable-handle='drag']",
      fallbackOnBody: true,
    });
  }
}
