import _ from "lodash";

let initialized = false;

let regionFilters = null;

let accountManagerFilters = null;

const initializeFilters = () => {
  if (!initialized) {
    const regions = document.getElementById("region");
    const accountManagers = document.getElementById("account_manager");

    if (regions) {
      regionFilters = JSON.parse(regions.dataset.payerFilters);
    }

    if (accountManagers) {
      accountManagerFilters = JSON.parse(accountManagers.dataset.payerFilters);
    }

    initialized = true;
  }
};

const parsePayerId = datum => {
  if (typeof datum === "number") {
    return datum;
  } else {
    return datum.payer;
  }
};

const comparePairToId = (pair, id) => parsePayerId(pair) === id;

export default (payerType, payerPairs) => {
  initializeFilters();

  const currentRegion = document.getElementById("region")?.value;
  const currentAccountManager = document.getElementById("account_manager")?.value;

  if (!currentRegion && !currentAccountManager) {
    return payerPairs;
  }

  let filteredPayerPairs = [...payerPairs];

  if (currentRegion) {
    filteredPayerPairs = _.intersectionWith(
      filteredPayerPairs,
      regionFilters[currentRegion][payerType],
      comparePairToId,
    );
  }

  if (currentAccountManager === "unassigned") {
    const allAccountManagers = _.mapValues(accountManagerFilters, am => am[payerType]);
    const idsFromAllAccountManagers = _.flatten(_.values(allAccountManagers));

    filteredPayerPairs = _.differenceWith(
      filteredPayerPairs,
      idsFromAllAccountManagers,
      comparePairToId,
    );
  } else if (currentAccountManager) {
    filteredPayerPairs = _.intersectionWith(
      filteredPayerPairs,
      accountManagerFilters[currentAccountManager][payerType],
      comparePairToId,
    );
  }

  return filteredPayerPairs;
};
