import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["action", "confirm", "confirmExtra"];

  connect() {
    this.reset();
  }

  confirm() {
    this.actionTarget.classList.add("hidden");
    this.confirmTarget.removeAttribute("disabled");
    this.confirmTarget.classList.remove("hidden");
    this.confirmExtraTargets.forEach(target => target.classList.remove("invisible"));
    this.confirmTarget.focus();
  }

  onClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.reset();
    }
  }

  reset() {
    this.actionTarget.classList.remove("hidden");
    this.confirmTarget.setAttribute("disabled", "disabled");
    this.confirmTarget.classList.add("hidden");
    this.confirmExtraTargets.forEach(target => target.classList.add("invisible"));
  }
}
