import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import colors from "../../utilities/charts/colors";
import {
  onHoverPointer,
  payersNoun,
  percentString,
  toOneDecimal,
} from "../../utilities/charts/chart_utils";
import SymbolaArrows from "../../utilities/charts/symbola_arrows";

export default class {
  constructor(outerClass) {
    this.outerClass = outerClass;
  }

  updateChartData() {
    this.outerClass.chart.data.datasets.forEach(dataset => {
      dataset.data = this.outerClass.formatData(dataset.stack);
    });
  }

  renderChart() {
    return new Chart(this.outerClass.appendCanvas(), {
      type: "bar",
      plugins: [ChartDataLabels],
      options: {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        animation: !this.outerClass.inStaticMode,
        scales: {
          x: {
            suggestedMin: 0,
            suggestedMax: 100,
            ticks: {
              display: !this.outerClass.inStaticMode,
              callback: value => `${value}%`,
            },
            grid: { drawBorder: false },
          },
          y: {
            ticks: { display: !this.outerClass.inStaticMode },
            grid: { display: false },
          },
        },
        plugins: {
          legend: {
            display: !this.outerClass.inStaticMode,
            position: "bottom",
            labels: {
              boxWidth: 12,
            },
          },
          tooltip: {
            mode: "index",
            callbacks: {
              label: context => this.tooltipLabelText(context),
            },
          },
          title: {
            display: true,
            text: this.outerClass.titleText,
            color: "black",
            font: { size: 13 },
          },
          datalabels: {
            anchor: "end",
            labels: {
              value: {
                display: !this.outerClass.inStaticMode,
                align: c => (this.datalabelInsideBar(c) ? "left" : "right"),
                formatter: (value, context) => this.dataLabelPossiblyWithArrow(value, context),
                color: c =>
                  this.datalabelInsideBar(c) ? "#eee" : this.deltaColorFromChartContext(c),
                offset: c => (this.datalabelInsideBar(c) ? 8 : 0),
                font: {
                  weight: "bold",
                },
              },
              arrow: {
                display: c =>
                  !this.outerClass.inStaticMode &&
                  this.datalabelInsideBar(c) &&
                  this.outerClass.deltaFromChartContext(c) !== 0,
                align: "right",
                formatter: (_value, context) =>
                  this.outerClass.deltaFromChartContext(context) > 0
                    ? SymbolaArrows.right
                    : SymbolaArrows.left,
                color: c => this.deltaColorFromChartContext(c),
                offset: 0,
              },
            },
          },
        },
        onClick: event => this.outerClass.showModal(event, false),
        onHover: onHoverPointer,
      },
      data: {
        datasets: [
          {
            label: payersNoun(this.outerClass.payerTypesIncluded),
            data: this.outerClass.formatData("payers"),
            backgroundColor: colors.magenta,
            stack: "payers",
            barPercentage: 1,
          },
          {
            label: "Lives",
            data: this.outerClass.formatData("lives"),
            backgroundColor: colors.teal,
            stack: "lives",
            barPercentage: 1,
          },
        ],
      },
    });
  }

  datalabelInsideBar(context) {
    return context.dataset.data[context.dataIndex].x >= 15;
  }

  deltaColorFromChartContext(context) {
    const delta = this.outerClass.deltaFromChartContext(context);

    if (delta > 0) {
      return "#6b7e38"; // text-green-500
    } else if (delta < 0) {
      return "#e02424"; // text-red
    } else {
      return "#000";
    }
  }

  dataLabelPossiblyWithArrow(value, context) {
    const delta = this.outerClass.deltaFromChartContext(context);
    const percent = percentString(value.x, 100);

    if (this.datalabelInsideBar(context) || delta === 0) {
      return percent;
    } else if (delta > 0) {
      return `${percent} ${SymbolaArrows.right}`;
    } else {
      return `${SymbolaArrows.left} ${percent}`;
    }
  }

  tooltipLabelText(context) {
    const standardLine = `${context.dataset.label}: ${toOneDecimal(context.parsed.x)}%`;
    const delta = this.outerClass.deltaFromChartContext(context);

    if (delta > 0) {
      return `${standardLine} (+${toOneDecimal(delta)})`;
    } else if (delta < 0) {
      return `${standardLine} (${toOneDecimal(delta)})`;
    } else {
      return standardLine;
    }
  }
}
