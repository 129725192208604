import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("trix-initialize", () => {
      const underlineButton = document.createElement("button");
      const linkButton = this.toolbar.querySelector("trix-button--icon-link");

      underlineButton.setAttribute("type", "button");
      underlineButton.setAttribute(
        "class",
        "trix-button trix-button--icon trix-button--icon-underline",
      );
      underlineButton.setAttribute("data-trix-attribute", "underline");
      underlineButton.setAttribute("title", "Underline");
      underlineButton.setAttribute("tabindex", "-1");
      underlineButton.innerText = "U";

      this.toolbar
        .querySelector(".trix-button-group--text-tools")
        .insertBefore(underlineButton, linkButton);
    });
  }

  disconnect() {
    this.toolbar.querySelector("[data-trix-attribute=underline]").remove();
  }

  get toolbar() {
    return this.element.previousSibling;
  }
}
