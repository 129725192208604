import { Controller } from "stimulus";
import "@selectize/selectize";

export default class extends Controller {
  connect() {
    $(this.element).selectize({
      valueField: "id",
      labelField: "name",
      searchField: ["name", "email"],
      dataAttr: "data-details",
      create: false,
      preload: "focus",
      render: {
        option(item) {
          return `
            <div class="option flex flex-col px-4 py-2">
              <div class="text-gray-800 font-semibold">
                ${item.name}
              </div>
              <div class="flex justify-between flex-col sm:flex-row sm:space-x-8">
                <div class="text-sm text-gray-600 truncate flex-shrink-0">
                  ${item.email || "No email"}
                </div>
                <div class="text-sm text-gray-600 truncate">
                  ${item.employer || "No employer"}
                </div>
              </div>
            </div>
          `;
        },
      },
      // eslint-disable-next-line consistent-return
      load: (query, callback) => {
        if (!query.length) return callback();

        $.ajax({
          url: `/contacts/autocomplete?q=${encodeURIComponent(query)}`,
          type: "GET",
          error() {
            callback();
          },
          success(response) {
            callback(response.results);
          },
        });
      },
    });

    document.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.teardown();
      },
      { once: true },
    );
  }

  teardown() {
    this.element?.selectize?.destroy();
  }
}
