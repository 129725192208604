import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const alignmentRow = document.createElement("span");
    const fileTools = this.toolbar.querySelector(".trix-button-group--file-tools");

    alignmentRow.setAttribute("class", "trix-button-group trix-button-group--alignment-tools");
    this.toolbar.querySelector(".trix-button-row").insertBefore(alignmentRow, fileTools);

    const leftButton = document.createElement("button");

    leftButton.setAttribute("type", "button");
    leftButton.setAttribute("class", "trix-button trix-button--icon trix-button--icon-align-left");
    leftButton.setAttribute("data-trix-attribute", "alignLeft");
    leftButton.setAttribute("title", "Left Align");
    leftButton.setAttribute("tabindex", "-1");

    this.toolbar.querySelector(".trix-button-group--alignment-tools").appendChild(leftButton);

    const centerButton = document.createElement("button");

    centerButton.setAttribute("type", "button");
    centerButton.setAttribute(
      "class",
      "trix-button trix-button--icon trix-button--icon-align-center",
    );
    centerButton.setAttribute("data-trix-attribute", "alignCenter");
    centerButton.setAttribute("title", "Center Align");
    centerButton.setAttribute("tabindex", "-1");

    this.toolbar.querySelector(".trix-button-group--alignment-tools").appendChild(centerButton);

    const rightButton = document.createElement("button");

    rightButton.setAttribute("type", "button");
    rightButton.setAttribute(
      "class",
      "trix-button trix-button--icon trix-button--icon-align-right",
    );
    rightButton.setAttribute("data-trix-attribute", "alignRight");
    rightButton.setAttribute("title", "Right Align");
    rightButton.setAttribute("tabindex", "-1");

    this.toolbar.querySelector(".trix-button-group--alignment-tools").appendChild(rightButton);
  }

  disconnect() {
    this.toolbar.querySelector("[data-trix-attribute=alignCenter]").remove();
    this.toolbar.querySelector("[data-trix-attribute=alignLeft]").remove();
    this.toolbar.querySelector("[data-trix-attribute=alignRight]").remove();
  }

  get toolbar() {
    return this.element.previousSibling;
  }
}
