import _ from "lodash";

export const screenBreakpoints = {
  "": 0,
  xs: 576,
  sm: 776,
  md: 968,
  lg: 1192,
  xl: 1400,
  "2k": 1921,
  "4k": 2561,
  "5k": 3841,
  "8k": 5121,
};

export const currentScreenBreakpoint = () =>
  _.findLastKey(screenBreakpoints, v => v < window.innerWidth);

export const screenWithinBreakpoint = breakpoint => {
  const screenPriority = _.keys(screenBreakpoints).indexOf(currentScreenBreakpoint());
  const breakpointPriority = _.keys(screenBreakpoints).indexOf(breakpoint);

  return breakpointPriority <= screenPriority;
};

export const isMobile = () => !screenWithinBreakpoint("md");
