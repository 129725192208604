import _ from "lodash";
import { stateAbbreviation } from "../../utilities/charts/state_abbreviation_map";
import ConfirmedAccessUSStateMapChartController from "./confirmed_access_us_state_map_chart_controller";

const MAX_LINE_LENGTH = 45;
const WORD_WRAP = new RegExp(
  `(?![^\\n]{1,${MAX_LINE_LENGTH}}$)([^\\n]{1,${MAX_LINE_LENGTH}})\\s`,
  "g",
);

export default class extends ConfirmedAccessUSStateMapChartController {
  titleText() {
    return `${this.drug} Medicaid Outreach`;
  }

  tooltipCallbacks() {
    const that = this;

    return _.merge(super.tooltipCallbacks(), {
      beforeFooter: () => "Outreach Summary:",
      footer: items => {
        const state = stateAbbreviation(items[0].raw.feature);

        let outreachSummary = that.params.medicaid_outreach_status[that.drug]?.[state];

        if (_.isEmpty(outreachSummary)) {
          outreachSummary = "N/I";
        }

        return that.wrapLines(outreachSummary);
      },
    });
  }

  wrapLines(text) {
    const lines = text
      .replace(WORD_WRAP, "$1\n")
      .split("\n")
      .map(t => t.trim());

    if (lines.length > 3) {
      const truncated = lines.slice(0, 3);

      truncated[2] += "…";
      return truncated;
    } else {
      return lines;
    }
  }

  coverageDetailsAnchor() {
    return "outreach";
  }
}
