import _ from "lodash";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import ChartControllerWrapper from "./payers_v_lives_chart_controller_base";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";
import SymbolaArrows from "../../utilities/charts/symbola_arrows";
import {
  accumulateValuesFn,
  payersNoun,
  percent,
  withinOneDecimalP,
} from "../../utilities/charts/chart_utils";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  connect() {
    this.innerClass = new ChartControllerWrapper(this);
    super.connect();

    if (this.showChangesFromPrevious) {
      SymbolaArrows.onload(() => this.chart.update());
    }
  }

  togglePayerTypeInternal() {
    this.chart.options.plugins.title.text = this.titleText;

    this.innerClass.updateChartData();
  }

  toggleDrugInternal() {
    this.chart.options.plugins.title.text = this.titleText;

    this.innerClass.updateChartData();
  }

  updateShownPayersInternal() {
    this.innerClass.updateChartData();
  }

  formatData(coverageMetric) {
    return this.payerTypesShown.map(payerType => {
      const currentData = this.params.data[payerType][this.drug][1];
      const currentCounts = currentData.map(accumulateValuesFn(payerType, coverageMetric));
      const currentPercent = percent(currentCounts[0], _.sum(currentCounts));

      return { x: currentPercent, y: window.PayerTypeLabels[payerType] };
    });
  }

  deltaFromChartContext(context) {
    const payerType = this.payerTypesShown[context.dataIndex];
    const coverageMetric = context.dataset.stack;

    const [priorData, currentData] = this.params.data[payerType][this.drug];

    const priorCounts = priorData.map(accumulateValuesFn(payerType, coverageMetric));
    const currentCounts = currentData.map(accumulateValuesFn(payerType, coverageMetric));

    const priorPercent = percent(priorCounts[0], _.sum(priorCounts));
    const currentPercent = percent(currentCounts[0], _.sum(currentCounts));

    if (this.showChangesFromPrevious && !withinOneDecimalP(priorPercent, currentPercent)) {
      return currentPercent - priorPercent;
    } else {
      return 0;
    }
  }

  showModal(event, alreadyParsed) {
    const clickLocation = alreadyParsed ? event : parseClick(this.chart, event);

    if (!clickLocation) return;

    let payerType = this.payerTypesShown[clickLocation.index];

    if (payerType === "medicare_advantage_payers") payerType = "medicare_advantage";

    const payerIds = {};
    const extraParams = {};

    const payerIdsFor = datasetIndex =>
      this.params.data[payerType][this.drug][datasetIndex][0].map(d => d.payer);

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(1);
      const priorPayers = payerIdsFor(0);

      payerIds[payerType] = _.union(currentPayers, priorPayers);
      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = {
        [payerType]: changedPayers.map(payer => ({ payer, drug: this.drug })),
      };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changeDisplayMode = "access_status";
    } else {
      payerIds[payerType] = payerIdsFor(1);
    }

    showPayersModal(this.drug, "confirmed", payerIds, extraParams);
  }

  renderChart() {
    return this.innerClass.renderChart();
  }

  get titleText() {
    return `Uptake of ${this.drug} ${payersNoun(this.payerTypesShown)} v. Lives`;
  }
}
