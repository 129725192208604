import { percentString, toOneDecimal, withinOneDecimal } from "../../utilities/charts/chart_utils";
import colors from "../../utilities/charts/colors";

export default class {
  constructor(outerClass) {
    this.outerClass = outerClass;
  }

  renderGrid() {
    const barColor = this.colorsByCoverageMetric[this.outerClass.coverageMetric];

    this.outerClass.gridTitleTargets.forEach(target => {
      this.strikethroughIfDisabled(target);
    });

    this.outerClass.gridFractionTargets.forEach(target => {
      const { yes, all } = this.outerClass.currentAndPriorDataForTarget(target).current;
      const suffix = this.outerClass.coverageMetric === "lives" ? "m" : "";
      const format = x => `${toOneDecimal(x)}${suffix}`;

      target.innerText = `${format(yes)}/${format(all)}`;

      this.strikethroughIfDisabled(target);
    });

    this.outerClass.gridPercentTargets.forEach(target => {
      const { current, prior } = this.outerClass.currentAndPriorDataForTarget(target);

      const currentPercent = current.yes / current.all || 0;
      const priorPercent = prior.yes / prior.all || 0;

      const mainPercent = this.outerClass.showChangesFromPrevious
        ? Math.max(currentPercent, priorPercent)
        : currentPercent;

      $(target)
        .find(".text")
        .text(percentString(current.yes, current.all))
        .toggleClass("hidden", this.outerClass.targetIsDisabled(target));

      $(target)
        .find(".bar")
        .css({ transform: `scaleX(${mainPercent})`, backgroundColor: barColor })
        .toggleClass("hidden", this.outerClass.targetIsDisabled(target));

      $(target)
        .find(".bar-remainder")
        .css({ transform: `scaleX(${1 - mainPercent})` })
        .toggleClass("hidden", this.outerClass.targetIsDisabled(target) || current.all === 0);
    });

    this.outerClass.gridChangeIndicatorTargets.forEach(target => {
      const { prior, current } = this.outerClass.currentAndPriorDataForTarget(target);
      const priorFraction = prior.yes / prior.all || 0;
      const currentFraction = current.yes / current.all || 0;
      const arrow = $(target).find("svg");

      if (
        !this.outerClass.showChangesFromPrevious ||
        withinOneDecimal(priorFraction, currentFraction)
      ) {
        target.title = null;
        $(arrow).addClass("hidden");
      } else if (currentFraction > priorFraction) {
        target.title = `Increased ${toOneDecimal((currentFraction - priorFraction) * 100)}`;
        $(arrow)
          .removeClass(["hidden", "text-red"])
          .addClass("text-green")
          .css({ transform: "rotate(0)" });
      } else {
        target.title = `Decreased ${toOneDecimal((priorFraction - currentFraction) * 100)}`;
        $(arrow)
          .removeClass(["hidden", "text-green"])
          .addClass("text-red")
          .css({ transform: "rotate(180deg)" });
      }
    });
  }

  strikethroughIfDisabled(target) {
    $(target).toggleClass("line-through", this.outerClass.targetIsDisabled(target));
  }

  get colorsByCoverageMetric() {
    return {
      payers: colors.magenta,
      lives: colors.teal,
    };
  }
}
