import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["portalName"];

  setPortalName(event) {
    if (event.target.value) {
      this.portalNameTarget.value = `${event.target.innerText} Portal`;
    } else {
      this.portalNameTarget.value = "";
    }
  }
}
