import _ from "lodash";
import { orderedAccessStatusNames } from "../../utilities/charts/chart_utils";
import colors, { lightColors } from "../../utilities/charts/colors";
import USStateChartControllerBase from "./us_state_chart_controller_base";

export default class extends USStateChartControllerBase {
  titleText() {
    return `${this.drug} ${window.PayerTypeLabels[this.payerType]} Access Status`;
  }

  fractionForState(payerType, state) {
    const payersByAccessStatus = this.params.data[payerType]?.[this.drug]?.[state];

    if (_.isEmpty(_.flatten(payersByAccessStatus))) return 0;

    const indexesWithPayers = payersByAccessStatus
      .map((_payers, i) => i)
      .filter(i => !_.isEmpty(payersByAccessStatus[i]));

    const fractionsForIndexes = indexesWithPayers.map(index => {
      switch (orderedAccessStatusNames[index]) {
        case "Confirmed Access":
          return 1;
        case "Case-by-Case":
          return 0.75;
        case "Access Undetermined":
          return 0.5;
        case "Not Covered":
          return 0.25;
        default:
          return 0;
      }
    });

    return _.max(fractionsForIndexes);
  }

  displayValueFromFraction(fraction) {
    if (fraction > 0.8) {
      return "Confirmed Access";
    } else if (fraction > 0.6) {
      return "Case-by-Case";
    } else if (fraction > 0.4) {
      return "Access Undetermined";
    } else if (fraction > 0.2) {
      return "Not Covered";
    } else {
      return "N/I";
    }
  }

  colorForDisplayValue(accessStatus) {
    switch (accessStatus) {
      case "Confirmed Access":
        return colors.pale_green;
      case "Case-by-Case":
        return colors.goldenrod;
      case "Access Undetermined":
        return lightColors.gray;
      case "Not Covered":
        return colors.mauve;
      default:
        return "#ffffff";
    }
  }

  payersForState(payerType, state) {
    return _.flatten(this.params.data[payerType]?.[this.drug]?.[state]);
  }
}
