import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["overflowable", "show"];

  connect() {
    new ResizeObserver(() => this.showWhenOverflow()).observe(this.overflowableTarget);

    this.showWhenOverflow();
  }

  showWhenOverflow() {
    this.showTarget.classList.toggle("hidden", !this.hasOverflow);
  }

  get hasOverflow() {
    // +1 is necessary because Chrome has been known to round subpixels inconsistently.
    // E.g. if both elements are 18.31px, clientHeight will be 18 while scrollHeight is 19
    return this.overflowableTarget.scrollHeight > this.overflowableTarget.clientHeight + 1;
  }
}
