import _ from "lodash";
import { buildChart, updateChartData } from "../../utilities/charts/payers_v_lives_over_time_chart";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";
import {
  accumulateValuesFn,
  joinNounsWithOr,
  omitMissingOverTimeItems,
  overTimeX,
  payersNoun,
  percent,
  relativeDateOverTimeCallbacks,
  uniqPayersFromCustomFields,
} from "../../utilities/charts/chart_utils";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  togglePayerTypeInternal() {
    updateChartData(this.chart, this.formatData.bind(this), payersNoun(this.payerTypesShown));
  }

  toggleDrugInternal() {
    updateChartData(this.chart, this.formatData.bind(this), payersNoun(this.payerTypesShown));
  }

  updateShownPayersInternal() {
    updateChartData(this.chart, this.formatData.bind(this), payersNoun(this.payerTypesShown));
  }

  renderChart() {
    return buildChart(
      this.appendCanvas(),
      payersNoun(this.payerTypesShown),
      this.titleText,
      relativeDateOverTimeCallbacks(this),
      this.formatData.bind(this),
      this.triggerModal.bind(this),
      this.inStaticMode,
    );
  }

  formatData(coverageMetric) {
    const forShownPayers = _.pick(this.params.data[this.drug], this.payerTypesShown);

    const accumulatedData = {};

    _.forIn(forShownPayers, (datapoints, payerType) => {
      datapoints.forEach(datapoint => {
        const counted = this.countConfirmedValues(datapoint, payerType, coverageMetric);

        if (accumulatedData[counted.date]) {
          accumulatedData[counted.date].confirmed += counted.confirmed;
          accumulatedData[counted.date].all += counted.all;
        } else {
          accumulatedData[counted.date] = counted;
        }
      });
    });

    const pointsWithValues = omitMissingOverTimeItems(_.values(accumulatedData));

    const data = pointsWithValues.map(({ date, confirmed, all }) => ({
      x: overTimeX(this, date),
      y: percent(confirmed, all),
      date,
    }));

    return _.sortBy(data, point => point.x);
  }

  countConfirmedValues(datapoint, payerType, coverageMetric) {
    const accumulate = values =>
      accumulateValuesFn(payerType, coverageMetric)(uniqPayersFromCustomFields(values));

    return {
      date: datapoint.date,
      confirmed: accumulate(_.pick(datapoint.values, ...this.params.numerator_values)),
      all: accumulate(datapoint.values),
    };
  }

  triggerModal(event) {
    const clickLocation = parseClick(this.chart, event);

    if (!clickLocation) return;

    const payerTypes = this.payerTypesShown.map(payerType =>
      payerType === "medicare_advantage_payers" ? "medicare_advantage" : payerType,
    );

    const dataForPayerTypes = _.pick(this.params.data[this.drug], payerTypes);

    const dataForClickLocation = _.mapValues(dataForPayerTypes, data => data[clickLocation.index]);
    const presentData = _.pickBy(dataForClickLocation, d => !_.isEmpty(d));

    const payerIds = {};

    _.forIn(presentData, (data, payerType) => {
      _.flatten(_.values(_.pick(data.values, this.params.numerator_values))).forEach(datum => {
        payerIds[payerType] ||= [];
        if (!payerIds[payerType].includes(datum.payer)) {
          payerIds[payerType].push(datum.payer);
        }
      });
    });

    const title = `${payersNoun(payerTypes)} with ${this.numeratorDescriptionText}`;
    const dataset = this.chart.data.datasets[clickLocation.datasetIndex];

    const maxIndex = dataset.data.length - 1;

    if (clickLocation.index === maxIndex) {
      showPayersModal(this.drug, title, payerIds);
    } else {
      const timestamp = dataset.data[clickLocation.index].date;

      showPayersModal(this.drug, title, payerIds, { timestamp });
    }
  }

  get titleText() {
    const nouns = payersNoun(this.payerTypesShown);
    const preposition =
      this.params.custom_preposition || `with ${joinNounsWithOr(this.params.numerator_values)} for`;

    return `% of ${nouns} v. % of Lives ${preposition} ${this.drug} ${this.params.custom_field}`;
  }

  get numeratorDescriptionText() {
    const formattedNumerator = joinNounsWithOr(this.params.numerator_values);

    return `${formattedNumerator} for ${this.drug} ${this.params.custom_field}`;
  }
}
