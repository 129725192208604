import _ from "lodash";
import ChartControllerBase from "./chart_controller_base";

export default class extends ChartControllerBase {
  static targets = [];

  connect() {
    this.payerTypeInputTargets = $("input[type=checkbox][name=payer_types]")
      .toArray()
      .filter(target => this.payerTypesIncluded.includes(target.value));

    $(this.payerTypeInputTargets).on("change", event => this.togglePayerTypeFromButton(event));

    if (!this.isFixedDrug) {
      this.drugInputTargets = $("input[type=radio][name=drug]").toArray();
      $(this.drugInputTargets).on("change", event => this.toggleDrug(event));
    }

    this.coverageInputTargets = $("input[name=coverage_metric_toggle]").toArray();
    $(this.coverageInputTargets).on("change", event => this.toggleCoverageMetric(event));

    $("#account_manager").on("change", () => this.updateShownPayers());
    $("#region").on("change", () => this.updateShownPayers());

    this.params = this.initializeParams();
    this.chart = this.renderChart();
  }

  togglePayerTypeFromChart(legendItem) {
    const { payerType } = this.chart.data.datasets[legendItem.datasetIndex];
    const button = this.payerTypeInputTargets.find(target => target.value === payerType);

    $(button).trigger("click");
  }

  togglePayerTypeFromButton(event) {
    const payerType = event.target.value;

    if (this.payerTypesIncluded.includes(payerType)) {
      this.payerTypesShown = _.xor(this.payerTypesShown, [payerType]).sort();

      this.togglePayerTypeInternal(event.target.value, event.target.checked);

      this.chart?.update();
    }
  }

  toggleCoverageMetric() {
    this.toggleCoverageMetricInternal();

    this.chart?.update();
  }

  toggleDrug(event) {
    const previousDrug = this.drug;

    this.drug = event.target.value;

    this.toggleDrugInternal(previousDrug);

    this.chart?.update();
  }

  updateShownPayers() {
    this.updateShownPayersInternal();

    this.chart?.update();
  }

  initializeParams() {
    const params = JSON.parse(this.data.get("chartData")) || {};

    if (this.inStaticMode) {
      this.payerTypesShown = this.data.get("payer-types-included").split("|");
      this.drug = _.first(this.drugsIncluded);
    } else {
      this.payerTypesShown = this.payerTypeInputTargets
        .filter(target => target.checked)
        .map(target => target.value);

      this.drug = this.isFixedDrug
        ? this.drugsIncluded[0]
        : this.drugInputTargets.find(target => target.checked).value;
    }

    return params;
  }

  togglePayerTypeInternal() {
    // Implement in subclass if needed
  }

  toggleCoverageMetricInternal() {
    // Implement in subclass if needed
  }

  toggleDrugInternal() {
    // Implement in subclass if needed
  }
}
