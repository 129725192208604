import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("turbolinks:before-cache", this.onTurbolinksBeforeCache, {
      once: true,
    });
  }

  disconnect() {
    document.removeEventListener("turbolinks:before-cache", this.onTurbolinksBeforeCache, {
      once: true,
    });
  }

  onTurbolinksBeforeCache = () => {
    this.close();
  };

  close() {
    if (this.element.parentNode.contains(this.element)) {
      this.element.parentNode.removeChild(this.element);
    }
  }
}
