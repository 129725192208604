import { Controller } from "stimulus";
import HtmldiffAsyncRunner from "../utilities/htmldiff_async_runner";

export default class extends Controller {
  static targets = ["output"];

  static values = { snapshot: String, current: String };

  connect() {
    this.htmldiffRunner = new HtmldiffAsyncRunner(this.renderDiff.bind(this));
    this.update();
  }

  update() {
    this.htmldiffRunner.calculateDiff(this.snapshotValue, this.currentValue);
  }

  renderDiff(diff) {
    this.outputTarget.innerHTML = [
      "<div class='trix-content htmldiff-output'>",
      diff,
      "</div>",
    ].join("\n");
  }
}
