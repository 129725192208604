import { Controller } from "stimulus";
import spinner from "../templates/spinner";

export default class extends Controller {
  runReport(event) {
    event.preventDefault();

    this.render(this.payers, spinner);

    this.fetch().then(resp => {
      this.render(this.payers, resp.payers);
      this.render(this.results, resp.results);
    });
  }

  selectPayer(event) {
    event.preventDefault();

    this.render(this.results, spinner);

    this.fetch().then(resp => {
      this.render(this.results, resp.results);
      this.highlightSelectedPayer();
    });
  }

  fetch() {
    return fetch(this.element.action, {
      method: "POST",
      body: new FormData(this.element),
    }).then(resp => resp.json());
  }

  render(el, html) {
    el.innerHTML = html;
    el.children[0].scrollIntoView();
  }

  highlightSelectedPayer() {
    Array.from(this.payers.querySelectorAll("[data-payer]")).forEach(e =>
      e.classList.remove("border-teal", "bg-teal-50"),
    );
    this.payers
      .querySelector(`[data-payer="${this.payerId}"]`)
      .classList.add("border-teal", "bg-teal-50");
  }

  get payerId() {
    return this.data.get("payerId");
  }

  get payers() {
    return document.getElementById("payers");
  }

  get results() {
    return document.getElementById("results");
  }
}
