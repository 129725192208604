import { Controller } from "stimulus";
import Sortable from "sortablejs";
import Rails from "@rails/ujs";
import _ from "lodash";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    const isSync = this.data.get("mode") === "synchronous";

    Sortable.create(this.element, {
      animation: 150,
      handle: "[data-sortable-handle='drag']",
      fallbackOnBody: true,
      store: {
        get: sortable => sortable.toArray(),
        set: sortable => (isSync ? this.sortSync(sortable) : this.sortAsync(sortable)),
      },
    });
  }

  sortSync(sortable) {
    const inputTag = item => `<input type='hidden' name='ordered_items[]' value='${item}'>`;

    sortable.toArray().forEach(sortableItem => $(this.formTarget).append(inputTag(sortableItem)));
    this.formTarget.submit();
  }

  sortAsync(sortable) {
    const url = this.data.get("url");

    if (_.isEmpty(url)) return;

    fetch(this.data.get("url"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify({
        ordered_items: sortable.toArray(),
      }),
    }).then(resp => {
      if (resp.status >= 500) {
        alert("Something went wrong when you tried to reorder a list."); // eslint-disable-line
      }
    });
  }
}
