export default (container, target) => {
  const $container = $(container);
  const $target = $(target);

  const vertical = $container.height() / $target.height();
  const horizontal = $container.width() / $target.width();
  const percentOutOfBounds = Math.min(vertical, horizontal);

  if (percentOutOfBounds < 1) {
    $target.css({
      transform: `scale(${percentOutOfBounds})`,
      transformOrigin: "top",
    });
  }
};
