import Chart from "chart.js/auto";
import colors from "./colors";

export const updateChartData = (chart, buildData, payersNoun) => {
  chart.data.datasets[0].data = buildData("payers");
  chart.data.datasets[1].data = buildData("lives");

  if (payersNoun) {
    chart.data.datasets[0].label = payersNoun;
  }
};

export const buildChart = (
  canvas,
  payersNoun,
  titleText,
  formattingCallbacks,
  buildData,
  triggerModalCallback,
  inStaticMode,
) =>
  new Chart(canvas, {
    type: "scatter",
    options: {
      responsive: true,
      maintainAspectRatio: false,
      animation: !inStaticMode,
      showLine: true,
      interaction: {
        intersect: false,
        mode: "x",
      },
      scales: {
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            display: !inStaticMode,
            callback: value => `${value}%`,
          },
        },
        x: {
          bounds: "data",
          ticks: {
            display: !inStaticMode,
            callback: formattingCallbacks.ticks,
          },
        },
      },
      plugins: {
        legend: {
          display: !inStaticMode,
          position: "bottom",
          labels: { usePointStyle: true },
        },
        title: {
          display: true,
          text: titleText,
          color: "black",
          font: { size: 13 },
        },
        tooltip: {
          usePointStyle: true,
          titleAlign: "center",
          filter: formattingCallbacks.tooltip.filter,
          callbacks: formattingCallbacks.tooltip,
        },
      },
      onClick: triggerModalCallback,
    },
    data: {
      datasets: [
        {
          coverageMetric: "payers",
          label: `% of ${payersNoun}`,
          backgroundColor: "#A6A3D7",
          borderColor: colors.magenta,
          pointStyle: "circle",
          pointRadius: 5,
          data: buildData("payers"),
        },
        {
          coverageMetric: "lives",
          label: "% of Lives",
          pointRadius: 5,
          backgroundColor: "#8BDFFF",
          borderColor: colors.teal,
          pointStyle: "rect",
          data: buildData("lives"),
        },
      ],
    },
  });
