import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "display"];

  static values = { nouns: String };

  connect() {
    this.update();
  }

  update() {
    const checkedTargets = this.checkboxTargets.filter(c => c.checked);
    const checkedCount = checkedTargets.length;

    if (checkedCount === 0) {
      this.displayTarget.innerText = "None";
    } else if (checkedCount === 1) {
      this.displayTarget.innerText = this.matchingLabelText(checkedTargets[0]);
    } else if (checkedCount === this.checkboxTargets.length) {
      this.displayTarget.innerText = "All";
    } else {
      this.displayTarget.innerText = `${checkedCount} ${this.nounsValue}`;
    }
  }

  matchingLabelText(checkbox) {
    return $(checkbox).closest("label").text().trim();
  }
}
