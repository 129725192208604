import moment from "moment";

// Ensure it works with turbolinks

const updateTimeStamps = () => {
  const timeHelpers = document.querySelectorAll("[data-time-format]");
  const oneMinuteInMilli = 60 * 1000;

  Array.from(timeHelpers).forEach(timeHelper => {
    const timeFormat = timeHelper.getAttribute("data-time-format");
    const value = timeHelper.getAttribute("datetime");
    const datetime = Date.parse(value);

    if (timeFormat === "calendar") {
      timeHelper.textContent = moment(datetime).calendar(null, {
        lastDay: "[Yesterday]",
        sameDay: "[Today]",
        nextDay: "[Tomorrow]",
        lastWeek: "[on] dddd",
        nextWeek: "dddd",
        sameElse: "L",
      });
    } else if (timeFormat === "relative") {
      const offset = moment(datetime).diff(moment());

      if (offset > 0 && offset < oneMinuteInMilli) {
        timeHelper.textContent = moment().fromNow();
      } else {
        timeHelper.textContent = moment(datetime).fromNow();
      }
    } else {
      timeHelper.textContent = moment(datetime).format("MMM DD, YYYY");
    }
  });
};

const monitorTimeTags = mutations => {
  mutations.forEach(mutation => {
    if (mutation.type === "childList") {
      mutation.addedNodes.forEach(addedNode => {
        if (addedNode.querySelector && addedNode.querySelector("time")) {
          updateTimeStamps();
        }
      });
    }
  });
};

document.addEventListener("turbolinks:load", () => {
  updateTimeStamps();
  setInterval(updateTimeStamps, 30 * 1000);

  setTimeout(() => {
    new MutationObserver(monitorTimeTags).observe(document.querySelector("body"), {
      childList: true,
      subtree: true,
    });
  }, 0);
});
