import _ from "lodash";
import BarsChartControllerBase from "./bars_chart_base_controller";
import {
  accumulateValuesFn,
  joinNounsWithOr,
  payersNoun,
  toOneDecimal,
  uniqPayersFromCustomFields,
} from "../../utilities/charts/chart_utils";
import showPayersModal from "../../utilities/charts/payers_modal";

export default class extends BarsChartControllerBase {
  titleText() {
    return `${this.drug} ${this.params.custom_field}`;
  }

  descriptionText(yes, all) {
    const nouns =
      this.coverageMetric === "lives"
        ? "million lives"
        : payersNoun(this.payerTypesShown).toLowerCase();

    const preposition = this.params.custom_preposition || `with ${this.numeratorText}`;

    return `There are ${toOneDecimal(yes)} ${nouns}
            ${preposition} ${this.params.custom_field}
            out of the ${toOneDecimal(all)} ${nouns}`;
  }

  currentAndPriorDataForTarget(target) {
    const { payerType } = target.dataset;
    const customFieldValues = this.params.data[this.drug][payerType];
    const priorCustomFieldValues = this.params.previous_data?.[this.drug]?.[payerType];

    const accumulate = x =>
      accumulateValuesFn(payerType, this.coverageMetric)(uniqPayersFromCustomFields(x));

    return {
      current: {
        yes: accumulate(_.pick(customFieldValues, ...this.params.numerator_values)),
        all: accumulate(customFieldValues),
      },
      prior: {
        yes: accumulate(_.pick(priorCustomFieldValues, ...this.params.numerator_values)),
        all: accumulate(priorCustomFieldValues),
      },
    };
  }

  onBarClick(event) {
    let payerType = $(event.target).data("payer-type");

    if (payerType === "medicare_advantage_payers") {
      payerType = "medicare_advantage";
    }

    const nouns = payersNoun(payerType);
    const title = `${nouns} with ${this.numeratorText} for ${this.drug} ${this.params.custom_field}`;

    const payerIds = {};
    const extraParams = {};

    const payerIdsFor = dataset =>
      _.flatten(
        _.values(_.pick(dataset?.[this.drug]?.[payerType] || {}, ...this.params.numerator_values)),
      ).map(pl => pl.payer);

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(this.params.data);
      const priorPayers = payerIdsFor(this.params.previous_data);

      payerIds[payerType] = _.union(currentPayers, priorPayers);

      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = {
        [payerType]: changedPayers.map(payer => ({ payer, drug: this.drug })),
      };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
    } else {
      payerIds[payerType] = payerIdsFor(this.params.data);
    }

    showPayersModal(this.drug, title, payerIds, extraParams);
  }

  onBarRemainderClick(event) {
    let payerType = $(event.target).data("payer-type");

    if (payerType === "medicare_advantage_payers") {
      payerType = "medicare_advantage";
    }

    const title = `${payersNoun(payerType)} without ${this.numeratorText} for ${this.drug}`;

    const payerIds = {};
    const extraParams = {};

    const payerIdsFor = dataset =>
      _.flatten(
        _.values(_.omit(dataset?.[this.drug]?.[payerType] || {}, ...this.params.numerator_values)),
      ).map(pl => pl.payer);

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(this.params.data);
      const priorPayers = payerIdsFor(this.params.previous_data);

      payerIds[payerType] = _.union(currentPayers, priorPayers);
      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = {
        [payerType]: changedPayers.map(payer => ({ payer, drug: this.drug })),
      };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changeDisplayMode = "custom_fields";
    } else {
      payerIds[payerType] = payerIdsFor(this.params.data);
    }

    showPayersModal(this.drug, title, payerIds, extraParams);
  }

  get numeratorText() {
    return joinNounsWithOr(this.params.numerator_values);
  }
}
