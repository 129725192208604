import { Controller } from "stimulus";

import moment from "moment";

import Pikaday from "pikaday";
import "pikaday/css/pikaday.css";

window.moment = moment;

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const inputElement = this.hasInputTarget ? this.inputTarget : this.element;

    this.picker = new Pikaday({
      field: inputElement,
      format: "MM/DD/YYYY",
      toString(date) {
        return moment(date).format("MM/DD/YYYY");
      },
      parse(dateString) {
        const supportedFormats = ["MM-DD-YYYY", "MM/DD/YYYY"];

        return moment(dateString, supportedFormats).toDate();
      },
      onSelect: () => {
        this.element.dispatchEvent(new CustomEvent("select", { bubbles: true }));
      },
    });
  }

  clear() {
    this.picker.clear();
  }
}
