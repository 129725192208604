import Chart from "chart.js/auto";
import _ from "lodash";
import {
  accumulateValuesFn,
  coverageMetricNoun,
  onHoverPointer,
  payersNoun,
  toOneDecimal,
  uniqPayersFromCustomFields,
} from "../../utilities/charts/chart_utils";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import colors from "../../utilities/charts/colors";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  togglePayerTypeInternal() {
    this.recalculateChartData();
  }

  toggleCoverageMetricInternal() {
    this.recalculateChartData();
  }

  toggleDrugInternal() {
    this.chart.data.labels = this.allValues;
    this.recalculateChartData();
  }

  updateShownPayersInternal() {
    this.recalculateChartData();
  }

  recalculateChartData() {
    this.chart.data.datasets[0].label = this.percentOfPayersText();
    this.chart.data.datasets[0].data = this.formatData();
    this.chart.data.datasets[0].backgroundColor =
      this.coverageMetric === "payers" ? colors.magenta : colors.teal;
  }

  renderChart() {
    return new Chart(this.appendCanvas(), {
      type: "bar",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: !this.inStaticMode,
        scales: {
          x: {
            grid: { display: false },
            ticks: {
              display: !this.inStaticMode,
            },
          },
          y: {
            grid: { display: true },
            suggestedMin: 0,
            suggestedMax: 1,
            ticks: {
              display: !this.inStaticMode,
              font: { size: 11 },
              callback: fraction => `${toOneDecimal(fraction * 100)}%`,
            },
          },
        },
        plugins: {
          title: {
            display: true,
            text: this.params.custom_field,
            color: "black",
            font: { size: 13 },
          },
          tooltip: {
            mode: "index",
            intersect: false,
            titleAlign: "center",
            callbacks: {
              label: item => {
                const nouns = item.datasetIndex === 0 ? payersNoun(this.payerTypesShown) : "Lives";

                return `${nouns}: ${toOneDecimal(item.parsed.y * 100)}%`;
              },
            },
          },
          legend: {
            display: !this.inStaticMode,
            position: "bottom",
          },
        },
        onClick: event => this.showModal(event),
        onHover: onHoverPointer,
      },
      data: {
        labels: this.allValues,
        datasets: [
          {
            label: this.percentOfPayersText(),
            backgroundColor: this.coverageMetric === "payers" ? colors.magenta : colors.teal,
            data: this.formatData(),
          },
        ],
      },
    });
  }

  formatData() {
    return this.allValues.map(customFieldValue => {
      let all = 0;

      let these = 0;

      const forShownPayerTypes = _.pick(this.params.data[this.drug], this.payerTypesShown);

      _.forIn(forShownPayerTypes, (byFieldValue, payerType) => {
        const accumulate = x => accumulateValuesFn(payerType, this.coverageMetric)(x || []);

        all += accumulate(uniqPayersFromCustomFields(byFieldValue));
        these += accumulate(byFieldValue[customFieldValue]);
      });

      return these / all;
    });
  }

  showModal(event) {
    const clickLocation = parseClick(this.chart, event);

    if (!clickLocation) return;

    const customFieldValue = this.params.all_values[this.drug][clickLocation.index];

    const payerTypes = this.payerTypesShown.map(p =>
      p === "medicare_advantage_payers" ? "medicare_advantage" : p,
    );

    const payers = _.mapValues(
      _.pick(this.params.data[this.drug], payerTypes),
      data => data[customFieldValue] || [],
    );

    const nouns = payersNoun(payerTypes);
    const title = `${nouns} with ${customFieldValue} for ${this.params.custom_field}`;

    showPayersModal(this.drug, title, payers);
  }

  percentOfPayersText() {
    return `% of ${coverageMetricNoun(this.coverageMetric, this.payerTypesShown)}`;
  }

  get allValues() {
    return this.params.all_values[this.drug];
  }
}
