import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["openButton", "closeButton", "menu"];

  open() {
    this.closeButtonTarget.classList.remove("hidden");
    this.openButtonTarget.classList.add("hidden");
    this.menuTarget.classList.remove("hidden");
  }

  close() {
    this.openButtonTarget.classList.remove("hidden");
    this.closeButtonTarget.classList.add("hidden");
    this.menuTarget.classList.add("hidden");
  }
}
