import { Controller } from "stimulus";

function gtag() {
  window.dataLayer.push(arguments); // eslint-disable-line
}

export default class extends Controller {
  connect() {
    window.dataLayer = window.dataLayer || [];

    gtag("js", new Date());

    gtag("config", this.data.get("key"), {
      user_id: this.data.get("user"),
      dimension1: this.data.get("user"),
      dimension3: this.data.get("portal"),
      dimension4: this.data.get("portalType"),
      dimension5: this.data.get("client"),
      custom_map: {
        dimension1: "ga_user_id",
        dimension3: "portal",
        dimension4: "portalType",
        dimension5: "client",
      },
    });
  }

  trackDownload({ currentTarget }) {
    gtag("event", "download", {
      event_category: "portal downloads",
      event_label: currentTarget.dataset.analyticsDownloadType,
      portal: this.data.get("portal"),
      client: this.data.get("client"),
      portal_file: currentTarget.dataset.analyticsFileName,
    });
  }

  trackModal({ currentTarget }) {
    gtag("event", "modal", {
      event_category: "modal",
      client: this.data.get("client"),
      event_label: $(currentTarget).find("[name='group_title']").val(),
    });
  }

  trackFilter({ currentTarget }) {
    gtag("event", "filter", {
      event_category: "filter",
      client: this.data.get("client"),
      event_label: currentTarget.value + (currentTarget.checked ? "-enabled" : "-disabled"),
    });
  }
}
