import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["sourceCheckbox", "dependantLabel"];

  connect() {
    this.update();
  }

  update() {
    this.dependantLabelTargets.forEach(label => {
      if (this.dependantIsActive(label)) {
        label.classList.remove("hidden");
      } else {
        label.classList.add("hidden");
        this.setCheckboxState(label, false);
      }
    });
  }

  checkAllActiveDependants() {
    this.dependantLabelTargets
      .filter(t => this.dependantIsActive(t))
      .forEach(label => this.setCheckboxState(label, true));
  }

  dependantIsActive(dependantLabel) {
    const theseValues = dependantLabel.dataset.connectedMultiselectShowForSourceValue.split(",");

    return _.intersection(this.checkedSourceValues, theseValues).length > 0;
  }

  setCheckboxState(label, checked) {
    const checkbox = $(label).find("input[type=checkbox]")[0];

    checkbox.checked = checked;
    checkbox.dispatchEvent(new Event("input"));
  }

  get checkedSourceValues() {
    return this.sourceCheckboxTargets.filter(t => t.checked).map(t => t.value);
  }
}
