import _ from "lodash";
import OnePayerTypeManyDrugsChartControllerBase from "./one_payer_type_many_drugs_chart_controller_base";
import ChartControllerWrapper from "./payers_v_lives_chart_controller_base";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";
import SymbolaArrows from "../../utilities/charts/symbola_arrows";
import {
  accumulateValuesFn,
  payersNoun,
  percent,
  withinOneDecimalP,
} from "../../utilities/charts/chart_utils";

export default class extends OnePayerTypeManyDrugsChartControllerBase {
  connect() {
    this.innerClass = new ChartControllerWrapper(this);
    super.connect();

    if (this.showChangesFromPrevious) {
      SymbolaArrows.onload(() => this.chart.update());
    }
  }

  togglePayerTypeInternal() {
    this.chart.options.plugins.title.text = this.titleText;

    this.innerClass.updateChartData();
  }

  toggleDrugsInternal() {
    this.innerClass.updateChartData();
  }

  updateShownPayersInternal() {
    this.innerClass.updateChartData();
  }

  formatData(coverageMetric) {
    return this.drugs.map(drug => {
      const currentData = this.params.data[this.payerType][drug][1];
      const currentCounts = currentData.map(accumulateValuesFn(this.payerType, coverageMetric));
      const currentPercent = percent(currentCounts[0], _.sum(currentCounts));

      return { x: currentPercent, y: drug };
    });
  }

  deltaFromChartContext(context) {
    const drug = this.drugs[context.dataIndex];
    const coverageMetric = context.dataset.stack;

    const [priorData, currentData] = this.params.data[this.payerType][drug];

    const priorCounts = priorData.map(accumulateValuesFn(this.payerType, coverageMetric));
    const currentCounts = currentData.map(accumulateValuesFn(this.payerType, coverageMetric));

    const priorPercent = percent(priorCounts[0], _.sum(priorCounts));
    const currentPercent = percent(currentCounts[0], _.sum(currentCounts));

    if (this.showChangesFromPrevious && !withinOneDecimalP(priorPercent, currentPercent)) {
      return currentPercent - priorPercent;
    } else {
      return 0;
    }
  }

  showModal(event, alreadyParsed) {
    const clickLocation = alreadyParsed ? event : parseClick(this.chart, event);

    if (!clickLocation) return;

    const payerType =
      this.payerType === "medicare_advantage_payers" ? "medicare_advantage" : this.payerType;

    const drug = this.drugs[clickLocation.index];

    const payerIds = {};
    const extraParams = {};

    const payerIdsFor = datasetIndex =>
      this.params.data[payerType][drug][datasetIndex][0].map(d => d.payer);

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(1);
      const priorPayers = payerIdsFor(0);

      payerIds[payerType] = _.union(currentPayers, priorPayers);
      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = { [payerType]: changedPayers.map(payer => ({ payer, drug })) };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changeDisplayMode = "access_status";
    } else {
      payerIds[payerType] = payerIdsFor(1);
    }

    showPayersModal(drug, "confirmed", payerIds, extraParams);
  }

  renderChart() {
    return this.innerClass.renderChart();
  }

  get titleText() {
    return `Uptake ${payersNoun(this.payerType)} v. Lives - All Products`;
  }
}
