import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["initialAuth", "initialOtherText", "reAuth", "reauthOtherText"];

  connect() {
    const initialAuthSelectedValue =
      this.initialAuthTarget.options[this.initialAuthTarget.selectedIndex].text;
    const reauthSelectedValue = this.reAuthTarget.options[this.reAuthTarget.selectedIndex].text;

    if (initialAuthSelectedValue === "Other") {
      this.initialOtherTextTarget.classList.remove("hidden");
    }
    if (reauthSelectedValue === "Other") {
      this.reauthOtherTextTarget.classList.remove("hidden");
    }
  }

  InitialAuthLengthSelected() {
    const initialAuthSelectedValue =
      this.initialAuthTarget.options[this.initialAuthTarget.selectedIndex].text;

    if (initialAuthSelectedValue === "Other") {
      this.initialOtherTextTarget.classList.remove("hidden");
    } else {
      this.initialOtherTextTarget.classList.add("hidden");
    }
  }

  ReauthLengthSelected() {
    const reauthSelectedValue = this.reAuthTarget.options[this.reAuthTarget.selectedIndex].text;

    if (reauthSelectedValue === "Other") {
      this.reauthOtherTextTarget.classList.remove("hidden");
    } else {
      this.reauthOtherTextTarget.classList.add("hidden");
    }
  }
}
