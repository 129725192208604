import Chart from "chart.js/auto";
import _ from "lodash";
import moment from "moment";
import OnePayerTypeManyDrugsChartControllerBase from "./one_payer_type_many_drugs_chart_controller_base";
import colors, { variantLightBlue } from "../../utilities/charts/colors";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";
import {
  accumulateValuesFn,
  coverageMetricNoun,
  omitMissingOverTimeItems,
  maxOverTimeX,
  payersNoun,
  percent,
  relativeDateOverTimeCallbacks,
} from "../../utilities/charts/chart_utils";

export default class extends OnePayerTypeManyDrugsChartControllerBase {
  togglePayerTypeInternal() {
    this.updateChartData();
  }

  toggleCoverageMetricInternal() {
    this.updateChartData();
  }

  toggleDrugsInternal() {
    this.chart.data.datasets.forEach(dataset => {
      dataset.hidden = !this.drugs.includes(dataset.drug);
    });
  }

  updateShownPayersInternal() {
    this.updateChartData();
  }

  updateChartData() {
    this.chart.options.plugins.title.text = this.formatTitle();

    this.forEachDrug((data, drug) => {
      this.chart.data.datasets.find(set => set.drug === drug).data = this.formatData(data);
    });
  }

  renderChart() {
    const formattingCallbacks = relativeDateOverTimeCallbacks(this);

    return new Chart(this.appendCanvas(), {
      type: "scatter",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: !this.inStaticMode,
        showLine: true,
        interaction: {
          intersect: false,
          mode: "x",
        },
        scales: {
          y: {
            title: {
              display: !this.inStaticMode,
              text: this.formatYAxisTitle(),
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            suggestedMin: 0,
            suggestedMax: 100,
            ticks: {
              display: !this.inStaticMode,
              callback: value => `${value}%`,
            },
          },
          x: {
            title: {
              display: !this.inStaticMode,
              text: "Months Post Launch",
              padding: {
                top: 4,
                bottom: 8,
              },
            },
            bounds: "data",
            ticks: {
              display: !this.inStaticMode,
              callback: formattingCallbacks.ticks,
              maxTicksLimit: maxOverTimeX(this),
            },
          },
        },
        plugins: {
          legend: {
            display: !this.inStaticMode,
            position: "bottom",
            labels: { usePointStyle: true },
            onClick: (_event, legendItem) => this.toggleDrugFromChart(legendItem),
          },
          title: {
            display: true,
            text: this.formatTitle(),
            color: "black",
            font: { size: 13 },
          },
          tooltip: {
            usePointStyle: true,
            titleAlign: "center",
            filter: formattingCallbacks.tooltip.filter,
            callbacks: formattingCallbacks.tooltip,
          },
        },
        onClick: event => this.triggerModal(event),
      },
      data: {
        labels: this.drugs,
        datasets: this.forEachDrug((data, drug, i) => ({
          drug,
          label: drug,
          data: this.formatData(data),
          ...this.dataStyle[i],
          pointRadius: 5,
          pointBorderColor: "black",
        })),
      },
    });
  }

  formatData(dataForPayerType) {
    const accumulate = accumulateValuesFn(this.payerType, this.coverageMetric);
    const presentData = omitMissingOverTimeItems(dataForPayerType);
    const minDateInSet = moment.min(presentData.map(d => moment(d.date)));

    const data = presentData.map(datum => {
      const x = moment(datum.date).diff(minDateInSet, "days");
      const y = percent(accumulate(datum.confirmed), accumulate(datum.all));

      return { x, y, date: datum.date };
    });

    return _.sortBy(data, point => point.x);
  }

  forEachDrug(callback) {
    return _.toPairs(this.params.data).map(([drug, data], index) =>
      callback(data[this.payerType], drug, index),
    );
  }

  formatTitle() {
    const adjective = window.PayerTypeLabels[this.payerType];
    const nouns = this.coverageMetricText;

    return `Comparison of Confirmed Access By % of ${adjective} ${nouns}`;
  }

  formatYAxisTitle() {
    return `% of ${this.coverageMetricText} with Confirmed Access`;
  }

  triggerModal(event) {
    const clickLocation = parseClick(this.chart, event);

    if (!clickLocation) return;

    const dataset = this.chart.data.datasets[clickLocation.datasetIndex];

    const drug = dataset.drug;
    const payers = this.params.data[drug][this.payerType][clickLocation.index].confirmed;

    const title = `${payersNoun(this.payerType)} with Confirmed Access for ${drug}`;

    const maxIndex = dataset.data.length - 1;

    if (clickLocation.index === maxIndex) {
      showPayersModal(drug, title, { [this.payerType]: payers });
    } else {
      const timestamp = dataset.data[clickLocation.index].date;

      showPayersModal(drug, title, { [this.payerType]: payers }, { timestamp });
    }
  }

  get coverageMetricText() {
    return coverageMetricNoun(this.coverageMetric, this.payerType);
  }

  get dataStyle() {
    return [
      { backgroundColor: colors.teal, borderColor: colors.teal, pointStyle: "rect" },
      { backgroundColor: colors.magenta, borderColor: colors.magenta, pointStyle: "rectRot" },
      { backgroundColor: colors.orange, borderColor: colors.orange, pointStyle: "triangle" },
      { ...variantLightBlue, pointStyle: "circle" },
      { backgroundColor: colors.mauve, borderColor: colors.mauve, pointStyle: "rectRounded" },
    ];
  }
}
