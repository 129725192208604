import Chart from "chart.js/auto";
import _ from "lodash";
import {
  absoluteDateOverTimeCallbacks,
  accumulateValuesFn,
  coverageMetricNoun,
  maxOverTimeX,
  omitMissingOverTimeItems,
  overTimeX,
  payersNoun,
  percent,
} from "../../utilities/charts/chart_utils";
import colors, { variantLightBlue } from "../../utilities/charts/colors";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  togglePayerTypeInternal() {
    this.chart.options.plugins.title.text = this.formatTitle();

    this.forEachDataset((dataset, type) => {
      dataset.hidden = !this.payerTypesShown.includes(type);
    });
  }

  toggleDrugInternal() {
    this.updateChartData();
  }

  toggleCoverageMetricInternal() {
    this.updateChartData();
  }

  updateShownPayersInternal() {
    this.updateChartData();
  }

  updateChartData() {
    this.chart.options.plugins.title.text = this.formatTitle();

    this.forEachDataset((dataset, type) => {
      dataset.data = this.formatData(type);
    });
  }

  renderChart() {
    const formattingCallbacks = absoluteDateOverTimeCallbacks(this);

    return new Chart(this.appendCanvas(), {
      type: "scatter",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: !this.inStaticMode,
        showLine: true,
        interaction: {
          intersect: false,
          mode: "x",
        },
        scales: {
          y: {
            grid: {
              display: false,
              drawBorder: false,
            },
            suggestedMin: 0,
            suggestedMax: 100,
            ticks: {
              display: !this.inStaticMode,
              callback: value => `${value}%`,
            },
          },
          x: {
            bounds: "data",
            ticks: {
              display: !this.inStaticMode,
              callback: formattingCallbacks.ticks,
              maxTicksLimit: maxOverTimeX(this),
            },
          },
        },
        plugins: {
          legend: {
            display: !this.inStaticMode,
            position: "bottom",
            labels: { usePointStyle: true },
            onClick: (_event, legendItem) => this.togglePayerTypeFromChart(legendItem),
          },
          title: {
            display: true,
            text: this.formatTitle(),
            color: "black",
            font: { size: 13 },
          },
          tooltip: {
            usePointStyle: true,
            titleAlign: "center",
            filter: formattingCallbacks.tooltip.filter,
            callbacks: formattingCallbacks.tooltip,
          },
        },
        onClick: event => this.triggerModal(event),
      },
      data: {
        datasets: _.without(
          [
            this.payerTypesIncluded.includes("commercial") && {
              payerType: "commercial",
              label: window.PayerTypeLabels.commercial,
              data: this.formatData("commercial"),
              backgroundColor: colors.teal,
              borderColor: colors.teal,
              pointStyle: "rect",
              pointRadius: 5,
              pointBorderColor: "black",
              hidden: !this.payerTypesShown.includes("commercial"),
            },
            this.payerTypesIncluded.includes("medicare") && {
              payerType: "medicare",
              label: window.PayerTypeLabels.medicare,
              data: this.formatData("medicare"),
              backgroundColor: colors.magenta,
              borderColor: colors.magenta,
              pointStyle: "rectRot",
              pointRadius: 5,
              pointBorderColor: "black",
              hidden: !this.payerTypesShown.includes("medicare"),
            },
            this.payerTypesIncluded.includes("medicaid") && {
              payerType: "medicaid",
              label: window.PayerTypeLabels.medicaid,
              data: this.formatData("medicaid"),
              backgroundColor: colors.orange,
              borderColor: colors.orange,
              pointStyle: "triangle",
              pointRadius: 5,
              pointBorderColor: "black",
              hidden: !this.payerTypesShown.includes("medicaid"),
            },
            this.payerTypesIncluded.includes("medicare_advantage") && {
              payerType: "medicare_advantage",
              label: window.PayerTypeLabels.medicare_advantage,
              data: this.formatData("medicare_advantage"),
              ...variantLightBlue,
              pointStyle: "circle",
              pointRadius: 5,
              pointBorderColor: "black",
              hidden: !this.payerTypesShown.includes("medicare_advantage"),
            },
            this.payerTypesIncluded.includes("medicare_advantage_payers") && {
              payerType: "medicare_advantage_payers",
              label: window.PayerTypeLabels.medicare_advantage_payers,
              data: this.formatData("medicare_advantage_payers"),
              ...variantLightBlue,
              pointStyle: "circle",
              pointRadius: 5,
              pointBorderColor: "black",
              hidden: !this.payerTypesShown.includes("medicare_advantage_payers"),
            },
            this.payerTypesIncluded.includes("managed_medicaid") && {
              payerType: "managed_medicaid",
              label: window.PayerTypeLabels.managed_medicaid,
              data: this.formatData("managed_medicaid"),
              backgroundColor: colors.mauve,
              borderColor: colors.mauve,
              pointStyle: "rectRounded",
              pointRadius: 5,
              pointBorderColor: "black",
              hidden: !this.payerTypesShown.includes("managed_medicaid"),
            },
            this.payerTypesIncluded.includes("benefit_management") && {
              payerType: "benefit_management",
              label: window.PayerTypeLabels.benefit_management,
              data: this.formatData("benefit_management"),
              backgroundColor: colors.blue_gray,
              borderColor: colors.blue_gray,
              pointStyle: "rect",
              pointRadius: 5,
              pointBorderColor: "black",
              hidden: !this.payerTypesShown.includes("benefit_management"),
            },
          ],
          false,
        ),
      },
    });
  }

  formatData(payerType) {
    if (_.isEmpty(this.params.data[this.drug]?.[payerType])) {
      return [];
    }

    const accumulate = accumulateValuesFn(payerType, this.coverageMetric);

    const data = omitMissingOverTimeItems(this.params.data[this.drug][payerType]).map(datum => {
      const x = overTimeX(this, datum.date);
      const y = percent(accumulate(datum.confirmed), accumulate(datum.all));

      return { x, y, date: datum.date };
    });

    return _.sortBy(data, point => point.x);
  }

  forEachDataset(callback) {
    this.payerTypesIncluded.forEach(type => {
      const dataset = this.chart.data.datasets.find(set => set.payerType === type);

      callback(dataset, type);
    });
  }

  formatTitle() {
    const nouns = coverageMetricNoun(this.coverageMetric, this.payerTypesShown);

    return `% of ${nouns} with Confirmed Access Over Time for ${this.drug}`;
  }

  triggerModal(event) {
    const clickLocation = parseClick(this.chart, event);

    if (!clickLocation) return;

    const dataset = this.chart.data.datasets[clickLocation.datasetIndex];

    const payerType =
      dataset.payerType === "medicare_advantage_payers" ? "medicare_advantage" : dataset.payerType;
    const payers = this.params.data[this.drug][payerType][clickLocation.index].confirmed;

    const title = `${payersNoun(payerType)} with Confirmed Access for ${this.drug}`;

    const maxIndex = dataset.data.length - 1;

    if (clickLocation.index === maxIndex) {
      showPayersModal(this.drug, title, { [payerType]: payers });
    } else {
      const timestamp = dataset.data[clickLocation.index].date;

      showPayersModal(this.drug, title, { [payerType]: payers }, { timestamp });
    }
  }
}
