import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import _ from "lodash";
import moment from "moment";
import OnePayerTypeManyDrugsChartControllerBase from "./one_payer_type_many_drugs_chart_controller_base";
import colors, { variantLightBlue } from "../../utilities/charts/colors";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";
import SymbolaArrows from "../../utilities/charts/symbola_arrows";
import {
  formatPayersLivesTitle,
  accumulateValuesFn,
  onHoverPointer,
  orderedAccessStatusNames,
  payersNoun,
  toOneDecimal,
  withinOneDecimalP,
} from "../../utilities/charts/chart_utils";

export default class extends OnePayerTypeManyDrugsChartControllerBase {
  connect() {
    super.connect();

    if (this.showChangesFromPrevious) {
      SymbolaArrows.onload(() => this.chart.update());
    }
  }

  toggleDrugsInternal() {
    this.chart.data.datasets.forEach(dataset => {
      dataset.hidden = !this.drugs.includes(dataset.drug);
    });
  }

  togglePayerTypeInternal() {
    this.chart.options.plugins.subtitle.text = this.subtitle();
    this.updateChartData();
  }

  toggleCoverageMetricInternal() {
    this.chart.options.plugins.subtitle.text = this.subtitle();
    this.updateChartData();
  }

  updateShownPayersInternal() {
    this.updateChartData();
  }

  updateChartData() {
    this.forEachDrug((data, drug) => {
      this.chart.data.datasets.find(set => set.drug === drug).data = data;
    });
  }

  renderChart() {
    return new Chart(this.appendCanvas(), {
      type: "bar",
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: !this.inStaticMode,
        scales: {
          x: {
            grid: { display: false },
            ticks: {
              display: !this.inStaticMode,
              font: { size: 11 },
            },
          },
          y: {
            display: false,
          },
        },
        plugins: {
          legend: {
            display: !this.inStaticMode,
            position: "bottom",
            onClick: (_event, legendItem) => this.toggleDrugFromChart(legendItem),
            labels: {
              boxWidth: 12,
            },
          },
          title: {
            display: true,
            text: "Access Status Report",
            color: "black",
            font: { size: 13 },
          },
          subtitle: {
            display: !this.inStaticMode,
            text: this.subtitle(),
            color: "black",
            font: { size: 13 },
            padding: { bottom: 10 },
          },
          tooltip: {
            callbacks: {
              label: context => this.tooltipLabelText(context),
            },
          },
          datalabels: {
            display: !this.inStaticMode,
            color: c => this.colorForDelta(this.deltaFromChartContext(c)),
            font: c => (this.deltaFromChartContext(c) !== 0 ? { weight: "bold" } : {}),
            anchor: "end",
            align: "end",
            textAlign: "center",
            padding: c => (this.deltaFromChartContext(c) > 0 ? 0 : 4),
            formatter: (v, c) => this.dataLabelWithDelta(v, c),
            listeners: {
              click: event => this.triggerModalFromDatalabel(event),
            },
          },
        },
        onClick: event => this.triggerModalFromChart(event),
        onHover: onHoverPointer,
      },
      data: {
        labels: [
          ["Confirmed", "Access"],
          "Case-by-Case",
          "Not Covered",
          ["Access", "Undetermined"],
        ],
        datasets: this.forEachDrug((data, drug, i) => ({
          drug,
          label: drug,
          data,
          borderWidth: 1,
          barPercentage: 1,
          ...this.dataColors[i % 4],
        })),
      },
    });
  }

  deltaFromChartContext(context) {
    const drug = context.dataset.drug;
    const format = accumulateValuesFn(this.payerType, this.coverageMetric);

    const priorValue = format(this.params.data[this.payerType][drug][0][context.dataIndex]);
    const currentValue = format(this.params.data[this.payerType][drug][1][context.dataIndex]);

    if (!this.showChangesFromPrevious || withinOneDecimalP(priorValue, currentValue)) {
      return 0;
    } else {
      return currentValue - priorValue;
    }
  }

  dataLabelWithDelta(value, context) {
    const delta = this.deltaFromChartContext(context);

    if (delta === 0) {
      return value;
    } else if (delta > 0) {
      return [value, SymbolaArrows.up];
    } else {
      return [SymbolaArrows.down, value];
    }
  }

  colorForDelta(delta) {
    if (delta === 0) {
      return "black";
    } else if (delta > 0) {
      return "#6b7e38"; // text-green-500
    } else {
      return "#e02424"; // text-red
    }
  }

  forEachDrug(callback) {
    const format = x => toOneDecimal(accumulateValuesFn(this.payerType, this.coverageMetric)(x));

    return _.toPairs(this.params.data[this.payerType]).map(([drug, [, data]], index) =>
      callback(data.map(format), drug, index),
    );
  }

  subtitle() {
    const date = moment(this.params.current_date).format("MMMM YYYY");

    return `${formatPayersLivesTitle(this.coverageMetric, this.payerType)} (${date})`;
  }

  tooltipLabelText(context) {
    const standardLine = `${context.dataset.label}: ${context.parsed.y}`;
    const delta = this.deltaFromChartContext(context);

    if (delta === 0) {
      return standardLine;
    } else {
      const deltaWord = delta > 0 ? "Increased" : "Decreased";

      return [standardLine, `${deltaWord}: ${toOneDecimal(Math.abs(delta))}`];
    }
  }

  triggerModalFromChart(event) {
    const clickLocation = parseClick(this.chart, event);

    if (!clickLocation) return;

    this.triggerModalInternal(clickLocation.datasetIndex, clickLocation.index);
  }

  triggerModalFromDatalabel(e) {
    this.triggerModalInternal(e.datasetIndex, e.dataIndex);
  }

  triggerModalInternal(drugIndex, accessStatusIndex) {
    const payerType =
      this.payerType === "medicare_advantage_payers" ? "medicare_advantage" : this.payerType;
    const drug = this.drugs[drugIndex];
    const accessStatus = orderedAccessStatusNames[accessStatusIndex];

    const payerIdsFor = datasetIndex =>
      this.params.data[payerType][drug][datasetIndex][accessStatusIndex].map(d => d.payer);

    const title = `${payersNoun(payerType)} with ${accessStatus} for ${drug}`;

    const payerIds = {};
    const extraParams = { accessStatus };

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(1);
      const priorPayers = payerIdsFor(0);

      payerIds[payerType] = _.union(currentPayers, priorPayers);
      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = { [payerType]: changedPayers.map(payer => ({ payer, drug })) };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changeDisplayMode = "access_status";
    } else {
      payerIds[payerType] = payerIdsFor(1);
    }

    showPayersModal(drug, title, payerIds, extraParams);
  }

  get dataColors() {
    return [
      { backgroundColor: colors.teal, borderColor: "#358F9F" },
      { backgroundColor: colors.orange, borderColor: "#A5502B" },
      { backgroundColor: colors.magenta, borderColor: "#77446F" },
      variantLightBlue,
    ];
  }
}
