import { Controller } from "stimulus";
import _ from "lodash";
import { createPopper } from "@popperjs/core";

const rowContentsTemplate = iconPickerState => `
  <div title="Remove" data-action="click->custom-field-values-with-abbreviations#deleteRow"       data-flow="remove" class="roundedl-l px-3 pb-1 cursor-default flex items-center hover:bg-gray-100">x</div>
  <input type="text" style="flex:3" data-action="custom-field-values-with-abbreviations#onChange" data-flow="long_name"  name="custom_field[field_options][]" placeholder="Enter Dropdown Value" class="text-sm no-border-at-rest px-4">
  <input type="text" style="flex:1" data-action="custom-field-values-with-abbreviations#onChange" data-flow="short_name" name="custom_field[field_option_short_names][]"   placeholder="Optional short name"  class="text-sm no-border-at-rest px-4 text-gray-400">
  <input type="hidden"                                                                            data-flow="icon_value" name="custom_field[icon_set][]">
  <div data-action="click->custom-field-values-with-abbreviations#showIconPicker"                 data-flow="icon_image" data-hide-many-target="hideable" data-hide-many-token="dropdown" class="w-16 flex items-center justify-center cursor-pointer border-b border-black no-border-at-rest text-sm text-gray-300 ${iconPickerState}">No Icon</div>
`;

export default class extends Controller {
  static targets = ["iconPicker"];

  static values = { iconSvgMap: Object };

  addRow() {
    const isDropdown = $("[data-hide-many-target=select]").val() === "dropdown";
    const newRow = document.createElement("div");

    newRow.classList = "flex items-stretch";
    $(newRow).attr("data-flow", "field_option_row");
    $(newRow).attr("data-uuid", crypto.randomUUID());
    newRow.innerHTML = rowContentsTemplate(isDropdown ? "" : "hidden");

    $(this.element).append(newRow);
  }

  deleteRow(event) {
    $(event.target).parent().remove();

    this.onChange();
  }

  onChange() {
    const values = _.map(this.fieldOptionRowTargets, target => {
      const uuid = $(target).data("uuid");

      const longName = $(target).find("[data-flow=long_name]").val();
      const shortName = $(target).find("[data-flow=short_name]").val();

      return { uuid, longName, shortName };
    });

    const event = new Event("scoutchange", { bubbles: true });

    event.customFieldValues = values;

    this.element.dispatchEvent(event);
  }

  showIconPicker(event) {
    this.popper?.destroy();
    this.activeIconPickerRow = _.find(this.allFieldOptionRows, row => row.contains(event.target));
    const iconColumn = $(this.activeIconPickerRow).find("[data-flow=icon_image]")[0];

    this.popper = createPopper(iconColumn, this.iconPickerTarget, {
      placement: "left",
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            mainAxis: true,
            altAxis: true,
            padding: 5,
          },
        },
      ],
    });

    this.iconPickerTarget.classList.remove("hidden");
  }

  onOutsideClick(event) {
    const isOutside = this.allFieldOptionRows
      .toArray()
      .concat(this.iconPickerTarget)
      .every(c => !c.contains(event.target));

    if (isOutside) {
      this.hideIconPicker();
    }
  }

  hideIconPicker() {
    delete this.activeIconPickerRow;
    this.popper?.destroy();
    this.iconPickerTarget.classList.add("hidden");
  }

  setIcon(event) {
    const value = $(event.target).closest("[data-value]").data("value");
    const icon = _.isEmpty(value) ? "No Icon" : this.iconSvgMapValue[value];

    $(this.activeIconPickerRow).find("[data-flow=icon_value]").val(value);
    $(this.activeIconPickerRow).find("[data-flow=icon_image]").html(icon);

    this.hideIconPicker();
  }

  get allFieldOptionRows() {
    return $(this.element).find("[data-flow=field_option_row");
  }
}
