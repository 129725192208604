import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showWhenActive", "showWhenInactive", "selectedCount", "submit"];

  static values = { form: String };

  connect() {
    this.$checkboxTargets.on("input", () => this.update());

    this.update();
  }

  start() {
    $(this.showWhenInactiveTargets).addClass("hidden");
    $(this.showWhenActiveTargets).removeClass("hidden");
    this.$inactiveRowTargets.addClass("hidden");
    this.$checkboxColumnTargets.removeClass("hidden");
    this.$checkboxTargets.attr("disabled", false);

    this.update();
  }

  stop() {
    $(this.showWhenInactiveTargets).removeClass("hidden");
    $(this.showWhenActiveTargets).addClass("hidden");
    this.$inactiveRowTargets.removeClass("hidden");
    this.$checkboxColumnTargets.addClass("hidden");
    this.$checkboxTargets.attr("disabled", true);
  }

  update() {
    this.selectedCountTarget.innerText = this.selectedCount;
    $(this.submitTarget).attr("disabled", this.selectedCount === 0);
  }

  get $activeRowTargets() {
    return $(`#${this.formValue} [data-e-alert-builder-table-target='activeRow']`);
  }

  get $inactiveRowTargets() {
    return $(`#${this.formValue} [data-e-alert-builder-table-target='inactiveRow']`);
  }

  get $checkboxColumnTargets() {
    return $(`#${this.formValue} [data-e-alert-builder-table-target='checkboxColumn']`);
  }

  get $checkboxTargets() {
    return $(`#${this.formValue} input[type=checkbox]`);
  }

  get selectedCount() {
    return $(`#${this.formValue} input[type=checkbox]:checked`).length;
  }
}
