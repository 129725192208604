import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["submit"];

  connect() {
    this.disabled = true;
  }

  toggle() {
    this.disabled = !this.disabled;

    if (this.disabled) {
      this.submitTarget.setAttribute("disabled", "disabled");
    } else {
      this.submitTarget.removeAttribute("disabled");
    }
  }
}
