import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["option"];

  connect() {
    this.syncInputs();
  }

  syncInputs() {
    this.optionTargets.forEach(t => {
      const radioButton = t.querySelector("input[type=radio]");
      const select = t.querySelector("select");

      if (radioButton.checked) {
        t.classList.add("highlight-ring");
        select.disabled = false;

        if (select.selectize) {
          select.selectize.enable();
        }
      } else {
        t.classList.remove("highlight-ring");
        select.disabled = true;

        if (select.selectize) {
          select.selectize.disable();
        }
      }
    });
  }
}
