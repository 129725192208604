import { Controller } from "stimulus";

const ANNOTATION_TOOLS = `
  <button type="button"
          class="trix-button trix-button--icon trix-button--icon-highlight"
          data-trix-attribute="highlight"
          title="Highlight"
          tabIndex="-1">
    Highlight
  </button>
  <button type="button"
          class="trix-button trix-button--icon trix-button--icon-strike"
          data-trix-attribute="strike"
          title="Strikethrough"
          tabIndex="-1">
    Strikethrough
  </button>
`;

const RESET_BUTTON = `
  <button type="button"git
          class="trix-button trix-button--icon trix-button--icon-reset"
          data-trix-action="x-reset"
          title="Clear Annotations"
          tabIndex="-1">
    Highlight
  </button>
`;

export default class extends Controller {
  connect() {
    this.element.setAttribute("contentEditable", false);

    this.element.addEventListener("trix-initialize", () => {
      // It is possible for this to happen more than once per page, presumably because Turbolinks.
      // Thus, all mutations to toolbar must be idempotent.
      this.toolbar.classList.add("trix-annotations");

      this.toolbar.querySelector(".trix-button-group--text-tools").innerHTML = ANNOTATION_TOOLS;

      this.toolbar.querySelector(".trix-button-group--block-tools")?.remove();

      const historyTools = this.toolbar.querySelector(".trix-button-group--history-tools");

      if (!historyTools.querySelector(".trix-button--icon-reset")) {
        historyTools.innerHTML += RESET_BUTTON;
      }
    });
  }

  get toolbar() {
    return this.element.previousSibling;
  }
}
