import { Controller } from "stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["popup", "state"];

  stateSelected({ currentTarget }) {
    this.clearSelection();
    currentTarget.classList.add("active");

    $.ajax({
      url: `${this.baseUrl}/payers/${currentTarget.dataset.region}?popup=true`,
      dataType: "html",
      context: this,
      success: data => {
        const popup = document.createRange().createContextualFragment(data);

        this.element.appendChild(popup);
        this.popper = createPopper(currentTarget, this.popupTarget);
      },
    });
  }

  teardown() {
    this.clearSelection();
  }

  clearSelection() {
    if (this.popper) {
      this.popper.destroy();
    }

    this.popupTargets.forEach(t => this.element.removeChild(t));
    this.stateTargets.forEach(t => t.classList.remove("active"));
  }

  onOutsideClick({ target }) {
    if (!this.isInside(target)) {
      this.clearSelection();
    }
  }

  isInside(target) {
    return [...this.stateTargets, ...this.popupTargets].some(c => c.contains(target));
  }

  get baseUrl() {
    return this.data.get("base-url");
  }
}
