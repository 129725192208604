import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["card"];

  static values = { url: String };

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("hidden");
    } else {
      this.controller = new AbortController();
      const { signal } = this.controller;

      fetch(this.urlValue, { signal })
        .then(r => r.text())
        .then(html => {
          const fragment = document.createRange().createContextualFragment(html);

          this.element.appendChild(fragment);
        })
        .catch(() => {});
    }
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("hidden");
    } else if (this.controller) {
      this.controller.abort();
    }
  }

  hideOutside(event) {
    if (!this.element.contains(event.target)) {
      this.hide();
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}
