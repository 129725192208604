const ENOUGH_TIME_TO_RESIZE = 100; // ms

export default class {
  constructor(elementsThatCouldResize) {
    this.observer = new ResizeObserver(this.onResize.bind(this));

    elementsThatCouldResize.forEach(element => this.observer.observe(element));
  }

  onResize() {
    if (this.entryToPreserve) {
      const newPosition = this.entryToPreserve.getBoundingClientRect().top;

      window.scrollBy({ top: newPosition - this.topToPreserve });
    }
  }

  preservePosition(element) {
    clearTimeout(this.timeout);

    this.topToPreserve = element?.getBoundingClientRect().top;
    this.entryToPreserve = element;

    this.timeout = setTimeout(this.clear.bind(this), ENOUGH_TIME_TO_RESIZE);
  }

  clear() {
    delete this.entryToPreserve;
    delete this.topToPreserve;

    clearTimeout(this.timeout);
    this.timeout = null;
  }
}
