import _ from "lodash";
import OnePayerTypeManyDrugsChartControllerBase from "./one_payer_type_many_drugs_chart_controller_base";
import BarsChartGridBase from "./bars_chart_grid_base";
import { formatPayersLivesTitle, accumulateValuesFn } from "../../utilities/charts/chart_utils";
import showPayersModal from "../../utilities/charts/payers_modal";
import shrinkWidgetToFit from "../../utilities/charts/shrink_widget_to_fit";

export default class extends OnePayerTypeManyDrugsChartControllerBase {
  static targets = [
    "title",
    "gridTitle",
    "gridFraction",
    "gridPercent",
    "gridChangeIndicator",
    "payerTypeName",
    "fraction",
    "percent",
  ];

  connect() {
    this.innerClass = new BarsChartGridBase(this);
    super.connect();
  }

  toggleCoverageMetricInternal() {
    this.renderChart();
  }

  togglePayerTypeInternal() {
    this.renderChart();
  }

  toggleDrugsInternal() {
    this.renderChart();
  }

  updateShownPayersInternal() {
    this.renderChart();
  }

  renderChart() {
    this.renderTitle();
    this.innerClass.renderGrid();
    shrinkWidgetToFit(this.element, this.element.children[0]);
  }

  renderTitle() {
    const nouns = formatPayersLivesTitle(this.coverageMetric, this.payerType);
    const payerTypes = window.PayerTypeLabels[this.payerType];

    this.titleTarget.innerText = `${nouns} with Confirmed Access for ${payerTypes}`;
  }

  currentAndPriorDataForTarget(target) {
    const [prior, current] = this.params.data[this.payerType]?.[target.dataset.drug] || [[], []];

    const priorValues = prior.map(accumulateValuesFn(this.payerType, this.coverageMetric));
    const currentValues = current.map(accumulateValuesFn(this.payerType, this.coverageMetric));

    return {
      current: {
        yes: currentValues[0] || 0,
        all: _.sum(currentValues),
      },
      prior: {
        yes: priorValues[0] || 0,
        all: _.sum(priorValues),
      },
    };
  }

  targetIsDisabled(target) {
    const { drug, payerType } = target.dataset;

    // !!datum here converts undefined to false, avoiding toggleClass(..., undefined)
    // which regresses to jquery's on-argument "invert the state" instead of "set it to this".
    const payerTypeDisabled = !!payerType && !this.payerTypesShown.includes(payerType);
    const drugDisabled = !!drug && !this.drugs.includes(drug);

    return payerTypeDisabled || drugDisabled;
  }

  onBarClick(event) {
    const drug = $(event.target).data("drug");
    const payerType =
      this.payerType === "medicare_advantage_payers" ? "medicare_advantage" : this.payerType;

    const payerIds = {};
    const extraParams = {};

    const payerIdsFor = datasetIndex =>
      this.params.data[payerType][drug][datasetIndex][0].map(d => d.payer);

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(1);
      const priorPayers = payerIdsFor(0);

      payerIds[payerType] = _.union(currentPayers, priorPayers);
      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = { [payerType]: changedPayers.map(payer => ({ payer, drug })) };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changeDisplayMode = "access_status";
    } else {
      payerIds[payerType] = payerIdsFor(1);
    }

    showPayersModal(drug, "confirmed", payerIds, extraParams);
  }

  onBarRemainderClick(event) {
    const drug = $(event.target).data("drug");
    const payerType =
      this.payerType === "medicare_advantage_payers" ? "medicare_advantage" : this.payerType;

    const payerIds = {};
    const extraParams = {};

    const payerIdsFor = datasetIndex =>
      _.flatMap(this.params.data[payerType][drug][datasetIndex].slice(1), ds =>
        ds.map(d => d.payer),
      );

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(1);
      const priorPayers = payerIdsFor(0);

      payerIds[payerType] = _.union(currentPayers, priorPayers);
      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = {
        [payerType]: changedPayers.map(payer => ({ payer, drug: this.drug })),
      };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
    } else {
      payerIds[payerType] = payerIdsFor(1);
    }

    showPayersModal(drug, "unconfirmed", payerIds, extraParams);
  }

  onDrugNameClick(event) {
    const drug = event.currentTarget.dataset.drug;
    const button = this.drugInputTargets.find(target => target.value === drug);

    $(button).trigger("click");
  }
}
