import { Controller } from "stimulus";
import _ from "lodash";

const rowHtml = ({ uuid, text }) => `
  <div class="ml-2 my-1 horizontal-priority-sortable" data-uuid="${uuid}">
    <input type="hidden" name="custom_field[field_options_priority][]" value="${text}">
    <div class="badge border-gray-500 cursor-grab" title="Drag to reorder" data-sortable-handle="drag">${text}</div>
  </div>
`;

export default class extends Controller {
  static targets = ["reorder"];

  onChange(event) {
    const values = event.customFieldValues.map(({ uuid, longName, shortName }) => ({
      uuid,
      text: _.isEmpty(shortName.trim()) ? longName : shortName,
    }));

    _.forEach($(this.reorderTarget).find(".horizontal-priority-sortable"), el => {
      const value = values.find(v => v.uuid === $(el).data("uuid"));

      if (value) {
        $(el).find(".badge").text(value.text);

        $(el).find("input").val(value.text);
      } else {
        el.remove();
      }
    });

    const newlyAddedValues = _.reject(values, v => this.rowExists(v));

    $(this.reorderTarget).append(newlyAddedValues.map(rowHtml).join(""));
  }

  rowExists(value) {
    return $(this.reorderTarget).find(`[data-uuid=${value.uuid}]`).length > 0;
  }
}
