import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["result"];

  onStateChange({ currentTarget }) {
    $.ajax({
      url: `${this.baseUrl}/payers/${currentTarget.value}`,
      dataType: "html",
      context: this,
      success: data => {
        this.resultTarget.innerHTML = data;
      },
    });
  }

  get baseUrl() {
    return this.data.get("base-url");
  }
}
