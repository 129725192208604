import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    new MutationObserver(this.onClassChange.bind(this)).observe(this.element, {
      attributeFilter: ["class"],
    });
  }

  onClassChange() {
    const isHidden = this.element.classList.contains("hidden");
    const targetBottom = this.element.getBoundingClientRect().bottom;
    const distanceWithMargin = window.innerHeight - targetBottom - 10;

    if (isHidden || distanceWithMargin >= 0) {
      $(this.element).css("transform", "");
    } else {
      $(this.element).css("transform", `translateY(${distanceWithMargin}px)`);
    }
  }
}
