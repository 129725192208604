import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["filter", "label", "disable"];

  connect() {
    this.updateLabel();
  }

  filter(event) {
    const exclusions = event.target.dataset.filtersResetOnChange?.split(" ");
    const hash = this.urlHash(event.target);
    const url = `${window.location.pathname}?${this.params(exclusions)}${hash}`;

    $(this.disableTargets).prop("disabled", true);
    Turbolinks.clearCache();
    Turbolinks.visit(encodeURI(url));
  }

  leave(event) {
    const oldSlug = new RegExp(`${event.target.dataset.filtersLeaveSlug}[^/]*`);
    const newSlug = `${event.target.dataset.filtersLeaveSlug}${event.target.value}`;
    const pathname = window.location.pathname.replace(oldSlug, newSlug);

    const url = `${pathname}?${this.params()}${this.urlHash(event.target)}`;

    $(this.disableTargets).prop("disabled", true);
    Turbolinks.clearCache();
    Turbolinks.visit(encodeURI(url));
  }

  updateLabel() {
    this.labelTargets.forEach(label => {
      label.innerText = this.labelTextFromSelected();
    });
  }

  params(exclusions = []) {
    return this.selectedFilters
      .filter(t => !exclusions.includes(t.name))
      .map(t => `${t.name}=${t.value}`)
      .join("&");
  }

  propagateToDuplicates(event) {
    this.filterTargets
      .filter(t => t.name === event.currentTarget.name && t.value === event.currentTarget.value)
      .forEach(t => {
        t.checked = event.currentTarget.checked;
      });
  }

  urlHash(target) {
    const anchor = target.dataset.filtersAnchor;

    return anchor ? `#${anchor}` : window.location.hash;
  }

  get selectedFilters() {
    // Some input groups have duplicated inputs for desktop/mobile

    return _.uniqWith(
      this.filterTargets.filter(t => this.isFilterSelected(t)),
      (a, b) => a.name === b.name && a.value === b.value,
    );
  }

  isFilterSelected(target) {
    const isSelected = ["checkbox", "radio"].includes(target.type) ? target.checked : target.value;

    return target.dataset.filtersInvert ? !isSelected : isSelected;
  }

  labelTextFromSelected() {
    const selected = this.selectedFilters.length;

    switch (selected) {
      case 0:
        return "All";
      case 1:
        return $(this.selectedFilters[0]).closest("label").text().trim();
      default:
        return `${selected} ${this.element.dataset.filtersItemName} selected`;
    }
  }
}
