import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["columns", "rows", "grid"];

  connect() {
    this.resize();
  }

  resize() {
    const rows = parseInt(this.rowsTarget.value);
    const columns = parseInt(this.columnsTarget.value);

    if (rows > 0 && columns > 0) {
      $(this.gridTarget)
        .css("grid-template-columns", `repeat(${columns}, 1fr)`)
        .css("grid-template-rows", `repeat(${rows}, 1fr)`)
        .html(this.previewBoxes(rows, columns));
    }
  }

  previewBoxes(rows, columns) {
    const takenCoords = JSON.parse(this.gridTarget.dataset.takenCoords);

    const columnRange = _.range(1, columns + 1);
    const rowRange = _.range(1, rows + 1);
    const allCoords = rowRange.flatMap(row => columnRange.map(column => `${column},${row}`));

    const takenClass = coord => (takenCoords.includes(coord) ? "bg-gray-300" : "");

    return allCoords.map(coord => `<div class='border-2 border-gray ${takenClass(coord)}' />`);
  }
}
