import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider"];

  open() {
    this.sliderTarget.classList.remove("hidden");
  }

  close() {
    this.sliderTarget.classList.add("hidden");
  }
}
