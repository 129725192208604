import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message"];

  connect() {
    this.timeoutIds = [setTimeout(this.disappear, 4500), setTimeout(this.removeFlash, 5000)];

    document.addEventListener("turbolinks:before-cache", this.removeFlash, {
      once: true,
    });
  }

  close() {
    this.clearTimeouts();
    this.disappear();

    setTimeout(this.removeFlash, 500);
  }

  removeFlash = () => {
    if (this.hasMessageTarget) {
      this.messageTarget.parentNode.removeChild(this.messageTarget);
    }
  };

  disappear = () => {
    if (this.hasMessageTarget) {
      this.messageTarget.classList.add("disappear");
    }
  };

  clearTimeouts = () => this.timeoutIds.forEach(clearTimeout);

  disconnect() {
    this.clearTimeouts();
  }
}
