import { Controller } from "stimulus";
import InputMask from "inputmask";

export default class extends Controller {
  connect() {
    this.inputMask = new InputMask("9999[9]-999[9]-99", { tabThrough: true });
    this.inputMask.mask(this.element);
  }

  format() {
    const parts = this.element.value.replace(/_/g, "").split("-");

    if (parts.length === 3) {
      const formattedValue = [
        parts[0].padStart(5, "0"),
        parts[1].padStart(4, "0"),
        parts[2].padStart(2, "0"),
      ].join("-");

      this.element.value = formattedValue;
    }
  }
}
