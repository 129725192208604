import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["defaultOption", "range", "customContainer"];

  connect() {
    this.setRange(!this.defaultOptionTarget.checked);
  }

  enableRange() {
    this.setRange(true);
  }

  disableRange() {
    this.setRange(false);
  }

  setRange(enabled) {
    this.customContainerTarget.classList.toggle("hidden", !enabled);

    this.rangeTargets.forEach(el => {
      const input = el;

      input.disabled = !enabled;
    });
  }
}
