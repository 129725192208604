import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source"];

  copy() {
    const range = document.createRange();

    window.getSelection().removeAllRanges();

    const sourceHidden = $(this.sourceTarget).hasClass("hidden");

    $(this.sourceTarget).removeClass("hidden");

    range.selectNode(this.sourceTarget);
    window.getSelection().addRange(range);

    document.execCommand("copy");

    $(this.sourceTarget).toggleClass("hidden", sourceHidden);
    window.getSelection().removeAllRanges();
  }
}
