import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", "content", "clearItem"];

  showTab(event) {
    const index = parseInt(event.currentTarget.dataset.index);

    this.tabTargets.forEach((el, i) => {
      el.classList.toggle("active", index === i);
    });

    this.contentTargets.forEach((el, i) => {
      const contentIndex = el.dataset.tabIndex === undefined ? i : parseInt(el.dataset.tabIndex);

      el.classList.toggle(this.contentClass, index !== contentIndex);
    });

    this.clearItemTargets.forEach(clearItem => {
      if (clearItem?.checked) {
        const changeEvent = new CustomEvent("change", {
          bubbles: true,
          cancelable: true,
        });

        clearItem.checked = false;
        clearItem.dispatchEvent(changeEvent);
      }
    });
  }

  changeTabExternal(event) {
    // This is necessary (as opposed to binding an external event to #showTab)
    // because if the tab selector is a form's radio input, #showTab will not update
    // the inputs and the old, visually-unselected tab will be POSTed as active.
    const index = parseInt(event.currentTarget.dataset.index);

    $(this.tabTargets[index]).trigger("click");
  }

  get contentClass() {
    return this.data.get("content-class") || "hidden";
  }
}
