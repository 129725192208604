import { Controller } from "stimulus";
import "@algolia/autocomplete-theme-classic";
import { autocomplete } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch/lite";
import {
  HIGHLIGHT_PRE_TAG,
  HIGHLIGHT_POST_TAG,
} from "@algolia/autocomplete-preset-algolia/dist/esm/constants";
import { screenBreakpoints } from "../utilities/screen_size_breakpoints";

export default class extends Controller {
  initialize() {
    this.client = algoliasearch(this.data.get("algolia-id"), this.data.get("algolia-key"));
    this.isMobileView = this.data.get("size") === "small";
  }

  connect() {
    if (
      (window.innerWidth >= screenBreakpoints.sm && this.isMobileView) ||
      (window.innerWidth < screenBreakpoints.sm && !this.isMobileView)
    ) {
      return;
    }

    const sources = this.isMobileView ? this.buildSmallSource() : this.buildSource();

    this.search = autocomplete({
      container: this.isMobileView ? ".aa-input-container-sm" : ".aa-input-container-md",
      panelContainer: ".aa-medium-panel",
      hint: false,
      openOnFocus: this.isMobileView,
      autoFocus: this.isMobileView,
      placeholder: "Search...",
      detachedMediaQuery: this.isMobileView ? "" : "none",
      getSources: () => sources,
    });
  }

  disconnect() {
    this?.search?.destroy();
  }

  fetchResults(client) {
    return query =>
      client.search(this.searchParams(query)).then(data => {
        const groupedResults = {};
        const transformedHits = [];

        data.results[0].hits.forEach(h => {
          const key = h.category;

          if (groupedResults[key] === undefined) {
            groupedResults[key] = [];
          }

          groupedResults[key].push(h);
        });

        Object.keys(groupedResults).forEach(key => {
          groupedResults[key].forEach((hit, idx) => {
            transformedHits.push({
              showHeader: idx === 0,
              ...hit,
            });
          });
        });

        return transformedHits;
      });
  }

  searchParams(query) {
    return [
      {
        indexName: this.data.get("algolia-index"),
        query: query.query,
        params: {
          hitsPerPage: 8,
          filters: `visible_by:${this.data.get("visibility-key")}`,
          userToken: this.data.get("user"),
          optionalWords: query.query,
          highlightPreTag: HIGHLIGHT_PRE_TAG,
          highlightPostTag: HIGHLIGHT_POST_TAG,
        },
      },
    ];
  }

  buildSource() {
    return [
      {
        sourceId: "algoliaPublicIndex",
        getItems: this.fetchResults(this.client),
        getItemUrl({ item }) {
          return item.portal_link;
        },
        templates: {
          noResults() {
            return "No matching results.";
          },
          item(params) {
            const { item, components, html } = params;

            if (item.showHeader) {
              return html`
                <div class="w-full">
                  <div class="w-full mb-2">
                    <div class="py-2 mx-6 border-b border-gray-200">
                      <span class="text-gray-700 text-lg tracking-wide"> ${item.category} </span>
                    </div>
                  </div>
                  <a class="aa-ItemLink" href="${item.portal_link}">
                    <div class="flex w-full items-center">
                      <div class="w-150 border-r border-gray-200 text-right py-1 ml-6">
                        <div class="text-gray text-sm font-light tracking-wide mr-6">
                          ${components.Highlight({ hit: item, tagName: "mark", attribute: "type" })}
                        </div>
                      </div>
                      <div class="flex-1">
                        <div class="font-semibold w-300 text-gray-700 ml-6 mb-1">
                          ${components.Highlight({
                            hit: item,
                            tagName: "mark",
                            attribute: "title",
                          })}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              `;
            } else {
              return html`
                <div class="w-full">
                  <a class="aa-ItemLink" href="${item.portal_link}">
                    <div class="flex w-full items-center">
                      <div class="w-150 border-r border-gray-200 text-right py-1 ml-6">
                        <div class="text-gray text-sm font-light tracking-wide mr-6">
                          ${components.Highlight({ hit: item, tagName: "mark", attribute: "type" })}
                        </div>
                      </div>
                      <div class="flex-1">
                        <div class="font-semibold w-300 text-gray-700 ml-6 mb-1">
                          ${components.Highlight({
                            hit: item,
                            tagName: "mark",
                            attribute: "title",
                          })}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              `;
            }
          },
        },
      },
    ];
  }

  buildSmallSource() {
    return [
      {
        sourceId: "algoliaPublicIndex",
        getItems: this.fetchResults(this.client),
        getItemUrl({ item }) {
          return item.portal_link;
        },
        templates: {
          noResults() {
            return "No matching results.";
          },
          item(params) {
            const { item, components, html } = params;

            return html`
              <div class="w-full mb-4">
                <a class="aa-ItemLink" href="${item.portal_link}">
                  <div class="flex-col flex">
                    <div class="flex w-full">
                      <div class="flex-1">
                        <div class="font-semibold w-300 text-gray-700 ml-6 mb-1">
                          ${components.Highlight({
                            hit: item,
                            tagName: "mark",
                            attribute: "title",
                          })}
                        </div>
                      </div>
                    </div>
                    <div class="flex w-full">
                      <div class="flex-1">
                        <div class="font-semibold w-300 text-gray-400 ml-6 mb-1">
                          ${`${item.category} - `}
                          ${components.Highlight({
                            hit: item,
                            tagName: "mark",
                            attribute: "type",
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            `;
          },
        },
      },
    ];
  }
}
