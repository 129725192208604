import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hideable", "label"];

  toggle(event) {
    const token = $(event.target).closest("[data-hide-token]").data("hideToken");

    if (token) {
      this.hideableTargets
        .filter(target => target.dataset.hideToken === token)
        .forEach(target => target.classList.toggle("hidden"));

      this.labelTargets
        .filter(target => target.dataset.hideToken === token)
        .forEach(target => target.classList.toggle(this.labelClass));
    } else {
      this.hideableTargets
        .filter(target => !target.dataset.hideToken)
        .forEach(target => target.classList.toggle("hidden"));
      this.labelTargets
        .filter(target => !("hideToken" in target.dataset))
        .forEach(target => target.classList.toggle(this.labelClass));
    }
  }

  hideOutside(event) {
    if (!this.element.contains(event.target)) {
      this.hideableTargets.forEach(target => target.classList.add("hidden"));
      this.labelTargets.forEach(target => target.classList.remove(this.labelClass));
    }
  }

  get labelClass() {
    return this.data.get("label-class") || "active";
  }
}
