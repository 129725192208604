import _ from "lodash";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import {
  accumulateValuesFn,
  uniqPayersFromCustomFields,
  percentString,
  toOneDecimal,
} from "../../utilities/charts/chart_utils";
import shrinkWidgetToFit from "../../utilities/charts/shrink_widget_to_fit";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  static targets = [
    "rowTitle",
    "payersValue",
    "payersPercent",
    "payersTotal",
    "livesValue",
    "livesPercent",
    "livesTotal",
  ];

  initializeParams() {
    const params = super.initializeParams();

    this.enabledCustomFieldValues = params.all_values[this.drug];

    return params;
  }

  togglePayerTypeInternal() {
    this.renderChart();
  }

  toggleDrugInternal(previousDrug) {
    const allOldValues = this.params.all_values[previousDrug];
    const allNewValues = this.params.all_values[this.drug];

    const addedValues = _.without(allNewValues, ...allOldValues);
    const removedValues = _.without(allOldValues, ...allNewValues);

    this.enabledCustomFieldValues = _.without(
      this.enabledCustomFieldValues.concat(addedValues),
      ...removedValues,
    );

    this.renderChart();
  }

  updateShownPayersInternal() {
    this.renderChart();
  }

  renderChart() {
    this.updateValueRows();
    this.updateTotalRow();
    this.updateRowVisibility();
    shrinkWidgetToFit(this.element, this.gridTarget);
  }

  updateValueRows() {
    const counts = this.countsByCustomFieldValue();
    const shown = customFieldValue => this.enabledCustomFieldValues.includes(customFieldValue);

    this.rowTitleTargets.forEach(target => {
      const customFieldValue = $(target).data("custom-field-value");

      $(target).toggleClass("line-through", !shown(customFieldValue));
    });

    this.payersValueTargets.forEach(target => {
      const customFieldValue = $(target).data("custom-field-value");

      target.innerText = shown(customFieldValue) ? counts[customFieldValue]?.payers : "";
    });

    this.livesValueTargets.forEach(target => {
      const customFieldValue = $(target).data("custom-field-value");
      const livesCount = counts[customFieldValue]?.lives;

      target.innerText = shown(customFieldValue) ? this.livesString(livesCount) : "";
    });

    const enabledPayerCountSum = _.sum(
      this.enabledCustomFieldValues.map(enabledValue => counts[enabledValue]?.payers),
    );
    const enabledLivesCountSum = _.sum(
      this.enabledCustomFieldValues.map(enabledValue => counts[enabledValue]?.lives),
    );

    this.payersPercentTargets.forEach(target => {
      const customFieldValue = $(target).data("custom-field-value");

      target.innerText = shown(customFieldValue)
        ? percentString(counts[customFieldValue]?.payers, enabledPayerCountSum)
        : "";
    });

    this.livesPercentTargets.forEach(target => {
      const customFieldValue = $(target).data("custom-field-value");

      target.innerText = shown(customFieldValue)
        ? percentString(counts[customFieldValue]?.lives, enabledLivesCountSum)
        : "";
    });
  }

  updateTotalRow() {
    const sums = this.uniquePayerLivesCounts();

    this.payersTotalTarget.innerText = sums.payers.toLocaleString();
    this.livesTotalTarget.innerText = this.livesString(sums.lives);
  }

  updateRowVisibility() {
    this.allCustomFieldTargets.forEach(target => {
      const drug = $(target).data("drug");

      $(target).toggleClass("hidden", drug !== this.drug);
    });
  }

  toggleCustomFieldValue(event) {
    const customFieldValue = $(event.target).data("custom-field-value");

    if (this.enabledCustomFieldValues.includes(customFieldValue)) {
      this.enabledCustomFieldValues = _.without(this.enabledCustomFieldValues, customFieldValue);
    } else {
      this.enabledCustomFieldValues = this.enabledCustomFieldValues.concat(customFieldValue);
    }

    this.updateValueRows();
    this.updateTotalRow();
  }

  uniquePayerLivesCounts() {
    const counts = { payers: 0, lives: 0 };

    this.payerTypesShown.forEach(payerType => {
      const uniquePayers = uniqPayersFromCustomFields(this.params.data[this.drug][payerType]);

      counts.payers += accumulateValuesFn(payerType, "payers")(uniquePayers);
      counts.lives += accumulateValuesFn(payerType, "lives")(uniquePayers);
    });

    return counts;
  }

  countsByCustomFieldValue() {
    const values = this.enabledCustomFieldValues;
    const counts = Object.fromEntries(values.map(v => [v, { payers: 0, lives: 0 }]));

    this.payerTypesShown.forEach(payerType => {
      values.forEach(customFieldValue => {
        const data = this.params.data[this.drug][payerType]?.[customFieldValue];

        counts[customFieldValue].payers += accumulateValuesFn(payerType, "payers")(data || []);
        counts[customFieldValue].lives += accumulateValuesFn(payerType, "lives")(data || []);
      });
    });

    return counts;
  }

  livesString(count) {
    return count > 0 ? `${toOneDecimal(count).toLocaleString()}m` : "0";
  }

  get allCustomFieldTargets() {
    return this.rowTitleTargets
      .concat(this.payersValueTargets)
      .concat(this.payersPercentTargets)
      .concat(this.livesValueTargets)
      .concat(this.livesPercentTargets);
  }
}
