import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["hideable", "radio", "select", "label"];

  connect() {
    const preselectedRadios = this.radioTargets.filter(target => target.checked);
    const preselectedSelects = this.selectTargets.filter(target => !!target.value);

    const preselectedInputs = preselectedRadios.concat(preselectedSelects);

    preselectedInputs.forEach(target => this.toggle({ target }));
  }

  toggle(event) {
    const targetToShow = event.target.value;

    const matchesGroup = t => t.dataset.hideManyGroup === event.target.dataset.hideManyGroup;

    this.hideableTargets.filter(matchesGroup).forEach(target => {
      const matches = target.dataset.hideManyToken.split(" ").includes(targetToShow);

      target.classList.toggle("hidden", !matches);
    });

    this.labelTargets.filter(matchesGroup).forEach(target => {
      const active = target.dataset.hideManyToken === targetToShow;

      target.classList.toggle("active", active);
    });
  }
}
