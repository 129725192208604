import { Controller } from "stimulus";
import _ from "lodash";
import HtmldiffAsyncRunner from "../utilities/htmldiff_async_runner";

export default class extends Controller {
  static targets = [
    "select",
    "snapshot",
    "snapshotTrix",
    "snapshotTrixContainer",
    "snapshotTrixPlaceholder",
    "htmldiff",
    "current",
  ];

  connect() {
    this.asyncRunners = {};
    this.update();
  }

  async fetchAndUpdate() {
    const response = await fetch(this.resourceUrl);
    const newFieldValues = await response.json();

    this.snapshotTargets.forEach(el => {
      el.innerHTML = [
        "<div class='trix-content'>",
        newFieldValues?.[$(el).data("section-entry-field-name")],
        "</div>",
      ].join("\n");
    });

    this.snapshotTrixTargets.forEach(el => {
      el.editor.loadHTML(newFieldValues?.[$(el).data("section-entry-field-name")] || "");
    });

    this.snapshotTrixContainerTargets.forEach(el => {
      $(el).toggleClass(
        "hidden",
        _.isEmpty(newFieldValues?.[$(el).data("section-entry-field-name")]),
      );
    });

    this.snapshotTrixPlaceholderTargets.forEach(el => {
      $(el).toggleClass(
        "hidden",
        !_.isEmpty(newFieldValues?.[$(el).data("section-entry-field-name")]),
      );
    });

    this.update();
  }

  update() {
    this.allFieldNames.forEach(thisField => {
      const findMatching = targets => $(targets.find(el => this.fieldName(el) === thisField));

      const snapshotValue =
        findMatching(this.snapshotTargets).find(".trix-content").html() || this.diffHtml("");
      const currentValue =
        findMatching(this.currentTargets).find(".trix-content").html() || this.diffHtml("");

      this.initializeRunner(thisField);
      this.asyncRunners[thisField].calculateDiff(snapshotValue, currentValue);
    });
  }

  renderDiff(fieldName, diff) {
    this.htmldiffTargets
      .filter(t => this.fieldName(t) === fieldName)
      .forEach(target => {
        target.innerHTML = this.diffHtml(diff);
      });
  }

  initializeRunner(thisField) {
    if (!this.asyncRunners[thisField]) {
      this.asyncRunners[thisField] = new HtmldiffAsyncRunner(d => this.renderDiff(thisField, d));
    }
  }

  fieldName(target) {
    return $(target).data("section-entry-field-name");
  }

  diffHtml(diff) {
    return ["<div class='trix-content htmldiff-output'>", diff, "</div>"].join("\n");
  }

  get allFieldNames() {
    return _.uniq(this.currentTargets.map(t => this.fieldName(t)));
  }

  get resourceUrl() {
    const snapshotSetId = this.selectTarget.value;
    const urlBase = this.data.get("url");

    return `${urlBase}?snapshot_set_id=${snapshotSetId}`;
  }
}
