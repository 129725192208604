import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["chartType", "payerTypeInput"];

  static values = { limitedTypesByChart: Object };

  connect() {
    this.onChartTypeChanged();
  }

  onChartTypeChanged() {
    const limitedPayerTypes = this.limitedTypesByChartValue[this.currentChartType];

    this.payerTypeInputTargets.forEach(t => {
      if (limitedPayerTypes && !limitedPayerTypes.includes(t.dataset.payerType)) {
        t.disabled = true;
        t.checked = false;
        t.labels.forEach(l => l.classList.add("opacity-50"));
      } else {
        t.disabled = false;
        t.labels.forEach(l => l.classList.remove("opacity-50"));
      }
    });
  }

  get currentChartType() {
    return this.chartTypeTargets.find(t => t.checked)?.value;
  }
}
