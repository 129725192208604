import _ from "lodash";
import showPayersModal from "../../utilities/charts/payers_modal";
import PayersLivesDonutChartBase from "./payers_lives_donut_chart_base";
import {
  accumulateValuesFn,
  payersNoun,
  withinOneDecimal,
} from "../../utilities/charts/chart_utils";

export default class extends PayersLivesDonutChartBase {
  calculateFractions(coverageMetric) {
    let confirmed = 0;

    let total = 0;

    let priorConfirmed = 0;

    let priorTotal = 0;

    _.forIn(this.currentDatasets(this.payerTypesShown), (byAccessStatus, payerType) => {
      const accumulate = accumulateValuesFn(payerType, coverageMetric);

      confirmed += accumulate(byAccessStatus[0]);
      total += _.sum(byAccessStatus.map(accumulate));
    });

    _.forIn(this.priorDatasets(this.payerTypesShown), (byAccessStatus, payerType) => {
      const accumulate = accumulateValuesFn(payerType, coverageMetric);

      priorConfirmed += accumulate(byAccessStatus[0]);
      priorTotal += _.sum(byAccessStatus.map(accumulate));
    });

    const currentFraction = total === 0 ? 0 : confirmed / total;
    const priorFraction = priorTotal === 0 ? 0 : priorConfirmed / priorTotal;

    let mainFraction = 0;

    let increaseFraction = 0;

    let decreaseFraction = 0;

    if (this.showChangesFromPrevious && !withinOneDecimal(currentFraction, priorFraction)) {
      if (currentFraction > priorFraction) {
        mainFraction = priorFraction;
        increaseFraction = currentFraction - priorFraction;
      } else {
        mainFraction = currentFraction;
        decreaseFraction = priorFraction - currentFraction;
      }
    } else {
      mainFraction = currentFraction;
    }

    return {
      mainFraction,
      increaseFraction,
      decreaseFraction,
    };
  }

  triggerModalInternal(collection) {
    const groupType = {
      base: "confirmed",
      changed: "changed",
      remainder: "unconfirmed",
    }[collection];

    const payerTypes = this.payerTypesShown.map(payerType =>
      payerType === "medicare_advantage_payers" ? "medicare_advantage" : payerType,
    );

    const payerIds = {};
    const extraParams = {};

    if (groupType === "changed") {
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
    }

    payerTypes.forEach(payerType => {
      const dataset = this.params.data[payerType][this.drug];

      if (this.showChangesFromPrevious && ["base", "changed"].includes(collection)) {
        const priorPayers = dataset[0][0].map(d => d.payer);
        const currentPayers = dataset[1][0].map(d => d.payer);
        const combinatoric = collection === "changed" ? _.xor : _.intersection;

        payerIds[payerType] = combinatoric(currentPayers, priorPayers);
      } else if (collection === "base") {
        payerIds[payerType] = dataset[1][0].map(d => d.payer);
      } else if (collection === "remainder") {
        payerIds[payerType] = _.flatMap(dataset[1].slice(1), ds => ds.map(d => d.payer));
      }
    });

    showPayersModal(this.drug, groupType, payerIds, extraParams);
  }

  currentDatasets(payerTypes) {
    const forShownPayers = _.pick(this.params.data, payerTypes);
    const withCurrentDrug = _.omitBy(forShownPayers, x => _.isEmpty(x[this.drug]));

    return _.mapValues(withCurrentDrug, x => x[this.drug]?.[1] || 0);
  }

  priorDatasets(payerTypes) {
    const forShownPayers = _.pick(this.params.data, payerTypes);
    const withCurrentDrug = _.omitBy(forShownPayers, x => _.isEmpty(x[this.drug]));

    return _.mapValues(withCurrentDrug, x => x[this.drug]?.[0] || 0);
  }

  get titleText() {
    return `${payersNoun(this.payerTypesShown)} & Lives with Confirmed Access for ${this.drug}`;
  }

  get tooltipTitleText() {
    return "Has Confirmed Access";
  }
}
