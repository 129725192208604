import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "form"];

  show() {
    this.fetchForm().then(this.waitsForDOMToRepaintTheScreenAfterInsertingFormThenShowsModal);
  }

  waitsForDOMToRepaintTheScreenAfterInsertingFormThenShowsModal = () =>
    setTimeout(() => this.modalTarget.classList.remove("hidden"));

  hide() {
    this.modalTarget.classList.add("hidden");
  }

  fetchForm() {
    return fetch(this.data.get("url")).then(this.afterFormFetch);
  }

  afterFormFetch = response => {
    if (response.headers.has("Scout-Redirect")) {
      Turbolinks.clearCache();
      Turbolinks.visit(response.headers.get("Scout-Redirect"), {
        action: "replace",
      });
      return;
    }

    response.text().then(html => {
      this.formTarget.innerHTML = html;

      $(this.element)
        .find("form")
        .each((_i, form) => {
          form.addEventListener("submit", e => {
            e.preventDefault();

            fetch(form.action, {
              method: form.method,
              body: new FormData(form),
            }).then(this.afterFormFetch);

            return false;
          });
        });
    });
  };
}
