import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["widget", "coordinate", "newWidgetArea", "form"];

  onDragStart(event) {
    const coords = this.gridCoordinates(event.target.parentElement);
    const { dragOperation } = event.target.dataset;

    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("dragOperation", dragOperation);

    const otherWidgets = _.without(this.widgetTargets, event.target.parentElement);

    this.workingData = {
      dragOperation,
      otherWidgets: otherWidgets.map(this.gridCoordinates),
      newArea: coords,
      ...this.extraWorkingData(dragOperation, event.target.dataset, coords),
    };

    this.repositionAreaTarget(false);

    $(this.coordinateTargets).removeClass("hidden");
    $(this.newWidgetAreaTarget).removeClass("hidden");
    $(event.target.parentElement).removeClass("z-10").addClass("z-30");
  }

  extraWorkingData(dragOperation, dataset, coords) {
    return dragOperation.includes("resize")
      ? this.calculateResizeOrigin(dragOperation, dataset.startPoint, coords)
      : this.calculateMoveOriginAndOffset(dataset, coords);
  }

  calculateResizeOrigin(direction, startPoint, coords) {
    const origin = {};

    const anchorHorizontalEnd = startPoint?.includes("west") ? coords.columnSpan - 1 : 0;
    const anchorVerticalEnd = startPoint?.includes("north") ? coords.rowSpan - 1 : 0;

    if (direction === "resize-horizontal") {
      origin.row = coords.row;
      origin.rowSpan = coords.rowSpan;

      origin.column = coords.column + anchorHorizontalEnd;
    } else if (direction === "resize-vertical") {
      origin.column = coords.column;
      origin.columnSpan = coords.columnSpan;

      origin.row = coords.row + anchorVerticalEnd;
    } else if (direction === "resize-diagonal") {
      origin.column = coords.column + anchorHorizontalEnd;
      origin.row = coords.row + anchorVerticalEnd;
    }

    return { origin };
  }

  calculateMoveOriginAndOffset(dataset, coords) {
    return {
      offset: JSON.parse(dataset.offset),
      origin: {
        rowSpan: coords.rowSpan,
        columnSpan: coords.columnSpan,
      },
    };
  }

  calculateNewArea(targetCoords) {
    const { dragOperation } = this.workingData;

    return dragOperation.includes("resize")
      ? this.calculateNewResizeArea(dragOperation, targetCoords)
      : this.calculateNewMoveArea(targetCoords);
  }

  calculateNewResizeArea(dragOperation, targetCoords) {
    const newArea = {};

    const boundingStartColumn = Math.min(targetCoords.column, this.workingData.origin.column);
    const boundingEndColumn = Math.max(targetCoords.column + 1, this.workingData.origin.column + 1);
    const boundingStartRow = Math.min(targetCoords.row, this.workingData.origin.row);
    const boundingEndRow = Math.max(targetCoords.row + 1, this.workingData.origin.row + 1);

    if (dragOperation === "resize-vertical") {
      newArea.column = this.workingData.origin.column;
      newArea.columnSpan = this.workingData.origin.columnSpan;

      newArea.row = boundingStartRow;
      newArea.rowSpan = boundingEndRow - boundingStartRow;
    } else if (dragOperation === "resize-horizontal") {
      newArea.column = boundingStartColumn;
      newArea.columnSpan = boundingEndColumn - boundingStartColumn;

      newArea.row = this.workingData.origin.row;
      newArea.rowSpan = this.workingData.origin.rowSpan;
    } else if (dragOperation === "resize-diagonal") {
      newArea.column = boundingStartColumn;
      newArea.columnSpan = boundingEndColumn - boundingStartColumn;

      newArea.row = boundingStartRow;
      newArea.rowSpan = boundingEndRow - boundingStartRow;
    }

    return newArea;
  }

  calculateNewMoveArea(targetCoords) {
    const { columnSpan, rowSpan } = this.workingData.origin;

    const targetColumn = targetCoords.column - this.workingData.offset.column;
    const targetRow = targetCoords.row - this.workingData.offset.row;

    const maximum = this.maxCoords();
    const maxColumn = maximum.column - columnSpan + 1;
    const maxRow = maximum.row - rowSpan + 1;

    const bounded = (ideal, max) => Math.max(1, Math.min(ideal, max));

    return {
      column: bounded(targetColumn, maxColumn),
      row: bounded(targetRow, maxRow),
      columnSpan,
      rowSpan,
    };
  }

  onDragOver(event) {
    const targetCoords = this.gridCoordinates(event.target);

    this.workingData.newArea = this.calculateNewArea(targetCoords);

    const anyOverlap = this.newAreaOverlapsOtherWidgets();

    this.repositionAreaTarget(anyOverlap);
    this.setDragCursor(event, anyOverlap);

    return true;
  }

  onDragEnd(event) {
    const endCoords = this.workingData.newArea;
    const chartID = event.target.parentElement.dataset.chart;
    const $form = $(this.formTargets.find(f => f.dataset.chart === chartID));

    const widgetMoved = this.anyChanges(endCoords, $form.serializeArray());
    const widgetIsValid = !this.newAreaOverlapsOtherWidgets();

    if (widgetMoved && widgetIsValid) {
      const setFormValue = (key, value) =>
        $form.find(`[name='portal_analytics_chart[${key}]']`).val(value);

      setFormValue("column", endCoords.column);
      setFormValue("row", endCoords.row);
      setFormValue("column_span", endCoords.columnSpan);
      setFormValue("row_span", endCoords.rowSpan);
      $form.trigger("submit");
    } else {
      $(this.coordinateTargets).addClass("hidden");
      $(this.newWidgetAreaTarget).addClass("hidden");
      $(this.widgetTargets).removeClass("z-30").addClass("z-10");
      this.workingData = {};
    }
  }

  repositionAreaTarget(hasError) {
    const cssCoords = {
      "grid-column-start": this.workingData.newArea.column,
      "grid-column-end": this.workingData.newArea.column + this.workingData.newArea.columnSpan,
      "grid-row-start": this.workingData.newArea.row,
      "grid-row-end": this.workingData.newArea.row + this.workingData.newArea.rowSpan,
    };

    $(this.newWidgetAreaTarget)
      .css(cssCoords)
      .toggleClass("bg-red-400", hasError)
      .toggleClass("bg-blue-400", !hasError);
  }

  setDragCursor(event, hasError) {
    event.preventDefault();
    event.dataTransfer.dropEffect = hasError ? "none" : "move";
  }

  gridCoordinates(target) {
    const column = parseInt(target.dataset.column);
    const row = parseInt(target.dataset.row);
    const columnSpan = parseInt(target.dataset.columnSpan) || 1;
    const rowSpan = parseInt(target.dataset.rowSpan) || 1;

    return { column, row, columnSpan, rowSpan };
  }

  anyChanges(newGridCoords, currentFormValues) {
    const formValue = key =>
      parseInt(currentFormValues.find(obj => obj.name === `portal_analytics_chart[${key}]`).value);

    return (
      newGridCoords.column !== formValue("column") ||
      newGridCoords.row !== formValue("row") ||
      newGridCoords.columnSpan !== formValue("column_span") ||
      newGridCoords.rowSpan !== formValue("row_span")
    );
  }

  newAreaOverlapsOtherWidgets() {
    const area = this.workingData.newArea;

    const startBeforeEnd = (a, b, key) => a[key] < b[key] + b[`${key}Span`];

    const intersectsAlongAxis = (a, b, key) =>
      startBeforeEnd(a, b, key) && startBeforeEnd(b, a, key);

    return this.workingData.otherWidgets.some(
      widget =>
        intersectsAlongAxis(area, widget, "column") && intersectsAlongAxis(area, widget, "row"),
    );
  }

  maxCoords() {
    const allCoords = this.coordinateTargets.map(target => this.gridCoordinates(target));

    return {
      row: Math.max(...allCoords.map(x => x.row)),
      column: Math.max(...allCoords.map(y => y.column)),
    };
  }
}
