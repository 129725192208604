import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.grow();
  }

  grow() {
    this.element.style.height = "5px";
    this.element.style.height = `${this.element.scrollHeight + 1}px`;
  }
}
