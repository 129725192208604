import { Controller } from "stimulus";
import InputMask from "inputmask";

export default class extends Controller {
  connect() {
    const decimalPlaces = this.data.get("scale") || 0;

    this.inputMask = new InputMask("decimal", {
      digits: decimalPlaces,
      rightAlign: false,
      enforceDigitsOnBlur: true,
      allowMinus: false,
    });
    this.inputMask.mask(this.element);
  }
}
