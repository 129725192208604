import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["addressForm", "addressId"];

  connect() {
    if (this.addressIdTarget.value === "0") {
      this.showCustomForm();
    }
  }

  addressTypeChange() {
    if (this.addressIdTarget.value === "0") {
      this.showCustomForm();
    } else {
      this.hideCustomForm();
    }
  }

  hideCustomForm() {
    this.addressFormTarget.classList.add("hidden");
  }

  showCustomForm() {
    this.addressFormTarget.classList.remove("hidden");
  }
}
