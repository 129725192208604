import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["details", "chevron"];

  toggle() {
    this.chevronTargets.forEach(t => t.classList.toggle("rotate-right"));
    this.detailsTargets.forEach(t => t.classList.toggle("hidden"));
  }
}
