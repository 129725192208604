import _ from "lodash";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import { accumulateValuesFn, coverageMetricNoun } from "../../utilities/charts/chart_utils";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  static targets = [
    "title",
    "initialAuthIndicator",
    "initialAuthLabel",
    "reauthIndicator",
    "reauthLabel",
  ];

  toggleCoverageMetricInternal() {
    this.renderChart();
  }

  togglePayerTypeInternal() {
    this.renderChart();
  }

  toggleDrugInternal() {
    this.renderChart();
  }

  updateShownPayersInternal() {
    this.renderChart();
  }

  renderChart() {
    this.updateAttribute(
      "initial_length",
      this.initialAuthIndicatorTargets,
      this.initialAuthLabelTargets,
    );
    this.updateAttribute("reauth_length", this.reauthIndicatorTargets, this.reauthLabelTargets);

    const nouns = coverageMetricNoun(this.coverageMetric, this.payerTypesShown);

    $(this.titleTargets).text(`Average PA Auth/Reauth Length for ${this.drug} by ${nouns}`);
  }

  updateAttribute(key, indicatorTarget, labelTarget) {
    const percentage = this.percentageForAttribute(key);

    const ensuringNoOverrun = percentage > 0.99 ? "calc(100% - 0.25rem)" : `${percentage * 100}%`;

    $(indicatorTarget).css("left", ensuringNoOverrun);

    $(labelTarget)
      .css(percentage < 0.5 ? "left" : "right", "0.5rem")
      .css(percentage < 0.5 ? "right" : "left", "")
      .text(`${(percentage * 12).toFixed(1)} Months`);
  }

  percentageForAttribute(attribute) {
    const byPayerType = _.pick(this.params[attribute]?.[this.drug], this.payerTypesShown);

    let numerator = 0;

    let denominator = 0;

    _.forIn(byPayerType, (payersByLength, payerType) => {
      const accumulate = accumulateValuesFn(payerType, this.coverageMetric);

      _.forIn(payersByLength, (payers, key) => {
        const weight = accumulate(payers);

        switch (key) {
          case "3 Months":
            numerator += weight * 0.25;
            denominator += weight;
            break;
          case "6 Months":
            numerator += weight * 0.25;
            denominator += weight;
            break;
          case "9 Months":
            numerator += weight * 0.25;
            denominator += weight;
            break;
          case "12 Months":
            numerator += weight;
            denominator += weight;
            break;
          default:
          // Non-standard and non-numeric entries do not contribute to the average
        }
      });
    });

    return numerator / denominator;
  }
}
