/* eslint-disable class-methods-use-this */

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["stickyHeader"];

  jump(event) {
    let { anchor } = event.currentTarget.dataset;

    if (!anchor) {
      anchor = event.currentTarget.value;
    }

    if (anchor) {
      const section = document.getElementById(anchor);

      if (this.hasStickyHeaderTarget) {
        this.scrollWithOffset(section);
      } else {
        this.scrollWithoutOffset(section);
      }
    }
  }

  jumpToTop() {
    document.scrollingElement.scrollIntoView({ behavior: "smooth" });
  }

  scrollWithOffset(section) {
    const position = $(section).offset().top;
    const headerHeight = $(this.stickyHeaderTarget).outerHeight();
    const marginForReadability = 24;

    window.scroll({ top: position - headerHeight - marginForReadability, behavior: "smooth" });

    this.animate(section);
  }

  scrollWithoutOffset(section) {
    section.scrollIntoView({ behavior: "smooth" });

    this.animate(section);
  }

  animate(section) {
    section.classList.remove("animate-slow");
    section.classList.add("pulse");

    window.setTimeout(() => {
      section.classList.add("animate-slow");
      section.classList.remove("pulse");
    }, 1000);
  }
}
