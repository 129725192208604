import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    super.connect();

    this.element.onchange = event => {
      const url = `/market_research/${event.target.value}`;

      Turbolinks.clearCache();
      Turbolinks.visit(url);
    };
  }
}
