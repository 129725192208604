import _ from "lodash";
import colors from "../../utilities/charts/colors";
import USStateChartControllerBase from "./us_state_chart_controller_base";

export default class extends USStateChartControllerBase {
  titleText() {
    const payerType = window.PayerTypeLabels[this.payerType];

    return `${this.drug} ${payerType} ${this.params.custom_field}`;
  }

  displayValueFromFraction(fraction) {
    const index = _.round(fraction * this.allValues.length);

    return this.allValues[index];
  }

  colorForDisplayValue(value) {
    return _.values(colors)[_.indexOf(this.allValues, value)];
  }

  // This does not currently support prioritized values for multiple payers
  fractionForState(payerType, state) {
    const payersByValue = this.params.data[this.drug]?.[payerType]?.[state];

    if (_.isEmpty(payersByValue)) {
      return _.indexOf(this.allValues, "N/I") / this.allValues.length;
    } else {
      const value = _.keys(payersByValue)[0];
      const index = _.indexOf(this.allValues, value);

      return index / this.allValues.length;
    }
  }

  payersForState(payerType, state) {
    const payersByValue = this.params.data[this.drug]?.[payerType]?.[state];

    return _.isEmpty(payersByValue) ? [] : _.flatten(_.values(payersByValue));
  }

  coverageDetailsAnchor() {
    return "custom";
  }

  get allValues() {
    return this.params.all_values[this.drug];
  }
}
