import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["searchBox", "listItem"];

  connect() {
    this.clearFilter();
  }

  filterList() {
    const allowFilter = /[^a-z0-9 ]/gi;
    const filterExp = new RegExp(this.searchBoxTarget.value.replace(allowFilter, ""), "i");

    this.listItemTargets.forEach(listItem => {
      if (listItem.innerText.match(filterExp)) {
        listItem.classList.toggle("hidden", false);
      } else {
        listItem.classList.toggle("hidden", true);
        const inputField = listItem.querySelector("input");

        if (inputField?.checked) {
          const event = new CustomEvent("change", {
            bubbles: true,
            cancelable: true,
          });

          inputField.checked = false;
          inputField.dispatchEvent(event);
        }
      }
    });
  }

  clearFilter() {
    this.searchBoxTarget.value = "";
    this.searchBoxTarget.focus();
    this.filterList();
  }
}
