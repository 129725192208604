import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    highlight: String,
    dismissUrl: String,
  };

  connect() {
    if (this.highlightElement) {
      if (!this.anchor) {
        this.highlightElement.scrollIntoView();
      }
      this.highlightElement.classList.add("bg-blue-100", "hover:bg-blue-200");
    }
  }

  dismiss() {
    fetch(this.dismissUrlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then(() => {
      if (this.highlightElement) {
        this.highlightElement.classList.remove("bg-blue-100", "hover:bg-blue-200");
      }

      this.element.remove();
    });
  }

  get highlightElement() {
    if (this.hasHighlightValue) {
      return document.querySelector(`#${this.highlightValue}`);
    }

    return false;
  }

  get anchor() {
    return window.location.hash;
  }
}
