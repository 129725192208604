import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.onchange = ({ target: { value } }) => {
      Turbolinks.clearCache();
      Turbolinks.visit(value, { action: "replace" });
    };
  }
}
