import { Controller } from "stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["menu", "toggle"];

  static values = { position: String };

  connect() {
    window.addEventListener("click", this.onOutsideClick.bind(this));
  }

  toggle() {
    const shouldOpen = this.menuTarget.classList.contains("hidden");

    this.closeMenu();

    if (shouldOpen) {
      this.popper = createPopper(this.toggleTarget, this.menuTarget, {
        placement: this.positionValue || "top",
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              mainAxis: true,
              altAxis: true,
              padding: 5,
            },
          },
        ],
      });

      this.menuTarget.classList.remove("hidden");
      this.toggleTarget.classList.add("active");
    }
  }

  teardown() {
    this.closeMenu();
  }

  closeMenu() {
    this.popper?.destroy();
    $(this.menuTargets).addClass("hidden");
    $(this.toggleTargets).removeClass("hidden");
  }

  onOutsideClick({ target }) {
    if (!this.isInside(target)) {
      this.closeMenu();
    }
  }

  isInside(target) {
    return this.menuTargets.concat(this.toggleTargets).some(c => c.contains(target));
  }
}
