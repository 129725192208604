import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import BarsChartGridBase from "./bars_chart_grid_base";
import shrinkWidgetToFit from "../../utilities/charts/shrink_widget_to_fit";
import { percent, percentString, toOneDecimal } from "../../utilities/charts/chart_utils";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  static targets = [
    "title",
    "mainPercent",
    "mainDescription",
    "gridTitle",
    "gridFraction",
    "gridPercent",
    "gridChangeIndicator",
    "changesContainer",
    "changesText",
  ];

  connect() {
    this.innerClass = new BarsChartGridBase(this);
    super.connect();
  }

  toggleCoverageMetricInternal() {
    this.renderChart();
  }

  togglePayerTypeInternal() {
    this.renderChart();
  }

  toggleDrugInternal() {
    this.renderChart();
  }

  updateShownPayersInternal() {
    this.renderChart();
  }

  renderChart() {
    this.setMainStats();
    this.setMainDifference();
    this.innerClass.renderGrid();
    this.updatePayerTypeVisibility();
    shrinkWidgetToFit(this.element, this.element.children[0]);
  }

  togglePayerTypeFromChart(event) {
    const payerType = $(event.target).data("payer-type");
    const button = this.payerTypeInputTargets.find(target => target.value === payerType);

    $(button).trigger("click");
  }

  setMainStats() {
    const { yes, all } = this.accumulateDataForShownPayers().current;

    $(this.titleTarget).text(this.titleText());
    $(this.mainPercentTarget).text(percentString(yes, all));
    $(this.mainDescriptionTarget).text(this.descriptionText(yes, all));
  }

  setMainDifference() {
    if (this.showChangesFromPrevious) {
      const currentData = this.accumulateDataForShownPayers().current;
      const previousData = this.accumulateDataForShownPayers().prior;

      const currentPercent = percent(currentData.yes, currentData.all);
      const previousPercent = percent(previousData.yes, previousData.all);
      const changeAsShown = toOneDecimal(
        parseFloat(toOneDecimal(currentPercent)) - parseFloat(toOneDecimal(previousPercent)),
      );

      if (changeAsShown === "0") {
        this.changesTextTarget.innerText = "No Change";
        this.changesContainerTarget.classList.add("text-yellow");
        this.changesContainerTarget.classList.remove("text-red");
        this.changesContainerTarget.classList.remove("text-green-500");
      } else if (currentPercent > previousPercent) {
        this.changesTextTarget.innerText = `+${changeAsShown}`;
        this.changesContainerTarget.classList.add("text-green-500");
        this.changesContainerTarget.classList.remove("text-yellow");
        this.changesContainerTarget.classList.remove("text-red");
      } else {
        this.changesTextTarget.innerText = changeAsShown;
        this.changesContainerTarget.classList.add("text-red");
        this.changesContainerTarget.classList.remove("text-yellow");
        this.changesContainerTarget.classList.remove("text-green-500");
      }

      this.changesContainerTarget.title = `${toOneDecimal(previousPercent)}% in ${
        this.params.previous_snapshot_set.name
      }`;
    }
  }

  accumulateDataForShownPayers() {
    const output = {
      prior: { yes: 0, all: 0 },
      current: { yes: 0, all: 0 },
    };

    this.gridTitleTargets
      .filter(t => !t.classList.contains("hidden"))
      .forEach(target => {
        const { current, prior } = this.currentAndPriorDataForTarget(target);

        output.prior.yes += prior.yes;
        output.current.yes += current.yes;
        output.prior.all += prior.all;
        output.current.all += current.all;
      });

    return output;
  }

  targetIsDisabled(target) {
    return !this.payerTypesShown.includes(target.dataset.payerType);
  }

  updatePayerTypeVisibility() {
    this.gridTitleTargets
      .concat(this.gridFractionTargets)
      .concat(this.gridChangeIndicatorTargets)
      .concat(this.gridPercentTargets)
      .forEach(target => {
        $(target).toggleClass(
          "hidden",
          !this.payerTypesIncluded.includes(target.dataset.payerType),
        );
      });
  }

  formatNumber(count) {
    const suffix = this.coverageMetric === "lives" ? "m" : "";

    return `${toOneDecimal(count)}${suffix}`;
  }

  titleText() {
    // Override
  }

  descriptionText(/* yes, all */) {
    // Override
  }

  currentAndPriorDataForTarget() {
    // Override.  Return { current: { yes: ..., all: ... }, prior: ... }
  }
}
