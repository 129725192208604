import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "actions",
    "header",
    "selectAll",
    "counter",
    "spinner",
    "item",
    "dropdown",
    "progress",
  ];

  connect() {
    this.hideActions();
    this.itemClicked();
  }

  itemClicked() {
    const count = this.selectedItems.length;

    if (count > 0) {
      this.showActions(count);
    } else {
      this.hideActions();
    }
  }

  actionClicked(event) {
    const action = event.currentTarget;

    action.disabled = true;
    this.showSpinner();

    if ("url" in action.dataset) {
      fetch(action.dataset.url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        body: JSON.stringify({
          bulk_ids: this.selectedItems,
        }),
      }).then(resp => {
        this.hideSpinner();

        if (resp.status >= 400) {
          alert("Something went wrong performing this action."); // eslint-disable-line
          action.disabled = false;
        }

        Turbolinks.visit(window.location);
      });
    } else if ("jobUrl" in action.dataset) {
      this.showProgress();
      fetch(action.dataset.jobUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        body: JSON.stringify({
          bulk_ids: this.selectedItems,
        }),
      })
        .then(blob => blob.json())
        .then(response => {
          this.intervalId = setInterval(() => {
            fetch(response.status_url, {
              headers: {
                Accept: "application/json",
              },
            })
              .then(blob => blob.json())
              .then(resp => {
                if (resp.progress > 0) {
                  this.progressTarget.innerText = `${resp.progress}%`;
                }

                if (resp.error) {
                  alert("Something went wrong performing this action."); // eslint-disable-line
                  this.hideSpinner();
                  this.hideProgress();
                  this.clearInterval(this.intervalId);
                  action.disabled = false;
                } else if (resp.progress === 100) {
                  this.hideSpinner();
                  this.hideProgress();
                  this.clearInterval(this.intervalId);
                  Turbolinks.visit(window.location);
                }
              });
          }, 1000);
        });
    }
  }

  selectAllClicked() {
    if (this.selectAllTarget.indeterminate) {
      this.selectAllTarget.checked = true;
    }

    this.itemTargets.forEach(item => {
      item.checked = this.selectAllTarget.checked;
    });

    this.itemClicked();
  }

  showActions(count) {
    this.actionsTarget.classList.remove("hidden");
    this.counterTarget.innerHTML = `${count} selected...`;
    this.headerTarget.classList.add("hidden");
    this.syncSelectAllState();
  }

  syncSelectAllState() {
    if (this.selectedItems.length === this.totalItems) {
      this.selectAllTarget.indeterminate = false;
      this.selectAllTarget.checked = true;
    } else if (this.selectedItems.length === 0) {
      this.selectAllTarget.indeterminate = false;
      this.selectAllTarget.checked = false;
    } else {
      this.selectAllTarget.indeterminate = true;
    }
  }

  hideActions() {
    this.actionsTarget.classList.add("hidden");
    this.headerTarget.classList.remove("hidden");
    this.dropdownTargets.forEach(d => d.classList.add("hidden"));
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
  }

  showProgress() {
    this.progressTarget.classList.remove("hidden");
  }

  hideSpinner() {
    this.spinnerTarget.classList.add("hidden");
  }

  hideProgress() {
    this.progressTarget.classList.add("hidden");
  }

  get selectedItems() {
    return this.itemTargets.filter(item => item.checked).map(item => item.value);
  }

  get totalItems() {
    return this.itemTargets.length;
  }

  clearInterval = () => clearInterval(this.intervalId);
}
