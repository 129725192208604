import { Controller } from "stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["checkbox", "activeCustomField", "fieldNameMirror"];

  connect() {
    this.refreshOptions();
  }

  refreshOptions() {
    this.refreshOptionsInternal();
    this.updateNameMirrors();
  }

  refreshOptionsInternal() {
    const newValues = this.valuesForActiveField();

    this.checkboxTargets.forEach(target => {
      const { value } = target;
      const shown = newValues.includes(value);

      $(target).closest("label").toggleClass("hidden", !shown);
      target.checked = shown && target.checked;
    });

    const shownCheckboxes = this.checkboxTargets.filter(t => newValues.includes(t.value));
    const allUnchecked = _.every(shownCheckboxes, t => !t.checked);
    const yesBox = shownCheckboxes.find(t => t.value === "Yes");

    if (allUnchecked && yesBox) {
      yesBox.checked = true;
      yesBox.dispatchEvent(new Event("input"));
    }
  }

  valuesForActiveField() {
    const fieldName = this.activeCustomFieldTarget.value;

    return JSON.parse(this.data.get("values"))[fieldName] || [];
  }

  updateNameMirrors() {
    this.fieldNameMirrorTargets.forEach(target => {
      target.innerText = this.activeCustomFieldTarget.value;
    });
  }
}
