import { Controller } from "stimulus";

function fromString(value) {
  if (["true", "false"].includes(value)) {
    return value === "true";
  }

  return value;
}

function normalizeValues(values) {
  return values.startsWith("[") ? JSON.parse(values) : [values];
}

export default class extends Controller {
  static targets = ["input", "inputGroup", "sourceInput"];

  connect() {
    const value =
      this.sourceOfTruth?.type === "checkbox"
        ? this.sourceOfTruth.checked
        : this.sourceOfTruth?.value;

    this.toggle(value);
  }

  onChange({ target }) {
    const value = this.useValue ? target.value : target.checked;

    this.toggle(value);
  }

  toggle(value) {
    if (this.values.includes(fromString(value))) {
      this.inputGroupTargets.forEach(target => target.classList.remove(this.klass));
      this.enable();
    } else {
      this.inputGroupTargets.forEach(target => target.classList.add(this.klass));
      this.disable();
    }
  }

  disable() {
    this.inputTargets.forEach(i => {
      const input = i;

      if (input.selectize) {
        if (this.shouldClear) input.selectize.clear();
        input.selectize.disable();
      } else if (input.trixId) {
        input.setAttribute("disabled", "disabled");
        input.toolbarElement.setAttribute("disabled", "disabled");
      } else {
        if (this.shouldClear) input.value = "";
        input.setAttribute("disabled", "disabled");
      }
    });
  }

  enable() {
    this.inputTargets.forEach(i => {
      const input = i;

      if (input.selectize) {
        input.selectize.enable();
      } else if (input.trixId) {
        input.removeAttribute("disabled");
        input.toolbarElement.removeAttribute("disabled");
      } else {
        input.removeAttribute("disabled");
      }
    });
  }

  get values() {
    return normalizeValues(this.data.get("value")).map(v => fromString(v));
  }

  get klass() {
    return this.data.get("class") || "hidden";
  }

  get useValue() {
    const useValue = this.data.get("useValue");

    return useValue ? fromString(useValue) : false;
  }

  get shouldClear() {
    const shouldClear = this.data.get("shouldClear");

    return shouldClear ? fromString(shouldClear) : true;
  }

  get sourceOfTruth() {
    return this.sourceInputTargets.length > 1
      ? this.sourceInputTargets.find(i => i.checked)
      : this.sourceInputTargets[0];
  }
}
