import _ from "lodash";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import {
  accumulateValuesFn,
  percentString,
  toOneDecimal,
} from "../../utilities/charts/chart_utils";
import shrinkWidgetToFit from "../../utilities/charts/shrink_widget_to_fit";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  static targets = [
    "grid",
    "rowTitle",
    "payersValue",
    "payersPercent",
    "payersTotal",
    "livesValue",
    "livesPercent",
    "livesTotal",
  ];

  initializeParams() {
    const params = super.initializeParams();

    this.enabledPolicyTypes = _.without(params.all_values, "");

    return params;
  }

  togglePayerTypeInternal() {
    this.renderChart();
  }

  toggleDrugInternal() {
    this.renderChart();
  }

  updateShownPayersInternal() {
    this.renderChart();
  }

  renderChart() {
    const countsByPolicyType = this.countsForShownPayerTypes();
    const totalsWithPolicy = this.totalsWithPolicy(countsByPolicyType);

    this.rowTitleTargets.forEach(target => {
      const policyType = $(target).data("policy-type");

      target.classList.toggle("line-through", !this.enabledPolicyTypes.includes(policyType));
    });

    this.payersValueTargets.forEach(target => {
      const policyType = $(target).data("policy-type");
      const isEnabled = this.enabledPolicyTypes.includes(policyType);

      target.innerText = isEnabled ? countsByPolicyType[policyType].payers : "";
    });

    this.livesValueTargets.forEach(target => {
      const policyType = $(target).data("policy-type");
      const isEnabled = this.enabledPolicyTypes.includes(policyType);

      target.innerText = isEnabled ? this.livesString(countsByPolicyType[policyType].lives) : "";
    });

    this.payersPercentTargets.forEach(target => {
      const policyType = $(target).data("policy-type");
      const numerator = countsByPolicyType[policyType].payers;
      const isEnabled = this.enabledPolicyTypes.includes(policyType);

      target.innerText = isEnabled ? percentString(numerator, totalsWithPolicy.payers) : "";
    });

    this.livesPercentTargets.forEach(target => {
      const policyType = $(target).data("policy-type");
      const numerator = countsByPolicyType[policyType].lives;
      const isEnabled = this.enabledPolicyTypes.includes(policyType);

      target.innerText = isEnabled ? percentString(numerator, totalsWithPolicy.lives) : "";
    });

    this.payersTotalTarget.innerText = totalsWithPolicy.payers;
    this.livesTotalTarget.innerText = this.livesString(totalsWithPolicy.lives);

    shrinkWidgetToFit(this.element, this.gridTarget);
  }

  togglePublishedPolicyType(event) {
    const policyType = $(event.target).data("policy-type");

    this.enabledPolicyTypes = _.xor(this.enabledPolicyTypes, [policyType]);

    this.renderChart();
  }

  countsForShownPayerTypes() {
    const counts = Object.fromEntries(
      this.params.all_values.map(v => [v, { payers: 0, lives: 0 }]),
    );

    const forShownPayers = _.pick(this.params.data[this.drug], this.payerTypesShown);

    _.forIn(forShownPayers, (countsByType, payerType) => {
      Object.entries(countsByType).forEach(([policyType, payerIds]) => {
        counts[policyType].payers += accumulateValuesFn(payerType, "payers")(payerIds);
        counts[policyType].lives += accumulateValuesFn(payerType, "lives")(payerIds);
      });
    });

    return counts;
  }

  totalsWithPolicy(countsByPolicyType) {
    const counts = _.values(_.pick(countsByPolicyType, this.enabledPolicyTypes));
    const countSum = key => _.sum(counts.map(byCoverageMetric => byCoverageMetric[key]));

    return { payers: countSum("payers"), lives: countSum("lives") };
  }

  livesString(count) {
    return count > 0 ? `${toOneDecimal(count).toLocaleString()}m` : "0";
  }
}
