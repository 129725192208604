import * as Sentry from "@sentry/browser";

const { RAILS_ENV } = process.env;

window.Sentry = Sentry;

if (RAILS_ENV === "staging" || RAILS_ENV === "production") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: RAILS_ENV,
  });

  document.addEventListener("turbolinks:load", () => {
    Sentry.configureScope(scope => {
      const user = window.Scout.currentUser;

      if (user) {
        scope.setUser({
          email: user,
        });
      }
    });
  });
}
