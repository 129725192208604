import _ from "lodash";
import BarsChartControllerBase from "./bars_chart_base_controller";
import showPayersModal from "../../utilities/charts/payers_modal";
import {
  accumulateValuesFn,
  coverageMetricNoun,
  toOneDecimal,
  payersNoun,
} from "../../utilities/charts/chart_utils";

export default class extends BarsChartControllerBase {
  titleText() {
    return `${coverageMetricNoun(
      this.coverageMetric,
      this.payerTypesShown,
    )} with Confirmed Access for ${this.drug}`;
  }

  descriptionText(yes, all) {
    const nouns =
      this.coverageMetric === "lives"
        ? "million lives"
        : payersNoun(this.payerTypesShown).toLowerCase();

    return `
      There are
      ${toOneDecimal(yes)} ${nouns}
      that have confirmed access out of the
      ${toOneDecimal(all)} ${nouns}
    `;
  }

  currentAndPriorDataForTarget(target) {
    const { payerType } = target.dataset;
    const [prior, current] = this.params.data[payerType]?.[this.drug] || [[], []];

    const priorValues = prior.map(accumulateValuesFn(payerType, this.coverageMetric));
    const currentValues = current.map(accumulateValuesFn(payerType, this.coverageMetric));

    return {
      current: {
        yes: currentValues[0] || 0,
        all: _.sum(currentValues),
      },
      prior: {
        yes: priorValues[0] || 0,
        all: _.sum(priorValues),
      },
    };
  }

  onBarClick(event) {
    let payerType = $(event.target).data("payer-type");

    if (payerType === "medicare_advantage_payers") {
      payerType = "medicare_advantage";
    }

    const title = `${payersNoun(payerType)} with Confirmed Access for ${this.drug}`;

    const payerIds = {};
    const extraParams = {};

    const payerIdsFor = index => this.params.data[payerType][this.drug][index][0].map(d => d.payer);

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(1);
      const priorPayers = payerIdsFor(0);

      payerIds[payerType] = _.union(currentPayers, priorPayers);

      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = {
        [payerType]: changedPayers.map(payer => ({ payer, drug: this.drug })),
      };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
      extraParams.changeDisplayMode = "access_status";
    } else {
      payerIds[payerType] = payerIdsFor(1);
    }

    showPayersModal(this.drug, title, payerIds, extraParams);
  }

  onBarRemainderClick(event) {
    let payerType = $(event.target).data("payer-type");

    if (payerType === "medicare_advantage_payers") {
      payerType = "medicare_advantage";
    }

    const title = `${payersNoun(payerType)} without Confirmed Access for ${this.drug}`;

    const payerIds = {};
    const extraParams = {};

    const payerIdsFor = index =>
      _.flatMap(this.params.data[payerType][this.drug][index].slice(1), ds => ds.map(d => d.payer));

    if (this.showChangesFromPrevious) {
      const currentPayers = payerIdsFor(1);
      const priorPayers = payerIdsFor(0);

      payerIds[payerType] = _.union(currentPayers, priorPayers);
      const changedPayers = _.xor(currentPayers, priorPayers);

      extraParams.changedCoverage = {
        [payerType]: changedPayers.map(payer => ({ payer, drug: this.drug })),
      };
      extraParams.previousSnapshotSetId = this.params.previous_snapshot_set.id;
    } else {
      payerIds[payerType] = payerIdsFor(1);
    }

    showPayersModal(this.drug, title, payerIds, extraParams);
  }
}
