import Chart from "chart.js/auto";
import _ from "lodash";
import {
  accumulateValuesFn,
  onHoverPointer,
  payersNoun,
  percentString,
} from "../../utilities/charts/chart_utils";
import colors from "../../utilities/charts/colors";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import showPayersModal, { parseClick } from "../../utilities/charts/payers_modal";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  togglePayerTypeInternal() {
    this.recalculateChartData();
  }

  toggleCoverageMetricInternal() {
    this.recalculateChartData();
  }

  toggleDrugInternal() {
    this.chart.data.labels = this.allValues;
    this.chart.options.plugins.title.text = this.titleText;
    this.recalculateChartData();
  }

  updateShownPayersInternal() {
    this.recalculateChartData();
  }

  recalculateChartData() {
    this.chart.data.datasets[0].data = this.allValues.map(v => this.dataForValue(v));
  }

  renderChart() {
    return new Chart(this.appendCanvas(), {
      type: "pie",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: !this.inStaticMode,
        plugins: {
          title: {
            display: true,
            text: this.titleText,
            color: "black",
            font: { size: 13 },
          },
          tooltip: {
            callbacks: {
              label: item => this.formatTooltipLabel(item),
            },
          },
          legend: {
            display: !this.inStaticMode,
          },
        },
        onClick: event => this.showModal(event),
        onHover: onHoverPointer,
      },
      data: {
        labels: this.allValues,
        datasets: [
          {
            label: "",
            backgroundColor: this.dataColors,
            data: this.allValues.map(v => this.dataForValue(v)),
          },
        ],
      },
    });
  }

  dataForValue(customFieldValue) {
    const dataForPayerType = payerType => {
      const datum = this.params.data[this.drug][payerType]?.[customFieldValue];

      return datum ? accumulateValuesFn(payerType, this.coverageMetric)(datum) : 0;
    };

    return _.sum(this.payerTypesShown.map(dataForPayerType));
  }

  formatTooltipLabel(tooltipItem) {
    const value = tooltipItem.label;

    const unhiddenValues = tooltipItem.chart.legend.legendItems
      .filter(item => !item.hidden)
      .map(item => item.text);

    const allValuesOrdered = tooltipItem.chart.legend.legendItems.map(item => item.text);

    const unhiddenIndexes = unhiddenValues.map(v => allValuesOrdered.indexOf(v));

    const sumOfUnhiddenValues = _.sum(unhiddenIndexes.map(i => tooltipItem.dataset.data[i]));

    const percentOfUnhidden = percentString(tooltipItem.parsed, sumOfUnhiddenValues);

    return `${value}: ${percentOfUnhidden}`;
  }

  distinctCustomFieldValues() {
    return _.uniq(_.flatten(_.values(this.params[this.drug])));
  }

  showModal(event) {
    const clickLocation = parseClick(this.chart, event);

    if (!clickLocation) return;

    const customFieldValue = this.params.all_values[this.drug][clickLocation.index];

    const payerTypes = this.payerTypesShown.map(p =>
      p === "medicare_advantage_payers" ? "medicare_advantage" : p,
    );

    const payers = _.mapValues(
      _.pick(this.params.data[this.drug], payerTypes),
      data => data[customFieldValue] || [],
    );

    const nouns = payersNoun(payerTypes);
    const title = `${nouns} with ${customFieldValue} for ${this.params.custom_field}`;

    showPayersModal(this.drug, title, payers);
  }

  get titleText() {
    return `${this.drug} ${this.params.custom_field}`;
  }

  get allValues() {
    return this.params.all_values[this.drug];
  }

  get dataColors() {
    return _.values(colors);
  }
}
