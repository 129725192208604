import _ from "lodash";
import { Controller } from "stimulus";

export default class extends Controller {
  static values = { options: Array, selected: Number };

  connect() {
    const conditionalOptions = {};
    const inModal = $(this.element).parents(".modal").length > 0;

    if (inModal) {
      conditionalOptions.onDropdownOpen = this.buildDropdownRepositionCallback();
    }

    $(this.element)
      .selectize({
        ...conditionalOptions,
        width: "100%",
        create: true,
        openOnFocus: true,
        closeAfterSelect: true,
        allowEmptyOption: true,
        respect_word_boundaries: false,
        selectOnTab: true,
        items: [this.selectedValue],
        options: this.optionsValue.map(datum => ({
          id: datum[0],
          text: datum[1],
          indents: datum[2],
          descendents: datum[3],
        })),
        valueField: "id",
        labelField: "text",
        searchField: ["text", "descendents"],
        score(query) {
          const defaultSearch = this.getScoreFunction(query);

          return item => (defaultSearch(item) > 0 ? 1 : 0);
        },
        render: {
          option: (item, escape) => {
            const outputLines = [];

            outputLines.push(
              "<div class='flex items-stretch cursor-pointer px-3 py-0 hover:bg-teal-25 hover:text-teal' data-flow='master_entity_option'>",
            );

            _.each(item.indents, indent => {
              if (indent === " ") {
                outputLines.push('<div data-flow="indent_blank" class="w-4 mx-2"></div>');
              } else if (indent === "I") {
                outputLines.push(
                  '<div data-flow="indent_I" class="w-4 mx-2 border-l border-current"></div>',
                );
              } else if (indent === "L") {
                outputLines.push('<div data-flow="indent_L" class="flex flex-col w-4 mx-2">');
                outputLines.push('<div class="flex-1 border-l border-b border-current"></div>');
                outputLines.push('<div class="flex-1"></div>');
                outputLines.push("</div>");
              } else if (indent === "T") {
                outputLines.push('<div data-flow="indent_T" class="flex flex-col w-4 mx-2">');
                outputLines.push('<div class="flex-1 border-l border-b border-current"></div>');
                outputLines.push('<div class="flex-1 border-l border-current"></div>');
                outputLines.push("</div>");
              }
            });

            outputLines.push(`<div class="py-2">${escape(item.text)}</div>`);
            outputLines.push("</div>");

            return outputLines.join("\n");
          },
        },
        onFocus() {
          if (this.getValue().length === 0) {
            this.clear();
          }
        },
        createFilter(input) {
          return Object.values(this.options).every(
            option => option.text.toLowerCase() !== input.toLowerCase(),
          );
        },
      })
      .on("change", () => this.element.dispatchEvent(new Event("scoutchange", { bubbles: true })));

    document.addEventListener(
      "turbolinks:before-cache",
      () => {
        this.element?.selectize?.destroy();
      },
      { once: true },
    );
  }

  buildDropdownRepositionCallback() {
    return $dropdown => {
      const dropdownPosition = $dropdown[0].getBoundingClientRect();
      const dropdownSpillover = dropdownPosition.bottom - $(window).height();

      if (dropdownSpillover > 0) {
        $dropdown.css("top", dropdownPosition.top - dropdownSpillover);
      }
    };
  }
}
