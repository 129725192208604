import { Controller } from "stimulus";

const universalDomainExclusions = [/^tel:/];

export default class extends Controller {
  static targets = ["modal", "leaveButton"];

  connect() {
    Array.from(this.element.querySelectorAll("a"))
      .filter(anchor => anchor.dataset.target !== "leaveButton")
      .forEach(anchor => {
        if (this.showWarning && this.isExternalLink(anchor)) {
          anchor.addEventListener("click", event => {
            event.preventDefault();

            this.leaveButtonTarget.href = anchor.href;
            this.modalTarget.classList.remove("hidden");
          });
        } else if (anchor.host !== window.location.host) {
          anchor.setAttribute("target", "_blank");
        }
      });
  }

  cancel() {
    this.leaveButtonTarget.href = "#";
    this.modalTarget.classList.add("hidden");
  }

  leave() {
    this.modalTarget.classList.add("hidden");
  }

  get domains() {
    return JSON.parse(this.data.get("internal-domains"));
  }

  get showWarning() {
    return this.data.get("showWarning") === "true";
  }

  isExternalLink(anchor) {
    return !this.domains.concat(universalDomainExclusions).some(d => anchor.href.match(d));
  }
}
