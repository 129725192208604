/* eslint-disable class-methods-use-this */
import { Controller } from "stimulus";

export default class extends Controller {
  debug() {
    window.location = "/__better_errors";
  }

  redirect() {
    window.location = "/500";
  }
}
