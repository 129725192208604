import _ from "lodash";
import { payersNoun } from "./chart_utils";
import applyPayerFilters from "./filter_payers";

// As payer IDs are sent in dozens, possibly hundreds,
// it is possible to exceed the maximum string size for an HTTP query.
// Thus, we abbreviate the keys to a single character to get as close
// as possible to irreducable complexity.
const abbreviations = {
  commercial: "c[]",
  medicare: "r[]",
  medicaid: "i[]",
  medicare_advantage: "a[]",
  managed_medicaid: "m[]",
  benefit_management: "b[]",
};

const longGroupTitle = (groupTitle, payerType) => {
  switch (groupTitle) {
    case "unchanged":
      return `${payersNoun(payerType)} with Unchanged Confirmed Access`;
    case "changed":
      return `${payersNoun(payerType)} with Changed Access`;
    case "confirmed":
      return `${payersNoun(payerType)} with Confirmed Access`;
    case "unconfirmed":
      return `${payersNoun(payerType)} without Confirmed Access`;
    default:
      return groupTitle;
  }
};

const tag = (name, value) => `<input type="hidden" name="${name}" value="${_.escape(value)}" />`;

const parsePayerId = datum => {
  if (typeof datum === "number") {
    return datum;
  } else {
    return datum.payer;
  }
};

export const parseClick = (chart, event) =>
  chart.getElementsAtEventForMode(event, "nearest", { intersect: true })[0];

export default (drugs, groupTitle, payers, extraParams) => {
  const form = $("#charts-modal");

  form.find("[name='group_title']").val(longGroupTitle(groupTitle, _.keys(payers)));

  form.find('[name="drug[]"').remove();
  _.flatten([drugs]).forEach(drug => {
    form.append(tag("drug[]", drug));
  });

  form.find('[name="c[]"]').remove();
  form.find('[name="r[]"]').remove();
  form.find('[name="i[]"]').remove();
  form.find('[name="a[]"]').remove();
  form.find('[name="m[]"]').remove();
  form.find('[name="b[]"]').remove();
  form.find('[name="changed_coverage"').remove();
  form.find('[name="previous_snapshot_set_id"').remove();

  _.forOwn(payers, (data, payerType) => {
    applyPayerFilters(payerType, data)
      .map(parsePayerId)
      .forEach(id => form.append(tag(abbreviations[payerType], id)));
  });

  if (extraParams?.changedCoverage) {
    form.append(tag("changed_coverage", JSON.stringify(extraParams.changedCoverage)));
    form.append(tag("previous_snapshot_set_id", extraParams.previousSnapshotSetId));
    form.append(tag("change_display_mode", extraParams.changeDisplayMode));
  }

  form.find('[name="access_status[]"]').remove();
  if (extraParams?.accessStatus) {
    form.append(tag("access_status[]", extraParams.accessStatus));
  }

  form.find('[name="timestamp"]').remove();
  if (extraParams?.timestamp) {
    form.append(tag("timestamp", extraParams.timestamp));
  }

  form.find('[name="commit"]').trigger("click");
};

export const bypassPayerModalToDetails = (payerType, payerId, drugName, anchor = null) => {
  const form = $("#charts-coverage-details-direct");

  form.find("[name=payer_type]").val(payerType);
  form.find("[name=payer_id]").val(parsePayerId(payerId));
  form.find("[name=drug]").val(drugName);

  form.find("[name=anchor]").remove();
  if (anchor) {
    form.append(`<input type="hidden" name="anchor" value="${anchor}">`);
  }

  form[0].submit();
};
