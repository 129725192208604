import { Controller } from "stimulus";
import * as htmlToImage from "html-to-image";
import moment from "moment";

export default class extends Controller {
  static targets = ["exclude", "image", "frame", "footer"];

  static values = { htmlToImageKnownFaulty: Boolean };

  async download() {
    const monotonicTimestamp = moment().format("YYYY-MM-DD_kk-mm-ss");
    const filename = `${monotonicTimestamp}-chart.png`;
    const width = this.element.offsetWidth;
    const height = this.element.offsetHeight + this.footerTarget.offsetHeight;

    const options = {
      backgroundColor: "#fff",
      filter: el => !Array.from(this.excludeTargets).includes(el),
    };

    this.frameTarget.setAttribute("style", `width:${width}px; height:${height}px`);

    const imageData = await this.toPng(this.element, options);

    this.insertImage(imageData);

    const data = await this.toPng(this.frameTarget, options);

    this.saveFile(data, filename);
  }

  async toPng(element, options) {
    let dataUrl = await htmlToImage.toPng(element, options);

    let previousLength = -1;

    let i = 0;

    // Safari and Firefix cause issues with our htmltoimage library since they appear to not fully wait on images to load
    // https://github.com/bubkoo/html-to-image/issues/361
    // Chrome is not known to exhibit this behavior, so we skip the mitigation for performance
    if (this.htmlToImageKnownFaultyValue) {
      while (previousLength !== dataUrl.length && i < 10) {
        previousLength = dataUrl.length;
        // eslint-disable-next-line no-await-in-loop
        dataUrl = await htmlToImage.toPng(element, options);
        i += 1;
      }
    }

    return dataUrl;
  }

  insertImage(imageData) {
    const img = document.createElement("img");

    img.src = imageData;
    this.imageTarget.appendChild(img);
  }

  saveFile(imageData, filename) {
    const anchor = document.createElement("a");

    anchor.setAttribute("href", imageData);
    anchor.setAttribute("download", filename);
    anchor.click();
    anchor.remove();
    this.imageTarget.removeChild(this.imageTarget.firstChild);
  }
}
