import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["option", "source"];

  connect() {
    this.refreshOptions();
  }

  refreshOptions() {
    const newValues = this.sourceValues().map(n => n.id.toString());

    this.optionTargets.forEach(target => {
      const { value } = target;
      const shown = newValues.includes(value);

      $(target).closest("label").toggleClass("hidden", !shown);
      target.checked = shown && target.checked;
    });
  }

  sourceValues() {
    const fieldName = this.sourceTarget.value;

    return JSON.parse(this.data.get("values"))[fieldName] || [];
  }
}
