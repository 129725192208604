import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["log", "downloadButton", "title"];

  connect() {
    this.intervalId = setInterval(() => {
      fetch(this.data.get("url"), {
        headers: {
          Accept: "application/json",
        },
      })
        .then(blob => blob.json())
        .then(resp => {
          this.logTarget.innerText = resp.log;
          this.logTarget.parentNode.scrollTo(0, this.logTarget.parentNode.scrollHeight);
          if (resp.progress === 0) {
            this.titleTarget.innerText = "Report Progress - Working";
          } else {
            this.titleTarget.innerText = `Report Progress - Working - ${resp.progress}%`;
          }

          if (resp.error) {
            this.clearInterval(this.intervalId);
            this.titleTarget.innerText = "Report Progress - Error";
            this.element.classList.add("border-red");
            this.titleTarget.classList.add("text-red");
          } else if (resp.flags.includes("partial_completion")) {
            this.clearInterval(this.intervalId);
            if (this.data.get("redirectUrl")) {
              window.location = this.data.get("redirectUrl");
            } else {
              this.titleTarget.innerText = "Report Progress - Early Completion";
              if (this.hasDownloadButtonTarget) {
                this.downloadButtonTarget.href = `/download?key=${resp.download_key}`;
                this.downloadButtonTarget.classList.remove("invisible");
              }
            }
          } else if (resp.progress === 100) {
            this.clearInterval(this.intervalId);
            this.titleTarget.innerText = "Report Progress - Complete";
            if (this.hasDownloadButtonTarget) {
              this.downloadButtonTarget.href = `/download?key=${resp.download_key}`;
              this.downloadButtonTarget.classList.remove("invisible");
            }
          }
        });
    }, 1000);
  }

  disconnect() {
    this.clearInterval();
  }

  clearInterval = () => clearInterval(this.intervalId);
}
