import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cloner"];

  connect() {
    this.clonerTargets.forEach(t => {
      this.cloneToOtherForm({ currentTarget: t });
    });
  }

  cloneToOtherForm(event) {
    const input = event.currentTarget;
    const otherForm = document.getElementById(input.dataset.otherFormId);

    if (input.type === "checkbox") {
      const existing = $(otherForm).find(`input[name='${input.name}'][value='${input.value}']`);

      if (input.checked) {
        if (existing.length === 0) {
          $(otherForm).append(`<input type='hidden' name='${input.name}' value='${input.value}'>`);
        }
      } else {
        existing.remove();
      }
    }
  }
}
