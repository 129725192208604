import { Controller } from "stimulus";
import HtmldiffAsyncRunner from "../utilities/htmldiff_async_runner";

export default class extends Controller {
  static targets = ["editable", "comparator", "output", "spinner"];

  connect() {
    this.htmldiffRunner = new HtmldiffAsyncRunner(this.renderDiff.bind(this), this.spinnerTarget);
    this.update();
  }

  update() {
    this.htmldiffRunner.calculateDiff(this.comparatorValue, this.editableValue);
  }

  renderDiff(diff) {
    this.outputTarget.innerHTML = [
      "<div class='trix-content htmldiff-output'>",
      diff,
      "</div>",
    ].join("\n");
  }

  get editableValue() {
    return this.editableTarget.value;
  }

  get comparatorValue() {
    return $(this.comparatorTarget).find(".trix-content").html();
  }
}
