const abbreviationMap = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",

  "District of Columbia": "DC",
  "Puerto Rico": "PR",
  "American Samoa": "AS",
  Guam: "GU",
  "US Virgin Islands": "VI",
  "Northern Mariana Islands": "MP",
};

export const topojsonStateName = stateModel => {
  const name = stateModel.properties.name;

  switch (name) {
    case "United States Virgin Islands":
      return "US Virgin Islands";
    case "Commonwealth of the Northern Mariana Islands":
      return "Northern Mariana Islands";
    default:
      return name;
  }
};

export const stateAbbreviation = stateModel => abbreviationMap[topojsonStateName(stateModel)];

export const isTerritory = stateModel =>
  ["PR", "AS", "GU", "VI", "MP"].includes(stateAbbreviation(stateModel));
