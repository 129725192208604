import _ from "lodash";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";
import BarsChartGridBase from "./bars_chart_grid_base";
import { accumulateValuesFn, payersNoun } from "../../utilities/charts/chart_utils";
import showPayersModal from "../../utilities/charts/payers_modal";
import shrinkWidgetToFit from "../../utilities/charts/shrink_widget_to_fit";
import colors from "../../utilities/charts/colors";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  static targets = ["title", "gridTitle", "gridFraction", "gridPercent", "gridChangeIndicator"];

  connect() {
    this.innerClass = new BarsChartGridBase(this);
    super.connect();
  }

  initializeParams() {
    const params = super.initializeParams();

    this.enabledCustomFieldValues = params.all_values[this.drug];

    return params;
  }

  toggleCoverageMetricInternal() {
    this.renderChart();
  }

  togglePayerTypeInternal() {
    this.renderChart();
  }

  toggleDrugInternal(previousDrug) {
    const allOldValues = this.params.all_values[previousDrug];
    const allNewValues = this.params.all_values[this.drug];

    const addedValues = _.without(allNewValues, ...allOldValues);
    const removedValues = _.without(allOldValues, ...allNewValues);

    this.enabledCustomFieldValues = _.without(
      this.enabledCustomFieldValues.concat(addedValues),
      ...removedValues,
    );

    this.renderChart();
  }

  toggleCustomFieldValue(event) {
    const customFieldValue = $(event.currentTarget).data("custom-field-value");

    if (this.enabledCustomFieldValues.includes(customFieldValue)) {
      this.enabledCustomFieldValues = _.without(this.enabledCustomFieldValues, customFieldValue);
    } else {
      this.enabledCustomFieldValues = this.enabledCustomFieldValues.concat(customFieldValue);
    }

    this.renderChart();
  }

  updateShownPayersInternal() {
    this.renderChart();
  }

  renderChart() {
    this.titleTarget.innerText = `${this.drug} ${this.params.custom_field}`;
    this.innerClass.renderGrid();
    this.updateRowVisibility();
    shrinkWidgetToFit(this.element, this.element.children[0]);
  }

  currentAndPriorDataForTarget(target) {
    const output = {
      current: { yes: 0, all: 0 },
      prior: { yes: 0, all: 0 },
    };

    this.payerTypesShown.forEach(payerType => {
      this.enabledCustomFieldValues.forEach(customFieldValue => {
        const data = this.params.data[this.drug][payerType]?.[customFieldValue] || [];
        const count = accumulateValuesFn(payerType, this.coverageMetric)(data);

        output.current.all += count;

        if (customFieldValue === target.dataset.customFieldValue) {
          output.current.yes += count;
        }
      });
    });

    return output;
  }

  targetIsDisabled(target) {
    return !this.enabledCustomFieldValues.includes(target.dataset.customFieldValue);
  }

  updateRowVisibility() {
    const isOtherDrug = t => t.dataset.drug !== this.drug;

    this.gridTitleTargets.concat(this.gridPercentTargets).forEach(target => {
      $(target).toggleClass("hidden", isOtherDrug(target));
    });

    this.gridFractionTargets.forEach(target => {
      $(target).toggleClass("hidden", isOtherDrug(target));
      $(target).toggleClass("invisible", this.targetIsDisabled(target));
    });
  }

  onBarClick(event) {
    const customFieldValue = $(event.currentTarget).data("custom-field-value");
    const nouns = payersNoun(this.payerTypesShown);

    const payerIdsByType = {};

    this.payerTypesShown.forEach(payerType => {
      const payerIds = this.params.data[this.drug][payerType]?.[customFieldValue];

      if (!_.isEmpty(payerIds)) {
        payerIdsByType[payerType] = payerIds;
      }
    });

    const title = `${nouns} with ${customFieldValue} for ${this.drug} ${this.params.custom_field}`;

    showPayersModal(this.drug, title, payerIdsByType);
  }

  onBarRemainderClick(event) {
    const customFieldValue = $(event.currentTarget).data("custom-field-value");
    const nouns = payersNoun(this.payerTypesShown);

    const payerIdsByType = {};

    this.payerTypesShown.forEach(payerType => {
      _.without(this.enabledCustomFieldValues, customFieldValue).forEach(otherFieldValue => {
        const payerIds = this.params.data[this.drug][payerType]?.[otherFieldValue];

        if (!_.isEmpty(payerIds)) {
          payerIdsByType[payerType] ||= [];
          payerIdsByType[payerType].push(...payerIds);
        }
      });
    });

    const title = `${nouns} without ${customFieldValue} for ${this.drug} ${this.params.custom_field}`;

    showPayersModal(this.drug, title, payerIdsByType);
  }

  get allGridTargets() {
    return this.gridTitleTargets
      .concat(this.gridFractionTargets)
      .concat(this.gridChangeIndicatorTargets)
      .concat(this.gridPercentTargets);
  }

  get colorsByCoverageMetric() {
    return {
      payers: colors.magenta,
      lives: colors.teal,
    };
  }
}
