import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["drugs"];

  clientSelected(e) {
    const clientId = e.target.value;

    if (clientId !== "") {
      this.fetchDrugsForClient(clientId);
    }
  }

  fetchDrugsForClient(clientId) {
    const path = `${this.data.get("path")}/${clientId}`;

    fetch(path)
      .then(r => r.text())
      .then(html => $(this.drugsTarget).html(html));
  }
}
