import { Controller } from "stimulus";

require("jquery-tablesort");

export default class extends Controller {
  connect() {
    $(this.element).tablesort({
      asc: "asc",
      desc: "desc",
    });
  }

  disconnect() {
    const table = $(this.element).data("tablesort");

    if (table) {
      table.destroy();
    }
  }
}
