import Chart from "chart.js/auto";
import atlas from "us-atlas/states-10m.json";
import { topojson } from "chartjs-chart-geo";
import _ from "lodash";
import { onHoverPointer } from "../../utilities/charts/chart_utils";
import {
  topojsonStateName,
  stateAbbreviation,
  isTerritory,
} from "../../utilities/charts/state_abbreviation_map";
import { parseClick, bypassPayerModalToDetails } from "../../utilities/charts/payers_modal";
import applyPayerFilters from "../../utilities/charts/filter_payers";
import OneDrugOnePayerTypeChartControllerBase from "./one_drug_one_payer_type_chart_controller_base";

export default class extends OneDrugOnePayerTypeChartControllerBase {
  titleText() {
    throw new Error("Implement in child!");
  }

  displayValueFromFraction() {
    throw new Error("Implement in child!");
  }

  colorForDisplayValue(/* value */) {
    throw new Error("Implement in child!");
  }

  fractionForState(/* payerType, state */) {
    throw new Error("Implement in child!");
  }

  payersForState(/* payerType, state */) {
    throw new Error("Implement in child!");
  }

  togglePayerTypeInternal() {
    this.chart.options.plugins.title.text = this.titleText();
    this.recalculateChartData();
  }

  toggleDrugInternal() {
    this.chart.options.plugins.title.text = this.titleText();
    this.recalculateChartData();
  }

  updateShownPayersInternal() {
    this.recalculateChartData();
  }

  recalculateChartData() {
    this.chart.data.datasets[0].data = this.formatData();
  }

  renderChart() {
    return new Chart(this.appendCanvas(), {
      type: "choropleth",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: this.titleText(),
            color: "black",
            font: { size: 13 },
          },
          legend: {
            display: !this.inStaticMode,
            position: "bottom",
            labels: {
              usePointStyle: true,
              generateLabels: chart => this.generateLegendLabels(chart),
            },
            onClick: () => {},
          },
          tooltip: {
            callbacks: this.tooltipCallbacks(),
            footerMarginTop: 10,
            footerFont: {
              weight: "normal",
            },
          },
        },
        scales: {
          projection: {
            axis: "x",
            projection: "albersUsa",
          },
          color: {
            display: false,
            axis: "x",
          },
        },
        onHover: onHoverPointer,
        onClick: event => this.openCoverageDetails(event),
      },
      data: {
        labels: this.states.map(topojsonStateName),
        datasets: [
          {
            label: "States",
            outline: this.nation,
            data: this.formatData(),
            backgroundColor: datum => this.colorForStateOnMap(datum),
          },
        ],
      },
    });
  }

  formatData() {
    const payerType =
      this.payerType === "medicare_advantage_payers" ? "medicare_advantage" : this.payerType;

    return this.states.map(state => ({
      feature: state,
      value: this.fractionForState(payerType, stateAbbreviation(state)),
    }));
  }

  openCoverageDetails(event) {
    const clickLocation = parseClick(this.chart, event);

    if (!clickLocation) return;

    const payerType =
      this.payerType === "medicare_advantage_payers" ? "medicare_advantage" : this.payerType;
    const state = this.chart.data.datasets[0].data[clickLocation.index].feature;

    const payerId = this.payersForState(payerType, stateAbbreviation(state))[0];

    bypassPayerModalToDetails(payerType, payerId, this.drug, this.coverageDetailsAnchor());
  }

  generateLegendLabels(chart) {
    const relevantStates = chart.data.datasets[0].data.filter(d => !isTerritory(d.feature));
    const presentValues = _.uniq(relevantStates.map(d => d.value))
      .sort()
      .reverse();

    return presentValues.map(f => {
      const displayValue = this.displayValueFromFraction(f);

      return {
        text: displayValue,
        fillStyle: this.colorForDisplayValue(displayValue),
        pointStyle: "rect",
        fontColor: chart.legend.options.labels.color,
      };
    });
  }

  tooltipCallbacks() {
    return {
      title: items => topojsonStateName(items[0].raw.feature),
      label: item => this.displayValueFromFraction(item.raw.value),
      labelColor: item => ({ backgroundColor: this.colorFromFraction(item.raw.value) }),
    };
  }

  colorForStateOnMap(chartDatum) {
    const state = chartDatum.raw.feature;
    const payers = this.payersForState(this.payerType, stateAbbreviation(state));
    const color = this.colorFromFraction(chartDatum.raw.value);

    if (_.isEmpty(applyPayerFilters(this.payerType, payers))) {
      return this.fadeColor(color);
    } else {
      return color;
    }
  }

  colorFromFraction(fraction) {
    return this.colorForDisplayValue(this.displayValueFromFraction(fraction));
  }

  fadeColor(rgb) {
    const hex25 = "40";

    return `${rgb}${hex25}`; // appending a hex value takes this to RGBA, fading the base color against the white background.
  }

  coverageDetailsAnchor() {
    return null; // Here for chart-specific specialization
  }

  get nation() {
    return topojson.feature(atlas, atlas.objects.nation).features[0];
  }

  get states() {
    return topojson.feature(atlas, atlas.objects.states).features;
  }
}
