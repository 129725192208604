import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  increment() {
    this.inputTarget.stepUp();
  }

  decrement() {
    this.inputTarget.stepDown();
  }
}
