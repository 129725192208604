import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["exclusiveInput", "otherInput"];

  deselectExclusive() {
    if (this.hasExclusiveInputTarget) {
      this.exclusiveInputTarget.checked = false;
    }
  }

  deselectOthers() {
    this.otherInputTargets.forEach(e => {
      e.checked = false;
    });
  }
}
