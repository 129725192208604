import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import _ from "lodash";
import { onHoverPointer, payersNoun, toOneDecimal } from "../../utilities/charts/chart_utils";
import colors, { lightColors } from "../../utilities/charts/colors";
import { parseClick } from "../../utilities/charts/payers_modal";
import OneDrugManyPayerTypesChartControllerBase from "./one_drug_many_payer_types_chart_controller_base";

export default class extends OneDrugManyPayerTypesChartControllerBase {
  togglePayerTypeInternal() {
    this.chart.options.plugins.title.text = this.titleText;
    this.chart.data.labels = [payersNoun(this.payerTypesShown), "Lives"];
    this.chart.data.datasets[0].label = payersNoun(this.payerTypesShown);
    this.recalculateChartData();
  }

  toggleDrugInternal() {
    this.chart.options.plugins.title.text = this.titleText;
    this.recalculateChartData();
  }

  updateShownPayersInternal() {
    this.recalculateChartData();
  }

  recalculateChartData() {
    this.chart.data.datasets[0].data = this.formatData("payers");
    this.chart.data.datasets[1].data = this.formatData("lives");
  }

  renderChart() {
    return new Chart(this.appendCanvas(), {
      type: "doughnut",
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: !this.inStaticMode,
        plugins: {
          title: {
            display: true,
            text: this.titleText,
            color: "black",
            font: { size: 13 },
          },
          legend: {
            display: !this.inStaticMode,
          },
          tooltip: {
            mode: "dataset",
            position: "nearest",
            filter: item => item.dataIndex < 2,
            callbacks: {
              title: () => this.tooltipTitleText,
              label: context => this.tooltipLabelText(context),
            },
          },
          datalabels: {
            color: "black",
            offset: 0,
            align: context => 360 * this.combinedFractionFromChartContext(context),
            display: context => !this.inStaticMode && this.isDatalabelDataset(context),
            formatter: (_v, context) =>
              `${toOneDecimal(this.combinedFractionFromChartContext(context) * 100)}%`,
            font: {
              weight: 600,
            },
          },
        },
        onClick: event => this.triggerModal(event),
        onHover: onHoverPointer,
      },
      data: {
        labels: [payersNoun(this.payerTypesShown), "Lives"],
        datasets: [
          {
            label: payersNoun(this.payerTypesShown),
            data: this.formatData("payers"),
            backgroundColor: [
              colors.magenta,
              colors.teal,
              colors.pale_green,
              colors.orange,
              lightColors.gray,
              "#000",
            ],
          },
          {
            label: "Lives",
            data: this.formatData("lives"),
            backgroundColor: [
              colors.magenta,
              colors.teal,
              colors.pale_green,
              colors.orange,
              lightColors.gray,
              "#000",
            ],
          },
        ],
      },
    });
  }

  isDatalabelDataset(context) {
    return context.dataIndex === 3 && _.isFinite(this.combinedFractionFromChartContext(context));
  }

  combinedFractionFromChartContext(context) {
    const mainIndex = context.dataset.label === "Lives" ? 1 : 0;
    const increaseIndex = 2;
    const decreaseIndex = 3;
    const realValue =
      context.dataset.data[mainIndex] +
      context.dataset.data[increaseIndex] -
      context.dataset.data[decreaseIndex];

    return realValue;
  }

  triggerModal(e) {
    const clickLocation = parseClick(this.chart, e);

    if (!clickLocation) return;

    let collection = null;

    switch (clickLocation.index) {
      case 0:
      case 1:
        collection = "base";
        break;
      case 2:
      case 3:
        collection = "changed";
        break;
      case 4:
        collection = "remainder";
        break;
      default:
        throw new Error(`Unexpected index ${clickLocation.index}`);
    }

    this.triggerModalInternal(collection);
  }

  yesValues(values) {
    return values.Yes || [];
  }

  formatData(coverageMetric) {
    const { mainFraction, increaseFraction, decreaseFraction } =
      this.calculateFractions(coverageMetric);

    const remainderFraction = 1 - mainFraction - increaseFraction - decreaseFraction;

    return coverageMetric === "payers"
      ? [mainFraction, 0, increaseFraction, decreaseFraction, remainderFraction, 0]
      : [0, mainFraction, increaseFraction, decreaseFraction, remainderFraction, 0];
  }

  tooltipLabelText(context) {
    const coverageMetric = context.dataIndex === 0 ? "payers" : "lives";
    const nouns = context.dataIndex === 0 ? payersNoun(this.payerTypesShown) : "Lives";
    const value = toOneDecimal(
      context.chart.data.datasets[context.dataIndex].data[context.dataIndex] * 100,
    );

    const standardLine = `${nouns}: ${value}%`;

    const { increaseFraction, decreaseFraction } = this.calculateFractions(coverageMetric);

    if (increaseFraction > 0) {
      return `${standardLine} (+${toOneDecimal(increaseFraction * 100)})`;
    } else if (decreaseFraction > 0) {
      return `${standardLine} (-${toOneDecimal(decreaseFraction * 100)})`;
    } else {
      return standardLine;
    }
  }

  /* eslint-disable no-unused-vars */
  calculateFractions(_coverageMetric) {
    throw new Error("Implement in child!");
  }

  triggerModalInternal(_collection) {
    throw new Error("Implement in child!");
  }

  get titleText() {
    throw new Error("Implement in child!");
  }

  get tooltipTitleText() {
    throw new Error("Implement in child!");
  }
}
