/* eslint-disable class-methods-use-this */

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if (window.location.hash) this.jumpTo(window.location.hash.slice(1), true);
  }

  jump(event) {
    const { anchor } = event.currentTarget.dataset;

    this.jumpTo(anchor, false);
  }

  jumpTo(anchor, fast) {
    const section = document.getElementById(anchor);

    if (section && fast) {
      section.scrollIntoView();
    } else if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      section.classList.remove("animate-slow");
      section.classList.add("pulse");

      window.setTimeout(() => {
        section.classList.add("animate-slow");
        section.classList.remove("pulse");
      }, 1000);
    }
  }
}
